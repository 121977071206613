import { SymbolMetadata, UserContractModel } from "@/api/userApi";
import { IChartWidgetApi, IOrderLineAdapter } from "@/charting_library/charting_library";
import { ChartDisplayType } from "@/contexts/SettingsContext";
import { chartPlotSide } from "@/data/enumTypeMaps";

export interface ChartInstanceData {
    chart: IChartWidgetApi;
    positionId: number;
    positionLine: IOrderLineAdapter;
    stopLossLine: IOrderLineAdapter;
    takeProfitLine: IOrderLineAdapter;
    lastPnl?: number;
    lastSize?: number;
    lastAvgPrice?: number;
    lastStoploss?: number;
    lastTakeProfit?: number;
    lastRisk?: number;
    lastToMake?: number;
    lastTakeProfitPlotSide?: chartPlotSide;
    lastStopLossPlotSide?: chartPlotSide;
    lastTakeProfitDisplayType?: ChartDisplayType;
    lastStopLossDisplayType?: ChartDisplayType;
    resChangedEvent?: () => void;
  }
  export interface ChartGroup {
    symbol: string;
    actualSymbol: string;
    tickSize: number;
    lastPrice?: number;
    lastPriceForPnl?: number;
    metadata?: UserContractModel;
    updateTimer?: NodeJS.Timeout;
    eventHandlers?: ((chartGroup: ChartGroup) => void)[];
    charts: ChartInstanceData[];
  }


export const LEFT_PLOT_SIDE = 85;
export const RIGHT_PLOT_SIDE = 8;

export const ORDER_LEFT_PLOT_SIDE = 73;
export const ORDER_RIGHT_PLOT_SIDE = 21;
