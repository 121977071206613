import React from 'react';
import Panel from '../../components/topstep/panel';
import CopyTrading from '../../components/copyTrading/copyTrading';

import { Column, Page } from '../../layout/LayoutComponents';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { TradingAccountType } from '@api/userApi';
import FeatureRequests from '@/components/featureRequests/featureRequests';
import SupportResources from '@/components/supportResources/supportResources';
import QuickVideos from './quickVideos';
import select from '@/components/topstep/select';

const SupportView: React.FC = (): JSX.Element => {
  const { activeTradingAccount } = useTradingAccount();
  const hasDll = activeTradingAccount.type === TradingAccountType.Express || activeTradingAccount.type === TradingAccountType.Live;
  const [selectedView, setSelectedView] = React.useState<string>('featurebase');

  const handleSelectView = (view: string) => {
    setSelectedView(view)
  }

  return (
    <Page sx={{ maxWidth: '100%' }}>
      <Column overflow='hidden' width={'15%'} style={{ background: '#1c1e23' }}>
        <SupportResources selectView={handleSelectView} selectedView={selectedView}/>
      </Column>
      <Column overflow='auto' width={'90%'}>
        {selectedView === 'featurebase' && <FeatureRequests />}
        {selectedView === '5min' && <QuickVideos />}
      </Column>
    </Page>
  );
};

export default React.memo(SupportView);
