import { HistoryBarModel, HistoryBarsResult, HistoryClient } from '@/api/dataApi';
import { PeriodParamsWithOptionalCountback } from 'datafeeds2/src/history-provider';
import { LibrarySymbolInfo, PeriodParams } from './dataFeedTypes';

type BarRequestParams = {
  symbol: string;
  resolution: string;
  countBack?: number;
  from: number;
  to: number;
};

export type ResponseLimit = {
  maxLength: number;
  expectedOrder: string;
};

export class HistoryProvider {
  private _historyClient: HistoryClient;
  private _responseLimit: ResponseLimit | null;
  private _live: boolean;
  constructor(client: HistoryClient, responseLimit: ResponseLimit | null = null, live: boolean = false) {
    this._responseLimit = responseLimit;
    this._historyClient = client;
    this._live = live;
  }

  getBars(symbolInfo: LibrarySymbolInfo, resolution: string, periodParams: PeriodParamsWithOptionalCountback): Promise<HistoryBarsResult> {
    return new Promise<HistoryBarsResult>(async (resolve, reject) => {
      // Pack params for potential re-use if limited server response
      const requestParams: BarRequestParams = {
        symbol: symbolInfo.ticker,
        resolution: resolution,
        countBack: periodParams?.countBack ?? undefined,

        from: periodParams.from,
        to: periodParams.to
      };

      this._historyClient
        .getBarsV2(requestParams.symbol, requestParams.resolution, requestParams.countBack, requestParams.from, requestParams.to, symbolInfo.subsession_id, this._live)
        .then(async (response) => {
          response.bars.reverse();
          await this._processTruncatedResponse(response, requestParams, symbolInfo.subsession_id);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async _processTruncatedResponse(result: HistoryBarsResult, params: BarRequestParams, session: string) {
    let lastResultLength = result.bars.length;
    try {
      while (this._responseLimit && this._responseLimit.maxLength > 0 && this._responseLimit.maxLength === lastResultLength && params.from < params.to) {
        // adjust params for next request
        if (params.countBack) {
          params.countBack = params.countBack - lastResultLength;
        }

        if (this._responseLimit.expectedOrder === 'earliestFirst') {
          // adjust params for next request
          params.from = Math.round(result.bars[result.bars.length - 1].t / 1000);
        } else {
          // adjust params for next request
          params.to = Math.round(result.bars[0].t / 1000);
        }

        const followupResult = await this._historyClient.getBarsV2(params.symbol, params.resolution, params.countBack, params.from, params.to, session, this._live);
        followupResult.bars.reverse();
        lastResultLength = followupResult.bars.length;

        // merge result with existing results
        if (this._responseLimit.expectedOrder === 'earliestFirst') {
          if (followupResult.bars[0].t === result.bars[result.bars.length - 1].t) {
            // Datafeed shouldn't include a value exactly matching the `to` timestamp but in case it does
            // we will remove the duplicate.
            followupResult.bars.shift();
          }
          result.bars.push(...followupResult.bars);
        } else {
          if (followupResult.bars[followupResult.bars.length - 1].t === result.bars[0].t) {
            // Datafeed shouldn't include a value exactly matching the `from` timestamp but in case it does
            // we will remove the duplicate.
            followupResult.bars.pop();
          }
          result.bars.unshift(...followupResult.bars);
        }
      }
    } catch (e) {
      /**
       * Error occurred during followup request. We won't reject the original promise
       * because the initial response was valid so we will return what we've got so far.
       */
      if (e instanceof Error || typeof e === 'string') {
        // tslint:disable-next-line:no-console
        console.warn(`HistoryProvider: getBars() warning during followup request, error=${e}`);
      }
    }
  }
}
