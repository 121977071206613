import { StyledInput } from '@components/styledComponents';
import { Input } from '@mui/material';
import React from 'react';

const getPropValue = (obj, key) =>
  key.split('.').reduce((o, x) => (o == undefined ? o : o[x]), obj);

const ReactstrapFormikInput = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => {
  const Component = props.component || StyledInput;
  const invalid = Boolean(getPropValue(touched, fields.name) && getPropValue(errors, fields.name));
  return (
    <div>
      <Component {...props} {...fields} invalid={invalid.toString()} />
      {getPropValue(touched, fields.name) && getPropValue(errors, fields.name) && (
        <FormFeedback valid={!invalid}>{getPropValue(errors, fields.name)}</FormFeedback>
      )}
    </div>
  );
};
export default ReactstrapFormikInput;
