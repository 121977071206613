import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../components/Loading';
import { useApi } from './ApiContext';
import { SymbolMetadata, UserContractModel } from '../api/userApi';
import { Box } from '@mui/material';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { TradingEnvironment } from '@/data/tradingEnvironment';

export interface ISymbolContext {
  // contract: SymbolMetadata;
  // symbolId: number;
  // symbolCqgName: string;
  // symbols: SymbolMetadata[];
  // symbolsById: Map<string, SymbolMetadata>;

  // setContract: (symbol: SymbolMetadata) => void;
  // setContractByFriendlyName: (symbol: string) => void;
  // getContractByFriendlyName: (name: string) => SymbolMetadata | undefined;
  // getContractById: (id: string) => SymbolMetadata | undefined;
  // getDefaultContract: () => SymbolMetadata;

  contracts: UserContractModel[];

  getContractByContractId: (id: string) => UserContractModel | undefined;
  getContractByProductId: (id: string) => UserContractModel | undefined;
  getContractByContractName: (name: string) => UserContractModel | undefined;
  getContractByProductName: (name: string) => UserContractModel | undefined;

  getDefaultContract: () => UserContractModel;
}

export const SymbolContext = React.createContext<ISymbolContext>({} as any);
export const useSymbol = () => React.useContext<ISymbolContext>(SymbolContext);

export interface SymbolContextProviderProps {
  children: React.ReactNode;
}

const SymbolContextProvider: React.FC = (props: SymbolContextProviderProps): JSX.Element => {
  const [contracts, setContracts] = useState<UserContractModel[]>([]);
  const tradingAccountContext = useTradingAccount();
  const [loading, setLoading] = useState(true);
  const { contractApi } = useApi();

  useEffect(() => {
    let isMounted = true;

    if (tradingAccountContext.tradingEnvironment == TradingEnvironment.Live) {
      contractApi.getProfessional().then((res) => {
        if (isMounted) {
          setContracts(res);
          setLoading(false);
        }
      });
    } else {
      contractApi.getNonProfessional().then((res) => {
        if (isMounted) {
          setContracts(res);
          setLoading(false);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [tradingAccountContext.tradingEnvironment]);

  const values = useMemo(() => {
    const getDefaultContract = () => {
      if (contracts.length === 0) return undefined;
      return contracts.find((x) => x.productName === '/ES') || contracts[0];
    };

    const getContractByContractId = (id: string) => {
      return contracts.find((x) => x.contractId === id);
    };

    const getContractByProductId = (id: string) => {
      return contracts.find((x) => x.productId === id);
    };

    const getContractByContractName = (name: string) => {
      return contracts.find((x) => x.contractName === name);
    };

    const getContractByProductName = (name: string) => {
      return contracts.find((x) => x.productName === name);
    };

    return {
      getContractByContractId,
      getContractByProductId,
      getContractByContractName,
      getContractByProductName,
      getDefaultContract,
      contracts
    };
  }, [contracts]);

  return useMemo(() => {
    if (loading) {
      return <Loading />;
    }
    if (contracts.length === 0) {
      return <Box>You do not have a market data subscription assigned.</Box>;
    }

    return <SymbolContext.Provider value={values}>{props.children}</SymbolContext.Provider>;
  }, [loading, values, contracts, props.children]);
};

export default SymbolContextProvider;
