import { config } from '@/config';
import React, { useEffect, useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const TawkChat = ({ isSupportPage }) => {
  const tawkMessengerRef = useRef(null);

  const onBeforeLoad = () => {
    if (!isSupportPage) {
      tawkMessengerRef.current.hideWidget();
    }
  };

  useEffect(() => {
    if (!config.tawkPropertyId || !config.tawkWidgetId) {
      return;
    }
    if (!tawkMessengerRef.current.onLoaded()) {
      return;
    }
    if (!isSupportPage) {
      tawkMessengerRef.current.hideWidget();
    } else {      
      tawkMessengerRef.current.showWidget();
    }
    
  }, [config.tawkPropertyId, config.tawkWidgetId, isSupportPage]);

  if(!config.tawkPropertyId || !config.tawkWidgetId) {
    return <></>;
  }
  else {
    return (
      <TawkMessengerReact
        onBeforeLoad={onBeforeLoad}
        propertyId={config.tawkPropertyId}
        widgetId={config.tawkWidgetId}
        ref={tawkMessengerRef}
      />
    );
  }
};

export default React.memo(TawkChat);
