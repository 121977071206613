import { ChartModel, ChartTemplateModel, DrawingTemplateModel, LineToolsModel, StudyTemplateModel } from '@/api/userApi';
import {
  ChartData,
  ChartMetaInfo,
  ChartTemplate,
  ChartTemplateContent,
  IExternalSaveLoadAdapter,
  LineToolsAndGroupsLoadRequestContext,
  LineToolsAndGroupsLoadRequestType,
  LineToolsAndGroupsState,
  ResolutionString,
  StudyTemplateData,
  StudyTemplateMetaInfo
} from '@/charting_library';
import { IApiContext } from '@/contexts/ApiContext';
import { ILinkedContext } from '@/contexts/LinkedContext';

export class SaveLoadAdater implements IExternalSaveLoadAdapter {
  #apiContext: IApiContext;
  #linksContext: ILinkedContext;
  #mobile: boolean;
  #overrideMobileSymbol: string;

  constructor(apiContext: IApiContext, linksContext: ILinkedContext, mobile: boolean, overrideMobileSymbol?: string) {
    this.#apiContext = apiContext;
    this.#linksContext = linksContext;
    this.#mobile = mobile;
    this.#overrideMobileSymbol = overrideMobileSymbol;
  }
  async getAllCharts(): Promise<ChartMetaInfo[]> {
    let charts = await this.#apiContext.chartsApi.getAll();
    if (this.#mobile) {
      for (const chart of charts) {
        chart.symbol = this.#overrideMobileSymbol ?? '/ES';
      }
    }

    return charts.map((chart) => {
      return {
        id: chart.id,
        name: chart.name,
        symbol: chart.symbol,
        resolution: chart.resolution as ResolutionString,
        timestamp: chart.timestamp.unix()
      };
    });
  }
  async removeChart<T extends string | number>(id: T): Promise<void> {
    await this.#apiContext.chartsApi.remove(parseInt(id + ''));
  }
  async saveChart(chartData: ChartData): Promise<string> {
    const model = new ChartModel();
    model.init({
      id: parseInt(chartData.id),
      name: chartData.name,
      symbol: chartData.symbol,
      resolution: chartData.resolution,
      content: chartData.content
    });
    const res = await this.#apiContext.chartsApi.saveChart(model);

    return res + '';
  }
  async getChartContent(chartId: number): Promise<string> {
    try {
      for (let i = 0; i < window.frames.length; i++) {
        const frame = window.frames[i];
        (frame.window as any).DEFAULT_SYMBOL = '/ES';
      }
    } catch (e) {
      console.error('Error setting default chart symbol', e);
    }
    const data = await this.#apiContext.chartsApi.getChart(chartId);

    return data;
  }
  async getAllStudyTemplates(): Promise<StudyTemplateMetaInfo[]> {
    const studyTemplates = await this.#apiContext.studyTemplatesApi.getAll();

    return studyTemplates.map((template) => {
      return {
        name: template
      };
    });
  }
  async removeStudyTemplate(studyTemplateInfo: StudyTemplateMetaInfo): Promise<void> {
    await this.#apiContext.studyTemplatesApi.removeByName(studyTemplateInfo.name);
  }
  async saveStudyTemplate(studyTemplateData: StudyTemplateData): Promise<void> {
    await this.#apiContext.studyTemplatesApi.saveStudyTemplate(
      new StudyTemplateModel({
        id: 0, // Dummy value, is fetched by backend
        userId: 0, // Dummy value, is fetched by backend
        name: studyTemplateData.name,
        content: studyTemplateData.content
      })
    );
  }
  async getStudyTemplateContent(studyTemplateInfo: StudyTemplateMetaInfo): Promise<string> {
    return await this.#apiContext.studyTemplatesApi.getStudyTemplate(studyTemplateInfo.name);
  }
  async getDrawingTemplates(toolName: string): Promise<string[]> {
    const drawingTemplates = await this.#apiContext.drawingTemplatesApi.getAll(toolName);
    return drawingTemplates.map((template) => template.templateName);
  }
  async loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
    return await this.#apiContext.drawingTemplatesApi.getDrawingTemplate(toolName, templateName);
  }
  async removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
    await this.#apiContext.drawingTemplatesApi.remove(toolName, templateName);
  }
  async saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void> {
    await this.#apiContext.drawingTemplatesApi.saveDrawingTemplate(
      new DrawingTemplateModel({
        id: 0, // Dummy value, is fetched by backend
        userId: 0, // Dummy value, is fetched by backend
        toolName: toolName,
        templateName: templateName,
        content: content
      })
    );
  }
  async getChartTemplateContent(templateName: string): Promise<ChartTemplate> {
    const chartTemplate = await this.#apiContext.chartTemplatesApi.getChartTemplate(templateName);

    return {
      content: JSON.parse(chartTemplate)
    };
  }
  async getAllChartTemplates(): Promise<string[]> {
    return await this.#apiContext.chartTemplatesApi.getAll();
  }
  async saveChartTemplate(newName: string, theme: ChartTemplateContent): Promise<void> {
    await this.#apiContext.chartTemplatesApi.saveChartTemplate(
      new ChartTemplateModel({
        id: 0, // Dummy value, is fetched by backend
        userId: 0, // Dummy value, is fetched by backend
        name: newName,
        content: JSON.stringify(theme)
      })
    );
  }

  async removeChartTemplate(templateName: string): Promise<void> {
    await this.#apiContext.chartTemplatesApi.remove(templateName);
  }
  async saveLineToolsAndGroups(layoutId: string, chartId: string, state: LineToolsAndGroupsState, ...args): Promise<void> {
    // console.log('saveLineToolsAndGroups', layoutId, chartId, state, args);

    // load old line tools
    const oldLineTools = await this.#apiContext.lineToolsApi.load(layoutId as any, chartId);
    
    const kv = [];
    for (const [k, v] of state.sources) {
      if (!v) continue;
      if (!v.state) continue;
      // check if v.state is empty
      if (Object.keys(v.state).length === 0) {
        continue;
      }
      kv.push([k, v]);
    }

    // Compare state.sources and oldLineTools.sources

    const oldSources = JSON.parse(oldLineTools.sources) as [];

    // Make array of newSources values ordered by id
    const newSources = Array.from(kv.values()).map(x => x[1]).sort((a, b) => a.id - b.id);
    // Make array of old sources ordered by id (second element in arrays)
    const oldSourcesOrdered = oldSources.map(x => x[1]).sort((a: any, b: any) => a.id - b.id);

    //console.log('oldSources', oldSourcesOrdered);
    //console.log('newSources', newSources);

    // Compare oldSourcesOrdered and newSources
    const sourcesEqual = JSON.stringify(oldSourcesOrdered) === JSON.stringify(newSources);
    if (sourcesEqual) {
      console.log('No change in line tool data.');
      return;
    } else {
      console.log('Changes in line tool data.');
    }

    const layoutIdInt = parseInt(layoutId);
    await this.#apiContext.lineToolsApi.saveLineTool(
      new LineToolsModel({
        id: 0, // Dummy value, is fetched by backend
        userId: 0, // Dummy value, is fetched by backend
        layoutId: layoutIdInt,
        chartId: chartId,
        sources: JSON.stringify(kv),
        groups: JSON.stringify(Array.from(state.groups.entries())),
        symbol: state.symbol
      })
    );
  }
  async loadLineToolsAndGroups(layoutId: string, chartId: string, requestType: LineToolsAndGroupsLoadRequestType, requestContext: LineToolsAndGroupsLoadRequestContext): Promise<Partial<LineToolsAndGroupsState>> {
    const lineTools = await this.#apiContext.lineToolsApi.load(layoutId as any, chartId);
    if (!lineTools) {
      return {};
    }

    const sources = JSON.parse(lineTools.sources) as [];
    const groups = JSON.parse(lineTools.groups) as [];

    return {
      sources: new Map(sources),
      groups: new Map(groups),
      symbol: lineTools.symbol
    };
  }
}
