import React, { useMemo } from 'react';
import { OrderPromptType, useOrders } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import DomEmptyOrder from './domEmptyOrder';
import DomOrder from './domOrder';
import { OrderType } from '@api/userApi';
import classNames from 'classnames';
interface OrderCellProps {
  price: number;
  orderType: OrderPromptType;
  canTrade: boolean;
}

const OrderCell: React.FC<OrderCellProps> = ({ price, orderType, canTrade }): JSX.Element => {
  const { orders, low, high, lastPrice, position, positionEntryPrice, highlightGroup, setHighlightGroup, linkedOrderMap } = useDom();

  const isLod = useMemo(() => {
    return price == low;
  }, [price, low]);
  const isHod = useMemo(() => {
    return price == high;
  }, [price, high]);

  const isBracket = useMemo(() => {
    let show = false;
    if (position?.stopLoss) {
      if (position.positionSize > 0) {
        show = price >= position.stopLoss && price <= positionEntryPrice;
      } else {
        show = price <= position.stopLoss && price >= positionEntryPrice;
      }
    }

    if (position?.takeProfit) {
      if (position.positionSize > 0) {
        show ||= price >= positionEntryPrice && price <= position.takeProfit;
      } else {
        show ||= price <= positionEntryPrice && price >= position.takeProfit;
      }
    }

    return show;
  }, [position?.stopLoss, position?.takeProfit, position?.positionSize, price, positionEntryPrice]);

  const isTopBracket = useMemo(() => {
    return isBracket && price >= positionEntryPrice;
  }, [isBracket, price, positionEntryPrice]);

  const isBottomBracket = useMemo(() => {
    return isBracket && price <= positionEntryPrice;
  }, [isBracket, price, positionEntryPrice]);

  const showBracketType = useMemo(() => {
    if (isBracket) {
      if (position.positionSize < 0 && orderType == OrderPromptType.Buy) {
        if (price < positionEntryPrice) {
          return [styles.leftBracketLine, styles.greenLine];
        } else if (price == positionEntryPrice)  {
          if (position?.stopLoss){
            return [styles.leftBracketLine, styles.redLine];
          }
          return [styles.leftBracketLine, styles.greenLine];
        
        } else {
          return [styles.leftBracketLine, styles.redLine];
        }
      } else if (position.positionSize > 0 && orderType == OrderPromptType.Sell) {
        if (price > positionEntryPrice) {
          return [styles.rightBracketLine, styles.greenLine];
        } else if (price == positionEntryPrice) {
          if (position?.takeProfit){
            return [styles.rightBracketLine, styles.greenLine]
          }
          return [styles.rightBracketLine, styles.redLine]    
        } else{
          return [styles.rightBracketLine, styles.redLine];
        }
      }
    }

    return '';
  }, [isBracket, position?.positionSize, positionEntryPrice, orderType, price]);

  const { changeOrderPrice, cancelOrder } = useOrders();

  const order = useMemo(() => {
    const priceOrders = orders.get(price) || [];

    const buyOrders = priceOrders?.filter((x) => x.positionSize > 0);
    const sellOrders = priceOrders?.filter((x) => x.positionSize < 0);

    const orderGroupIds = priceOrders.map((y) => linkedOrderMap.get(y.id) || null).filter((y) => y != null);
    const orderGroupId = orderGroupIds.length > 0 ? orderGroupIds[0] : null;

    if (orderType == OrderPromptType.Buy) {
      if (buyOrders.length == 0) {
        return <DomEmptyOrder changeOrderPrice={changeOrderPrice} type={orderType} price={price} />;
      } else {
        return (
          <DomOrder
            orderGroupId={orderGroupId}
            isHighlight={!!orderGroupId && orderGroupId == highlightGroup}
            setHighlightId={setHighlightGroup}
            canTrade={canTrade}
            leftSide={true}
            cancel={cancelOrder}
            orders={buyOrders}
            price={price}
            isSltp={buyOrders.some((y) => y.id == position?.stopLossOrderId || y.id == position?.takeProfitOrderId)}
            changeOrderPrice={changeOrderPrice}
            positionEntryPrice={positionEntryPrice}
          />
        );
      }
    } else {
      if (sellOrders.length == 0) {
        return <DomEmptyOrder changeOrderPrice={changeOrderPrice} type={orderType} price={price} />;
      } else {
        return (
          <DomOrder
            orderGroupId={orderGroupId}
            isHighlight={!!orderGroupId && orderGroupId == highlightGroup}
            setHighlightId={setHighlightGroup}
            canTrade={canTrade}
            leftSide={false}
            cancel={cancelOrder}
            orders={sellOrders}
            price={price}
            isSltp={sellOrders.some((y) => y.id == position?.stopLossOrderId || y.id == position?.takeProfitOrderId)}
            changeOrderPrice={changeOrderPrice}
            positionEntryPrice={positionEntryPrice}
          />
        );
      }
    }
  }, [orders, orderType, price, isBracket, position?.stopLossOrderId, position?.takeProfitOrderId, linkedOrderMap, highlightGroup, positionEntryPrice]);

  return useMemo(
    () => (
      <div className={classNames(showBracketType, isTopBracket && styles.topBracket, isBottomBracket && styles.bottomBracket)}>
        {order}
        {isLod && orderType == OrderPromptType.Sell && <div className={styles.redHeader}>LOD</div>}
        {isHod && orderType == OrderPromptType.Buy && <div className={styles.greenHeader}>HOD</div>}
      </div>
    ),
    [order, isHod, isLod, orderType, showBracketType, isTopBracket, isBottomBracket]
  );
};

export default React.memo(OrderCell);
