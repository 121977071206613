import { SymbolMetadata, UserContractModel } from '@/api/userApi';
import Decimal from 'decimal.js';
import { number } from 'yup';

export const roundToTickSize = (baseNumber: number, tickSize: number) => {
  return new Decimal(baseNumber).div(tickSize).round().mul(tickSize).toNumber();
};

export const convertToFractional = (base: number, priceScale: number, minMov: number, minMov2: number | null = null) => {
  const baseDecimal = new Decimal(Math.abs(base));
  const truncated = baseDecimal.trunc();
  const decimals = baseDecimal.sub(truncated);
  const isNegative = base < 0;
  if (minMov2 === null || minMov2 === 0) {
    var firstFraction = decimals.mul(priceScale);
    var firstFractionTruncated = firstFraction.trunc();

    return `${isNegative ? '-' : ''}${truncated.toString()}'${firstFractionTruncated.abs()}`;
  } else {
    var firstFraction = decimals.mul(priceScale).div(minMov2);
    var firstFractionTruncated = firstFraction.trunc();

    var secondFraction = firstFraction.sub(firstFractionTruncated).mul(10);
    var secondFractionTruncated = secondFraction.trunc();

    return `${isNegative ? '-' : ''}${truncated.toString()}'${firstFractionTruncated.abs()}'${secondFractionTruncated.abs()}`;
  }
};

interface FractionNumbers {
  whole: number;
  first: number;
  second: number | null;
}

export const combineFractions = (wholeNumber: number, firstFraction: number, secondFraction: number | null, priceScale: number, minMov: number, minMov2: number | null = null) => {
  if (minMov2 === null || minMov2 === 0) {
    const wholeDecimal = new Decimal(wholeNumber);
    const firstDecimal = new Decimal(firstFraction).div(priceScale);
    return wholeDecimal.add(firstDecimal).toNumber();
  } else {
    const wholeDecimal = new Decimal(wholeNumber);
    const firstDecimal = new Decimal(new Decimal(secondFraction).div(10).add(firstFraction)).mul(minMov2).div(priceScale);
    return wholeDecimal.add(firstDecimal).toNumber();
  }
};

export const getFractions = (base: number, priceScale: number, minMov: number, minMov2: number | null = null): FractionNumbers => {
  const baseDecimal = new Decimal(base);
  const truncated = baseDecimal.trunc();
  const decimals = baseDecimal.sub(truncated);

  if (minMov2 === null || minMov2 === 0) {
    var firstFraction = decimals.mul(priceScale);
    var firstFractionTruncated = firstFraction.trunc();

    return { whole: truncated.toNumber(), first: firstFractionTruncated.toNumber(), second: null };
  } else {
    var firstFraction = decimals.mul(priceScale).div(minMov2);
    var firstFractionTruncated = firstFraction.trunc();

    var secondFraction = firstFraction.sub(firstFractionTruncated).mul(10);
    var secondFractionTruncated = secondFraction.trunc();

    return { whole: truncated.toNumber(), first: firstFractionTruncated.toNumber(), second: secondFractionTruncated.toNumber() };
  }
};

export const roundToNearestTick = (n: number, contract: UserContractModel) => {
  const part1 = new Decimal(n);
  const divided = part1.div(contract.tickSize);
  const rounded = divided.round();
  const multiplied = rounded.mul(contract.tickSize);
  const truncated = multiplied.toDecimalPlaces(contract.decimalPlaces);

  return truncated.toNumber();
};
