import { Message } from '@mui/icons-material';
import { UpdateOptions, toast } from 'react-toastify';

export const showLoader = (message: string, isMobile?: boolean): ((result: string, error?: boolean) => void) => {
  const defaultOptions: UpdateOptions = {
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    hideProgressBar: true,
    autoClose: 3000,
    position: isMobile ? 'top-right' : 'bottom-center',
    toastId: 'loader'
  };
  if (toast.isActive('loader')) {
    toast.update('loader', { ...defaultOptions, type: 'info', render: message });
  } else {
    toast(message, { ...defaultOptions, type: 'info' });
  }
  return (result, error) => {
    if (toast.isActive('loader')) {
      toast.update('loader', { ...defaultOptions, render: result, type: error === true ? 'error' : 'success' });
    } else {
      toast(result, { ...defaultOptions, type: error === true ? 'error' : 'success' });
    }
  };
};
