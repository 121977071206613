import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './ModalContext.module.scss';
import { BrowserView, MobileView, getUA, isBrowser, isMobile, isTablet } from 'react-device-detect';

export interface IDeviceContext {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const DeviceContext = React.createContext<IDeviceContext>({} as any);
export const useDeviceContext = () => React.useContext<IDeviceContext>(DeviceContext);

function DeviceContextProvider({ children }: any) {
  const providerValues = useMemo<IDeviceContext>(() => {
    return { isMobile: isMobile && !isTablet, isTablet: isTablet, isDesktop: !isMobile && !isTablet };
  }, [isMobile]);

  return <DeviceContext.Provider value={providerValues}>{children}</DeviceContext.Provider>;
}

export default DeviceContextProvider;