import { IChartWidgetApi, IChartingLibraryWidget, ResolutionString, Timezone, widget, TradingTerminalWidgetOptions, IActionVariant, ActionsFactory, ActionId, CustomIndicator, WatermarkContentData } from '@/charting_library';
import { QuotesProvider } from '../../../../datafeed/quotesProvider';
import { Requester } from 'datafeeds2/lib/requester';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TopStepDataFeed } from '../../../../datafeed/datafeed';
import { useCqg } from '../../../../contexts/CqgContext';
import { formatContractPrice } from '../../../../helpers/formatter';
import { useSymbol } from '../../../../contexts/SymbolContext';
import { calculatePnl, OrderPromptType, useOrders } from '../../../../contexts/OrdersContext';
import { AuthorizationHeaders, useApi } from '../../../../contexts/ApiContext';
import config, { TradingPlatforms } from '../../../../config';
import { OrderType, SymbolMetadata, TradingAccountStatus, UserContractModel } from '../../../../api/userApi';
import { roundToNearestTick, roundToTickSize } from 'src/helpers/decimalHelper';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { useLinked } from '@/contexts/LinkedContext';
import { useSettings } from '@contexts/SettingsContext';
import { SaveLoadAdater as SaveLoadAdapter } from '@/views/trader/components/charts/saveLoadAdapter';
import LinkIndicator from '@/views/trader/components/charts/linkIndicator';
import { TsModal } from '@/components/modal';
import { Box, Stack } from '@mui/material';
import Heading, { HeadingSize } from '@/components/topstep/heading';
import { StyledButton } from '@/components/styledComponents';
import ChartSettings from '@/components/topstep/chartSettings';
import { ChartGroup, ChartInstanceData } from '@/views/trader/components/charts/chartTypes';
import ChartPosition from '@/views/trader/components/charts/chartPosition';
import ChartOrders from '@/views/trader/components/charts/chartOrders';
import ChartTradeExecutions from '@/views/trader/components/charts/chartTradeExecutions';
import HoagsIndicator, { HoagsLevels } from './hoagsIndicator';
import { StrapiHoagsModel } from '@/api/dataApi';
import { useModal } from '@/contexts/ModalContext';
import ConfirmOrderModal from '@/components/topstep/confirmOrderModal';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { QuoteData } from '@/models/quoteData';
import QuantityButtons from '@/components/topstep/quantityButtons';
import styles from './chart.module.scss';
import { ContractPriceSpan } from '@/components/numbers';
import MobileWebChartTrade from './mobileWebChartTrade';
import { useMobile } from '@/contexts/MobileContext';
import { TradingEnvironment } from '@/data/tradingEnvironment';

interface SubInfoDetails {
  timer: NodeJS.Timeout;
  lastQuote: number;
  currentQuote: number;
  callbacks: SubInfoCallback[];
}

const ORDER_ADJUST_SIDE = 10;
interface SubInfoCallback {
  symbolId: string;
  callbackId: number;
  guid: string;
  callback: (data: any) => void;
}

interface ChartState {
  name: string;
  layout: string;
  charts: object[];
  symbolLock: number;
  intervalLock: number;
  trackTimeLock: number;
  dateRangeLock: number;
  crosshairLock: number;
  layoutsSizes: object;
}

interface SubInfo {
  symbolId: string;
  callback: string;
  subId: number;
}

interface ChartProps {
  tabData: {
    layoutName: string;
    onChanged: () => void;
  };

  layoutName?: string;
  mobile?: boolean;
}

const tradeAmounts = [1, 2, 3, 4, 5, 10, 15];

const Chart: React.FC<ChartProps> = (props): JSX.Element => {
  // Global State //

  const [chartGroups, setChartGroups] = useState<ChartGroup[]>([]);
  const { quotesApi, historyApi, timeApi, marksApi, searchApi, configApi, symbolsApi } = useApi();
  const { customSettings, saveCustomSettings, showConfirmations } = useSettings();
  const { tabData, layoutName } = props;
  const apiContext = useApi();

  const { getContractByProductName, getContractByProductId } = useSymbol();

  const containerRef = useRef(null);
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget>(null);
  const { activeTradingAccount, tradingEnvironment } = useTradingAccount();
  const { subscribeBars, unsubscribeBars, subscribeQuotesForSymbol, unsubscribeQuotesForSymbol, subscribeOnChartReconnect, unsubscribeOnChartReconnect } = useCqg();
  const { nonSltpOrders: orders, placeOrderWithSymbol: placeOrder } = useOrders();
  const isMounted = useRef(true);
  const canTrade = useRef(true);
  const [showLinkHelpModal, setShowLinkHelpModal] = useState(false);
  const [isNewUser, setIsNewUser] = useState(customSettings.addedChartLink === undefined ? true : false);
  const isNewUserRef = useRef(isNewUser);
  const [showChartSettings, setShowChartSettings] = useState(false);
  const { showModal, hideModal } = useModal();
  const { mobileContract, setMobileContract } = useMobile();
  const { isMobile } = useDeviceContext();

  // Desktop state //

  const { links } = useLinked();
  const linkedContext = useLinked();
  const linksRef = useRef(links);
  const [linkChanged, setLinkChanged] = useState<boolean>(false);

  // Mobile state //

  const [quoteData, setQuoteData] = useState<QuoteData>(undefined);
  const appliedLastChart = useRef(false);
  const { placeOrderWithSymbol } = useOrders();
  const [orderAmount, setOrderAmount] = useState<number>(1);
  const [showFlash, setShowFlash] = useState<string>();
  const [prevLastPrice, setPrevLastPrice] = useState<number | null>(null); // Keep track of previous price
  const [showSlideOut, setShowSlideOut] = useState<boolean>(false);
  const { activePositions, editSltpSetting } = useOrders();

  // Desktop Hooks //

  const getContractByProductNameRef = useRef(getContractByProductName);
  const getContractByProductIdRef = useRef(getContractByProductId);

  useEffect(() => {
    getContractByProductNameRef.current = getContractByProductName;
    getContractByProductIdRef.current = getContractByProductId;
  }, [getContractByProductName, getContractByProductId]);

  useEffect(() => {
    if (isMobile) return;

    if (isNewUser) {
      setIsNewUser(false);
      saveCustomSettings({ addedChartLink: true });
    }
  }, [isNewUser, saveCustomSettings]);

  useEffect(() => {
    if (isMobile) return;

    linksRef.current = links;

    if (!topStepFeed.current) return;
    if (!widgetRef.current) return;

    topStepFeed.current.setLinksMap(links);

    let changedCharts = [];

    for (let i = 0; i < widgetRef.current.chartsCount(); i++) {
      const chart = widgetRef.current.chart(i);
      const colorStudy = chart.getAllStudies().find((y) => y.name === 'Color Link');
      if (colorStudy) {
        const study = chart.getStudyById(colorStudy.id);
        const color = study.getInputValues()[0];
        if (color) {
          const colorValue = color.value as string;
          const linkSymbol = Object.values(links).find((y) => y.text === colorValue);

          if (study.isVisible() && linkSymbol && !!linkSymbol.symbol?.productName && linkSymbol.symbol?.productName !== chart.symbol()) {
            changedCharts.push(chart);
            chart.setSymbol(linkSymbol.symbol.productName);
          }
        }
      }
    }

    if (linkChanged) setLinkChanged(false);

    const linkKeys = Object.values(links);
    for (const chartGroup of chartGroups) {
      const linkText = chartGroup.symbol.substring(0, chartGroup.symbol.indexOf('/'));
      if (linkText.length > 0) {
        const link = linkKeys.filter((y) => y.text == linkText);
        if (link.length > 0) {
          if (chartGroup.actualSymbol != link[0].symbol.productId) {
            chartGroup.actualSymbol = link[0].symbol.productId;
            for (const chart of chartGroup.charts) {
              if (changedCharts.includes(chart.chart)) continue;
              chart.chart.setSymbol(link[0].text + link[0].symbol.productId);
            }
          }
        }
      }
    }
  }, [links, chartGroups, linkChanged]);

  // Mobile Hooks //

  useEffect(() => {
    if (!isMobile) return;

    if (!mobileContract) {
      return;
    }

    let initialQuote = true;
    const id = subscribeQuotesForSymbol(mobileContract.productId, (quote) => {
      setQuoteData((prev) => {
        if (initialQuote) {
          initialQuote = false;
        }
        return {
          ...prev,
          ...quote
        };
      });
    });

    return () => {
      setQuoteData(undefined);
      unsubscribeQuotesForSymbol(mobileContract.productId, id);
    };
  }, [mobileContract]);

  // Retrieve order amount from local storage
  useEffect(() => {
    if (!isMobile) return;

    const savedOrderAmount = localStorage.getItem('mobile-order-amount');
    if (savedOrderAmount) {
      setOrderAmount(parseInt(savedOrderAmount));
    }
  }, []);

  // Set order amount to local storage for persistence
  useEffect(() => {
    if (!isMobile) return;

    localStorage.setItem('mobile-order-amount', orderAmount.toString());
  }, [orderAmount]);

  const quickBuy = async () => {
    const buyCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: mobileContract.productId,
        orderType: OrderPromptType.Buy,
        type: OrderType.Market
      });
    };

    if (showConfirmations) {
      showModal(<ConfirmOrderModal contract={mobileContract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={orderAmount} type={OrderType.Market} side={OrderPromptType.Buy} onConfirm={buyCallback} />);
    } else {
      await buyCallback();
    }
  };

  const quickSell = async () => {
    const sellCallback = async () => {
      hideModal();
      await placeOrderWithSymbol({
        amount: orderAmount,
        symbol: mobileContract.productId,
        orderType: OrderPromptType.Sell,
        type: OrderType.Market
      });
    };

    if (showConfirmations) {
      showModal(<ConfirmOrderModal contract={mobileContract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={orderAmount} type={OrderType.Market} side={OrderPromptType.Sell} onConfirm={sellCallback} />);
    } else {
      await sellCallback();
    }
  };

  const tradeEnabled = useMemo(() => {
    return activeTradingAccount.isFollower !== true && (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible);
  }, [activeTradingAccount.isFollower, activeTradingAccount?.status]);

  const flashStyle = {
    transition: 'color 0.1s ease-in-out'
  };

  const handleSlideOut = () => {
    setShowSlideOut((prev) => !prev);
  };

  // Compare askPrice with the previous value and trigger flashing effect
  useEffect(() => {
    let timerId;

    if (prevLastPrice !== null && quoteData?.bestAsk !== null && quoteData?.lastPrice != null) {
      if (quoteData.lastPrice > prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#43984d');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      } else if (quoteData.lastPrice < prevLastPrice) {
        timerId = setTimeout(() => {
          setShowFlash('#bd3f3b');
          setTimeout(() => {
            setShowFlash('');
          }, 1000);
        }, 0);
      }
    }
    if (quoteData?.lastPrice) setPrevLastPrice(quoteData.lastPrice);
    return () => clearTimeout(timerId);
  }, [quoteData?.lastPrice, quoteData?.bestAsk]);

  const activePosition = useMemo(() => {
    if (!mobileContract) return;
    const activePosList = activePositions.filter((y) => y.symbolId == mobileContract.productId);
    return activePosList.length > 0 ? activePosList[0] : null;
  }, [activePositions, mobileContract]);

  const showPnl = () => {
    if (quoteData?.lastPrice === undefined) return 0;
    return calculatePnl(activePosition.positionSize, activePosition.averagePrice, quoteData.lastPrice, mobileContract.pointValue);
  };

  const positionEntryPrice = useMemo(() => {
    if (!activePosition) return 0;
    return roundToNearestTick(activePosition.averagePrice, mobileContract);
  }, [activePosition?.averagePrice, mobileContract]);

  const breakEven = useCallback(() => {
    if (activePosition) {
      editSltpSetting(activePosition.id, positionEntryPrice, activePosition.takeProfit);
    }
  }, [activePosition, positionEntryPrice, activePosition?.id, activePosition?.takeProfit]);

  const handleSetContract = (symbol: UserContractModel) => {
    if (tvWidget) {
      const chart = tvWidget.activeChart();
      if (chart) {
        chart.setSymbol(symbol?.productName);
      }
    }
  };

  // Global Hooks //

  useEffect(() => {
    console.log('Reconnected to chart data. Requesting fresh bars.');

    const onReconnectCallback = () => {
      if (widgetRef.current) {
        for (let i = 0; i < widgetRef.current.chartsCount(); i++) {
          const chart = widgetRef.current.chart(i);
          topStepFeed.current.resetCache(chart.symbol());
        }
        for (let i = 0; i < widgetRef.current.chartsCount(); i++) {
          const chart = widgetRef.current.chart(i);
          chart.resetData();
        }
      }
    };
    subscribeOnChartReconnect(onReconnectCallback);

    return () => {
      unsubscribeOnChartReconnect(onReconnectCallback);
    };
  }, []);

  const hoagsData = useRef<StrapiHoagsModel>(null);

  const getHoagsData = () => {
    if (config.platform !== TradingPlatforms.TopstepX) return;

    apiContext.strapiApi.getHoags().then((data) => {
      hoagsData.current = data;
    });
  };

  // Load hoags data every 5 minutes
  useEffect(() => {
    if (config.platform !== TradingPlatforms.TopstepX) return;

    getHoagsData();
    const interval = setInterval(
      () => {
        getHoagsData();
      },
      1000 * 60 * 300
    );
    return () => clearInterval(interval);
  }, []);

  const getHoagsLevels = (symbol: string) => {
    const data = hoagsData?.current?.data?.find((x) => x.attributes.symbol === symbol)?.attributes;

    const hoagsLevels: HoagsLevels = {
      LWH: data?.lastWeekHigh,
      YH: data?.yesterdayHigh,
      YVAH: data?.yesterdayVAH,
      VPOC: data?.yesterdayVPOC,
      S: data?.settlement,
      YVAL: data?.yesterdayVAL,
      YL: data?.yesterdayLow,
      WKOL: data?.wkoLow,
      WKOH: data?.wkoHigh,
      LWL: data?.lastWeekLow
    };

    return hoagsLevels;
  };

  const widgetRef = useRef<IChartingLibraryWidget>(null);
  const subscribeBarsCallbackRef = useRef<Map<string, SubInfoDetails>>(new Map());
  const subscriptions = useRef<Map<string, SubInfo>>(new Map());

  const topStepFeed = useRef<TopStepDataFeed>(null);
  const [hideEconomicEvents, setHideEconomicEvents] = useState<boolean>(customSettings.hideEconomicEvents);

  useEffect(() => {
    setHideEconomicEvents(customSettings.hideEconomicEvents);
  }, [customSettings.hideEconomicEvents]);

  useEffect(() => {
    if (!topStepFeed.current) return;

    if (customSettings.hideEconomicEvents === true) {
      topStepFeed.current.disableMarks();
    } else {
      topStepFeed.current.enableMarks();
    }

    if (!tvWidget) return;

    var chartCount = tvWidget.chartsCount();

    for (let i = 0; i < chartCount; i++) {
      var chart = tvWidget.chart(i);
      if (hideEconomicEvents === true) {
        chart.clearMarks();
      } else {
        chart.refreshMarks();
      }
    }
  }, [tvWidget, hideEconomicEvents]);

  useEffect(() => {
    canTrade.current = activeTradingAccount.isFollower !== true;
  }, [activeTradingAccount]);

  useEffect(() => {
    return () => {
      console.log('Unmounting');
      isMounted.current = false;
    };
  }, []);

  const unsubscribeBarsWrapper = useCallback(
    (guid: string, callbackId: number) => {
      const subscriptionSymbol = subscriptions.current.get(guid);
      if (subscriptionSymbol) {
        let existingSubsBySymbol = subscribeBarsCallbackRef.current.get(subscriptionSymbol.symbolId);
        existingSubsBySymbol.callbacks = existingSubsBySymbol.callbacks.filter((x) => x.guid != guid);
        subscribeBarsCallbackRef.current.set(subscriptionSymbol.symbolId, existingSubsBySymbol);

        if (existingSubsBySymbol.callbacks.length == 0) {
          clearInterval(existingSubsBySymbol.timer);
        }
      }

      return unsubscribeBars(guid, callbackId);
    },
    [unsubscribeBars]
  );

  const subscribeBarsWrapper = useCallback((symbolId: number, resolution: string, guid: string, callback: (data: any) => void) => {
    const callbackId = subscribeBars(symbolId, resolution, guid, callback);

    let existingSubs = subscribeBarsCallbackRef.current.get(symbolId + '');
    if (!existingSubs) {
      existingSubs = {
        timer: null,
        lastQuote: null,
        currentQuote: null,
        callbacks: []
      };
    }

    if (existingSubs.callbacks.length == 0) {
      existingSubs.timer = setInterval(() => {
        if (existingSubs.currentQuote != existingSubs.lastQuote) {
          existingSubs.lastQuote = existingSubs.currentQuote;
          for (const callback of existingSubs.callbacks) {
            callback.callback({ isQuote: true, close: existingSubs.lastQuote });
          }
        }
      }, 100);
    }

    const obj: SubInfoCallback = {
      callback: callback,
      callbackId: callbackId,
      guid: guid,
      symbolId: symbolId + ''
    };
    existingSubs.callbacks.push(obj);
    subscriptions.current.set(guid, {
      callback: callbackId + '',
      subId: symbolId,
      symbolId: symbolId + ''
    });
    subscribeBarsCallbackRef.current.set(symbolId + '', existingSubs);
    return callbackId;
  }, []);

  const updateCharts = useCallback(() => {
    const newTvWidget = widgetRef.current;

    const charts: ChartGroup[] = [];
    const activeCharts: IChartWidgetApi[] = [];

    for (let i = 0; i < newTvWidget.chartsCount(); i++) {
      const chart = newTvWidget.chart(i);
      activeCharts.push(chart);

      chart.onSymbolChanged().subscribe(chart, (...args) => {
        chart.onSymbolChanged().unsubscribeAll(chart);

        if (!isMobile) {
          // Desktop uses links
          const studies = chart.getAllStudies();
          const colorStudy = studies.find((x) => x.name == 'Color Link');
          if (colorStudy) {
            const study = chart.getStudyById(colorStudy.id);
            const color = study.getInputValues()[0];
            if (color.value) {
              const link = Object.values(linksRef.current).find((x) => x.text == color.value);
              if (study.isVisible() && link && link.symbol?.productName != chart.symbol()) {
                const symbol = getContractByProductNameRef.current(chart.symbol());
                if (symbol) {
                  linkedContext.changeLinkSymbol(link.color, symbol);
                }
              }
            }
          }

          // Desktop sets up pivots
          chart.onDataLoaded().subscribe(
            chart,
            () => {
              try {
                const pivotStudy = chart.getAllStudies().find((x) => x.name == 'Pivot Points Standard');
                const fiftyTwoWeekStudy = chart.getAllStudies().find((x) => x.name == '52 Week High/Low');
                if (pivotStudy) {
                  chart.removeEntity(pivotStudy.id);
                }
                if (fiftyTwoWeekStudy) {
                  chart.removeEntity(fiftyTwoWeekStudy.id);
                }
              } catch (e) {
                console.log('Error setting pivot points', e);
              }
            },
            true
          );
        } else {
          // Mobile sets up contract
          if (chart === newTvWidget.activeChart()) {
            setMobileContract(getContractByProductNameRef.current(chart.symbol()));
          }
        }

        chart.dataReady(() => {
          updateCharts();
        });
      });

      if (isMobile) {
        // Mobile grabs last active chart from local storage
        if (!appliedLastChart.current) {
          // if this is the last active mobile chart, set the symbol to the last active contract
          const lastActiveMobileChart = localStorage.getItem('lastActiveMobileChart');
          if (lastActiveMobileChart && parseInt(lastActiveMobileChart) === i) {
            newTvWidget.setActiveChart(i);
            appliedLastChart.current = true;
            chart.setSymbol(mobileContract?.productName);

            const clean = getContractByProductNameRef.current(mobileContract?.productName);
            setMobileContract(clean);
          }
        }
      }
    }

    const linkValues = Object.values(linksRef.current);

    const chartsBySymbol = activeCharts.reduce((acc, chart) => {
      let symbol = chart.symbol();
      let symbolId = getContractByProductNameRef.current(symbol)?.productId;
      if (!symbolId) {
        symbolId = symbol;
      }
      if (!acc[symbolId]) {
        acc[symbolId] = [];
      }
      acc[symbolId].push(chart);
      return acc;
    }, {});

    for (const symbol in chartsBySymbol) {
      let color = '';
      if (symbol.indexOf('/') > -1) {
        color = symbol.substring(0, symbol.indexOf('/'));
      }

      const link = isMobile ? undefined : linkValues.filter((x) => x.text == color);
      const actualSymbol = isMobile ? symbol : link.length > 0 ? link[0].symbol.productId : symbol;
      const contract = getContractByProductIdRef.current(actualSymbol);

      const inst: ChartGroup = {
        actualSymbol,
        metadata: contract,
        eventHandlers: [],
        updateTimer: setInterval(() => {
          if (inst.lastPriceForPnl != inst.lastPrice) {
            inst.lastPriceForPnl = inst.lastPrice;
            for (const handler of inst.eventHandlers) {
              handler(inst);
            }
          }
        }, 500),
        symbol: symbol,
        charts: chartsBySymbol[symbol].map((x) => {
          const chartInst: ChartInstanceData = {
            chart: x,
            positionId: null,
            positionLine: null,
            stopLossLine: null,
            takeProfitLine: null,
            lastPnl: null,
            lastSize: null,
            lastAvgPrice: null,
            lastStoploss: null,
            lastTakeProfit: null,
            lastRisk: null,
            lastToMake: null
          };
          return chartInst;
        }),
        tickSize: contract?.tickSize,
        lastPrice: null,
        lastPriceForPnl: null
      };

      for (const i of inst.charts) {
        (i.chart as any).chartGrp = inst;
      }

      charts.push(inst);
    }

    setChartGroups(charts);
  }, []);

  useEffect(() => {
    if (tvWidget) {
      updateCharts();
    }
  }, [tvWidget]);

  // creates the chart widget
  useEffect(() => {
    const requester = new Requester(AuthorizationHeaders);

    const quotesProvider = new QuotesProvider(quotesApi);
    var feed = new TopStepDataFeed(
      config.chartApi,
      historyApi,
      marksApi,
      timeApi,
      searchApi,
      configApi,
      symbolsApi,
      quotesProvider,
      requester,
      subscribeBarsWrapper,
      unsubscribeBarsWrapper,
      links,
      {
        maxLength: 25000,
        expectedOrder: null
      },
      false,
      customSettings.hideEconomicEvents,
      tradingEnvironment == TradingEnvironment.Live,
    );

    topStepFeed.current = feed;
    if (!containerRef.current) return;

    let libraryUrl = '/';

    if (process.env.NODE_ENV !== 'development') {
      libraryUrl = '/charting_library/';
    }

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;
    const saveLoadAdapter = new SaveLoadAdapter(apiContext, linkedContext, isMobile);

    const widgetOptions: TradingTerminalWidgetOptions = {
      symbol: '/ES',
      datafeed: feed,
      interval: '15' as ResolutionString,
      save_load_adapter: saveLoadAdapter,

      //interval: 'H' as ResolutionString,
      time_frames: [
        {
          text: '1y',
          resolution: '1D' as any,
          description: '1 Year',
          title: '1yr'
        },
        {
          text: '3m',
          resolution: '1D' as any,
          description: '3 Month'
        },
        {
          text: '1m',
          resolution: '1D' as any,
          description: '1 Month'
        },
        {
          text: '5d',
          resolution: '30',
          description: '5 Days'
        },
        {
          text: '3d',
          resolution: '15',
          description: '3 Day'
        },
        {
          text: '1d',
          resolution: '30',
          description: '1 Day'
        }
      ],
      container: containerRef.current,
      library_path: libraryUrl,
      auto_save_delay: isMobile ? 3 : 10,

      locale: 'en',
      theme: 'dark',
      disabled_features: isMobile
        ? [
            'popup_hints',
            'order_panel',
            'open_account_manager',
            'trading_account_manager',
            'timeframes_toolbar',
            'header_compare',
            'header_fullscreen_button',
            'header_undo_redo',
            'header_screenshot',
            'header_settings',
            'symbol_info',
            'use_localstorage_for_settings'
          ]
        : ['popup_hints', 'right_toolbar', 'order_panel', 'open_account_manager', 'trading_account_manager'],
      custom_font_family: "'Roboto Condensed', sans-serif",
      load_last_chart: true,
      charts_storage_url: config.userApi + '/charts',
      charts_storage_api_version: '1.1',
      client_id: 'topstep',
      user_id: apiContext.token,
      timezone: tz,
      fullscreen: false,

      autosize: true,
      context_menu: isMobile
        ? undefined
        : {
            items_processor: (items: readonly IActionVariant[], actionsFactory: ActionsFactory) => {
              const customItems = items.filter((y) => (y as any)._options?.label == 'Custom');
              const customItem = customItems.length > 0 ? customItems[0] : null;
              let price: number | null = null;
              let chartGrp: ChartGroup | null = null;

              if (customItem) {
                [price, chartGrp] = (customItem as any)._options.onExecute();
              }

              return new Promise((resolve) => {
                const newItems = [...items.filter((y) => (y as any)._options?.label != 'Custom')];
                if (chartGrp?.lastPrice && canTrade.current) {
                  const lastPrice = chartGrp.lastPrice;

                  const marketBuyItems = tradeAmounts.map((amt) => {
                    return actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Market Buy ${amt}`,
                      onExecute: function () {
                        const buyCallback = () => {
                          hideModal();
                          placeOrder({
                            amount: amt,
                            symbol: chartGrp.actualSymbol,
                            type: OrderType.Market,
                            orderType: OrderPromptType.Buy
                          });
                        };

                        const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                        if (showConfirmations) {
                          showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={amt} type={OrderType.Market} side={OrderPromptType.Buy} onConfirm={buyCallback} />);
                        } else {
                          buyCallback();
                        }
                      }
                    });
                  });

                  const marketBuyAction = actionsFactory.createAction({
                    actionId: 'Chart.ExternalActionId' as ActionId,
                    label: `Market Buy`,
                    subItems: marketBuyItems
                  });

                  const marketSellItems = tradeAmounts.map((amt) => {
                    return actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Market Sell ${amt}`,
                      onExecute: function () {
                        const sellCallback = () => {
                          hideModal();
                          placeOrder({
                            amount: amt,
                            symbol: chartGrp.actualSymbol,
                            type: OrderType.Market,
                            orderType: OrderPromptType.Sell
                          });
                        };

                        const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                        if (showConfirmations) {
                          showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={undefined} trailDistance={undefined} orderAmount={amt} type={OrderType.Market} side={OrderPromptType.Sell} onConfirm={sellCallback} />);
                        } else {
                          sellCallback();
                        }
                      }
                    });
                  });
                  3;

                  const marketSellAction = actionsFactory.createAction({
                    actionId: 'Chart.ExternalActionId' as ActionId,
                    label: `Market Sell`,
                    subItems: marketSellItems
                  });

                  if (price > lastPrice) {
                    const sellLimitItems = tradeAmounts.map((amt) => {
                      return actionsFactory.createAction({
                        actionId: 'Chart.ExternalActionId' as ActionId,
                        label: `Limit Sell ${amt} @ ${formatContractPrice(price, chartGrp.metadata)}`,
                        onExecute: function () {
                          const limitSellCallback = () => {
                            hideModal();
                            placeOrder({
                              amount: amt,
                              symbol: chartGrp.actualSymbol,
                              limitPrice: price,
                              type: OrderType.Limit,
                              orderType: OrderPromptType.Sell
                            });
                          };

                          const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                          if (showConfirmations) {
                            showModal(<ConfirmOrderModal contract={contract} limitPrice={price} stopPrice={undefined} trailDistance={undefined} orderAmount={amt} type={OrderType.Limit} side={OrderPromptType.Sell} onConfirm={limitSellCallback} />);
                          } else {
                            limitSellCallback();
                          }
                        }
                      });
                    });

                    const sellAction = actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Limit Sell @ ${formatContractPrice(price, chartGrp.metadata)}`,
                      subItems: sellLimitItems
                    });

                    const buyStopitems = tradeAmounts.map((amt) => {
                      return actionsFactory.createAction({
                        actionId: 'Chart.ExternalActionId' as ActionId,
                        label: `Stop Buy ${amt} @ ${formatContractPrice(price, chartGrp.metadata)}`,
                        onExecute: function () {
                          const stopBuyCallback = () => {
                            hideModal();
                            placeOrder({
                              amount: amt,
                              symbol: chartGrp.actualSymbol,
                              stopPrice: price,
                              type: OrderType.Stop,
                              orderType: OrderPromptType.Buy
                            });
                          };

                          const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                          if (showConfirmations) {
                            showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={price} trailDistance={undefined} orderAmount={amt} type={OrderType.Stop} side={OrderPromptType.Buy} onConfirm={stopBuyCallback} />);
                          } else {
                            stopBuyCallback();
                          }
                        }
                      });
                    });

                    const buyStopAction = actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Stop Buy @ ${formatContractPrice(price, chartGrp.metadata)}`,
                      subItems: buyStopitems
                    });

                    newItems.unshift(sellAction);
                    newItems.unshift(buyStopAction);
                  } else if (price < lastPrice) {
                    const buyLimitItems = tradeAmounts.map((amt) => {
                      return actionsFactory.createAction({
                        actionId: 'Chart.ExternalActionId' as ActionId,
                        label: `Limit Buy ${amt} @ ${formatContractPrice(price, chartGrp.metadata)}`,
                        onExecute: function () {
                          const limitBuyCallback = () => {
                            hideModal();
                            placeOrder({
                              amount: amt,
                              symbol: chartGrp.actualSymbol,
                              limitPrice: price,
                              type: OrderType.Limit,
                              orderType: OrderPromptType.Buy
                            });
                          };

                          const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                          if (showConfirmations) {
                            showModal(<ConfirmOrderModal contract={contract} limitPrice={price} stopPrice={undefined} trailDistance={undefined} orderAmount={amt} type={OrderType.Limit} side={OrderPromptType.Buy} onConfirm={limitBuyCallback} />);
                          } else {
                            limitBuyCallback();
                          }
                        }
                      });
                    });
                    const sellStopitems = tradeAmounts.map((amt) => {
                      return actionsFactory.createAction({
                        actionId: 'Chart.ExternalActionId' as ActionId,
                        label: `Stop Sell ${amt} @ ${formatContractPrice(price, chartGrp.metadata)}`,
                        onExecute: function () {
                          const stopSellCallback = () => {
                            hideModal();
                            placeOrder({
                              amount: amt,
                              symbol: chartGrp.actualSymbol,
                              stopPrice: price,
                              type: OrderType.Stop,
                              orderType: OrderPromptType.Sell
                            });
                          };

                          const contract = getContractByProductIdRef.current(chartGrp.actualSymbol);

                          if (showConfirmations) {
                            showModal(<ConfirmOrderModal contract={contract} limitPrice={undefined} stopPrice={price} trailDistance={undefined} orderAmount={amt} type={OrderType.Stop} side={OrderPromptType.Sell} onConfirm={stopSellCallback} />);
                          } else {
                            stopSellCallback();
                          }
                        }
                      });
                    });

                    const buyAction = actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Limit Buy @ ${formatContractPrice(price, chartGrp.metadata)}`,
                      subItems: buyLimitItems
                    });

                    const sellStopAction = actionsFactory.createAction({
                      actionId: 'Chart.ExternalActionId' as ActionId,
                      label: `Stop Sell @ ${formatContractPrice(price, chartGrp.metadata)}`,
                      subItems: sellStopitems
                    });

                    newItems.unshift(buyAction);
                    newItems.unshift(sellStopAction);
                  }

                  newItems.unshift(marketSellAction);
                  newItems.unshift(marketBuyAction);
                }
                resolve(newItems);
              });
            }
          },

      custom_indicators_getter(PineJS) {
        return new Promise<CustomIndicator[]>(async (resolve) => {
          let indicators = [LinkIndicator(PineJS, () => setLinkChanged(true))];

          if (config.platform === TradingPlatforms.TopstepX) {
            indicators.push(HoagsIndicator(PineJS, 1000 * 60 * 5, getHoagsLevels));
          }

          resolve(indicators);
        });
      },
      enabled_features: isMobile
        ? ['saveload_separate_drawings_storage', 'hide_left_toolbar_by_default', 'tick_resolution', 'seconds_resolution', 'create_volume_indicator_by_default', 'countdown', 'buy_sell_buttons', 'pre_post_market_sessions']
        : [
            'saveload_separate_drawings_storage',
            'side_toolbar_in_fullscreen_mode',
            'header_in_fullscreen_mode',
            'confirm_overwrite_if_chart_layout_with_name_exists',
            'tick_resolution',
            'seconds_resolution',
            'create_volume_indicator_by_default',
            'countdown',
            'buy_sell_buttons',
            'study_templates',
            'drawing_templates',
            'pre_post_market_sessions'
          ],
      studies_overrides: {},
      favorites: isMobile
        ? {
            indicators: ['Moving Average', 'VWAP', 'Volume Profile Fixed Range'],
            chartTypes: ['Bars', 'Candles']
          }
        : {
            indicators: ['Moving Average', 'VWAP', 'Volume Profile Fixed Range'],
            drawingTools: ['LineToolTrendLine', 'LineToolFixedRangeVolumeProfile'],
            chartTypes: ['Bars', 'Candles']
          },
      studies_access: {
        type: 'black',
        tools: [
          {
            name: 'Pivot Points Standard'
          },
          {
            name: '52 Week High/Low'
          }
        ]
      },

      //timeframe: { from: start, to: end},
      overrides: isMobile
        ? {
            // Long Position
            'linetoolriskrewardlong.lotSize': 1,
            'linetoolriskrewardlong.compact': true,
            'linetoolriskrewardlong.alwaysShowStats': false,
            'linetoolriskrewardlong.showPriceLabels': false,

            // Short Position
            'linetoolriskrewardshort.lotSize': 1,
            'linetoolriskrewardshort.compact': true,
            'linetoolriskrewardshort.alwaysShowStats': false,
            'linetoolriskrewardshort.showPriceLabels': false,

            'mainSeriesProperties.sessionId': 'extended'
          }
        : {
            'paneProperties.backgroundType': 'solid' as any,
            'paneProperties.background': '#2A292F',

            // Long Position
            'linetoolriskrewardlong.lotSize': 1,
            'linetoolriskrewardlong.compact': true,
            'linetoolriskrewardlong.alwaysShowStats': false,
            'linetoolriskrewardlong.showPriceLabels': false,

            // Short Position
            'linetoolriskrewardshort.lotSize': 1,
            'linetoolriskrewardshort.compact': true,
            'linetoolriskrewardshort.alwaysShowStats': false,
            'linetoolriskrewardshort.showPriceLabels': false,

            'mainSeriesProperties.sessionId': 'extended'
          },
      settings_overrides: {
        'panelProperties.legendProperties.showSeriesTitle': false
      },

      // custom_css_url should be related to library_path
      custom_css_url: isMobile ? '/mobileChartTheme.css' : '/chart-theme.css'
    };

    const newTvWidget = new widget(widgetOptions);
    newTvWidget.applyOverrides({ 'mainSeriesProperties.sessionId': 'extended' });

    widgetRef.current = newTvWidget;
    let setWatermark = false;

    newTvWidget.subscribe('layout_about_to_be_changed', () => {
      newTvWidget.applyOverrides({ 'mainSeriesProperties.sessionId': 'extended' });
      try {
        if (!setWatermark) {
          newTvWidget.watermark().setContentProvider((data: WatermarkContentData) => {
            const info = data.symbolInfo as any;
            return [
              { text: `${info.watermarkTitle}, ${data.interval}`, fontSize: 84, lineHeight: 84, vertOffset: 0 },
              { text: info.watermarkDescription, fontSize: 84, lineHeight: 84, vertOffset: 0 }
            ];
          });
          setWatermark = true;
        }

        if (localStorage.getItem('initWatermark') !== 'true') {
          newTvWidget.watermark().visibility().setValue(true);
          localStorage.setItem('initWatermark', 'true');
        }
      } catch (e) {
        console.log('Error setting watermark', e);
      }
    });

    newTvWidget.subscribe('layout_changed', () => {
      updateCharts();
    });

    newTvWidget.subscribe('onAutoSaveNeeded', (...args) => {
      const currentLayoutName = newTvWidget.layoutName();
      if (!currentLayoutName) {
        newTvWidget.saveChartToServer(
          () => {},
          () => {},
          { defaultChartName: 'default' }
        );
      } else {
        newTvWidget.saveChartToServer();
      }
    });

    if (isMobile) {
      // when clicking / activating a chart, update the order card with the correct symbol
      // port this to desktop too eventually
      newTvWidget.subscribe('activeChartChanged', (chartIndex) => {
        const activeChart = newTvWidget.chart(chartIndex);
        const symbolName = activeChart.symbol();
        const clean = getContractByProductNameRef.current(symbolName);
        setMobileContract(clean);

        // set last active mobile chart index in local storage
        localStorage.setItem('lastActiveMobileChart', chartIndex.toString());
      });
    }

    newTvWidget.headerReady().then(function () {
      var customSettings = newTvWidget.createButton({
        useTradingViewStyle: true,
        align: 'left',
        text: '⚙ Preferences',
        onClick: function () {
          setShowChartSettings(true);
          //newTvWidget.openDialog('chartProperties');
        }
      });

      if (!isMobile) {
        var button = newTvWidget.createButton({
          useTradingViewStyle: true,
          align: 'left',
          text: '🔗 Add Chart Link',
          onClick: () => {
            const activeChart = newTvWidget.activeChart();
            const colorStudy = activeChart.getAllStudies().find((y) => y.name === 'Color Link');
            if (!colorStudy) {
              activeChart.createStudy('Color Link', false, false);
            } else {
              const study = activeChart.getStudyById(colorStudy.id);
              setShowLinkHelpModal(true);
            }
          }
        });
      }
    });

    // newTvWidget.chart
    newTvWidget.onChartReady(() => {
      if (localStorage.getItem('initSessionId') !== 'true') {
        newTvWidget.applyOverrides({ 'mainSeriesProperties.sessionId': 'extended' });
        localStorage.setItem('initSessionId', 'true');
        setTimeout(() => {
          newTvWidget.saveChartToServer(
            () => {},
            () => {},
            { defaultChartName: 'default' }
          );
        }, 1500);
      }

      if (!isMobile) {
        // Links
        if (isNewUserRef.current === true) {
          const activeChart = newTvWidget.activeChart();
          const colorStudy = activeChart.getAllStudies().find((y) => y.name === 'Color Link');
          if (!colorStudy) {
            activeChart.createStudy('Color Link', false, false, { color: '🔵' });
          }
        }

        // Context Menu
        newTvWidget.onContextMenu((unixtime, price) => {
          const activeChart = newTvWidget.activeChart() as any;
          const chartGrp = activeChart.chartGrp as ChartGroup;

          const roundedPrice = roundToTickSize(price, chartGrp.tickSize);
          let items: any[] = [
            { text: '-', position: 'top' }, // Adds a separator between buttons
            { text: '-Paste' } // Removes the existing item from the menu
          ];

          items.unshift({
            position: 'top',
            text: 'Custom',
            custom: 'price',
            click: function () {
              return [roundedPrice, chartGrp];
            }
          });
          return items;
        });
      } else {
        newTvWidget
          .activeChart()
          .onSymbolChanged()
          .subscribe(null, () => {
            const clean = getContractByProductNameRef.current(newTvWidget.activeChart().symbol());
            setMobileContract(clean);
          });
      }

      try {
        if (localStorage.getItem('initWatermark') !== 'true') {
          localStorage.setItem('initWatermark', 'true');
          newTvWidget.watermark().visibility().setValue(true);
        }
      } catch (e) {
        console.log('Error setting watermark', e);
      }

      if (!setWatermark) {
        newTvWidget.watermark().setContentProvider((data: WatermarkContentData) => {
          const info = data.symbolInfo as any;
          return [
            { text: `${info.watermarkTitle}, ${data.interval}`, fontSize: 84, lineHeight: 84, vertOffset: 0 },
            { text: info.watermarkDescription, fontSize: 84, lineHeight: 84, vertOffset: 0 }
          ];
        });
        setWatermark = true;
      }

      setTvWidget(newTvWidget);
      newTvWidget.symbolSync().setValue(false, true);
      newTvWidget.symbolSync().subscribe((data) => {
        if (data) newTvWidget.symbolSync().setValue(false, true);
      });
      newTvWidget.applyOverrides(
        isMobile
          ? {
              'mainSeriesProperties.statusViewStyle.showExchange': false,
              'mainSeriesProperties.haStyle.showRealLastPrice': true,
              'paneProperties.canvasBackground': '#1c1e23'
            }
          : {
              'mainSeriesProperties.statusViewStyle.showExchange': false,
              'mainSeriesProperties.haStyle.showRealLastPrice': true
            }
      );
    });

    return () => {
      console.log('Destroying component, deleting widget');
      setTvWidget(null);
      newTvWidget.remove();
    };
  }, [layoutName]);

  useEffect(() => {
    const subscriptions = chartGroups.map((y) => {
      const symbol = y.actualSymbol;
      const id = subscribeQuotesForSymbol(symbol, (quote) => {
        if (quote.lastPrice) {
          y.lastPrice = quote.lastPrice;
          const subs = subscribeBarsCallbackRef.current.get(symbol);
          if (subs !== undefined) {
            subs.currentQuote = quote.lastPrice;
          }
        }
      });

      return { symbol, id };
    });
    return () => {
      for (const sub of subscriptions) {
        unsubscribeQuotesForSymbol(sub.symbol, sub.id);
      }
    };
  }, [chartGroups]);

  const chart = useMemo(() => {
    return isMobile ? (
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}
      ></div>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
        ref={containerRef}
      ></div>
    );
  }, []);

  const chartSettingsModal = useMemo(() => {
    return (
      <TsModal open={showChartSettings} onClose={() => setShowChartSettings(false)} title='Chart Settings'>
        <ChartSettings onSaved={() => setShowChartSettings(false)} onClosed={() => setShowChartSettings(false)} showClose />
      </TsModal>
    );
  }, [showChartSettings]);

  const chartPositions = useMemo(() => {
    return <ChartPosition charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  const chartOrders = useMemo(() => {
    return <ChartOrders charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  const chartExecutions = useMemo(() => {
    return <ChartTradeExecutions charts={chartGroups} widget={tvWidget} />;
  }, [chartGroups, tvWidget]);

  // Mobile Chart
  if (isMobile) {
    return useMemo(() => {
      return (
        <Box display='flex' flexDirection='column' flex='1' height='100%'>
          {chartOrders}
          {chartPositions}
          {chartExecutions}
          {chartSettingsModal}
          {chart}
          {!showSlideOut && (
            <div className={styles.slideOutToggle} onClick={handleSlideOut}>
              +
            </div>
          )}
          {
            <TsModal sx={{ zIndex: 100 }} open={showSlideOut} onClose={() => setShowSlideOut(false)} childSx={{ padding: '2em' }}>
              <MobileWebChartTrade orderAmount={orderAmount} minimize={handleSlideOut} contract={mobileContract} onSetContract={handleSetContract} onSetOrderAmount={setOrderAmount} />
            </TsModal>
          }
          {!showSlideOut && (
            <Box display='flex' flexDirection='column' marginTop={0}>
              <Box
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'block',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: '0.25em 0'
                  }}
                >
                  {activePosition && activePosition.positionSize != 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div style={{ marginRight: 10 }}>
                        {activePosition.positionSize > 0 ? '+' + activePosition.positionSize : activePosition.positionSize} @ <ContractPriceSpan contract={mobileContract}>{activePosition.averagePrice}</ContractPriceSpan>
                      </div>
                      <div style={{ marginRight: 10 }}>
                        <span style={{ color: showPnl() < 0 ? '#bd3f3b' : '#43984d' }}>UP&L ${showPnl().toFixed(2)}</span>
                      </div>
                      <div style={{ marginRight: 10 }}>
                        <StyledButton color='neutral' disabled={showPnl() < 0} onClick={breakEven} sx={{ padding: '2px 4px', margin: '4px', fontSize: '.9em', minWidth: 20 }}>
                          B/E
                        </StyledButton>
                      </div>
                    </div>
                  )}
                  <Stack>
                    <Stack style={{ textAlign: 'center' }}>
                      <div>
                        <div>{mobileContract.productName}</div>
                        <div style={{ fontWeight: 300 }}> {mobileContract.description}</div>
                      </div>
                    </Stack>
                    {(!activePosition || activePosition.positionSize == 0) && (
                      <Stack>
                        <div>No Active Position</div>
                      </Stack>
                    )}
                  </Stack>
                </div>
              </Box>
              <QuantityButtons disabled={!tradeEnabled} currentSize={orderAmount} setSize={(size) => setOrderAmount(size)} />
              <div className={styles.quickButtonContainer}>
                <StyledButton disabled={!tradeEnabled} color='success' onClick={() => quickBuy()}>
                  BUY +{orderAmount}
                </StyledButton>
                <Box>
                  <div className={styles.marketOrderPriceContainer}>
                    <Stack style={{ alignItems: 'center', flexDirection: 'row' }}>
                      <div className={styles.marketOrderPriceContainerBB}>Bid: {mobileContract && <ContractPriceSpan contract={mobileContract}>{quoteData?.bestBid ?? 0}</ContractPriceSpan>}</div>

                      <div style={{ display: 'block', marginLeft: 5, marginRight: 5 }}>
                        <div className={styles.marketOrderPriceContainerLP} style={{ color: showFlash, ...flashStyle }}>
                          {mobileContract && <ContractPriceSpan contract={mobileContract}>{quoteData?.lastPrice ?? 0}</ContractPriceSpan>}
                        </div>
                        <div className={styles.marketOrderPriceContainerSpread}>{mobileContract && <ContractPriceSpan contract={mobileContract}>{(quoteData?.bestAsk ?? 0) - (quoteData?.bestBid ?? 0)}</ContractPriceSpan>}</div>
                      </div>

                      <div className={styles.marketOrderPriceContainerBA}>Ask: {mobileContract && <ContractPriceSpan contract={mobileContract}>{quoteData?.bestAsk ?? 0}</ContractPriceSpan>}</div>
                    </Stack>
                  </div>
                </Box>

                <StyledButton disabled={!tradeEnabled} color='error' onClick={() => quickSell()}>
                  SELL -{orderAmount}
                </StyledButton>
              </div>
            </Box>
          )}
        </Box>
      );
    }, [chart, chartSettingsModal, chartPositions, chartOrders, chartExecutions, mobileContract, quoteData?.lastPrice, quoteData?.bestAsk, orderAmount, showSlideOut, activePosition]);
  }

  // Desktop Chart
  return useMemo(() => {
    return (
      <>
        {chartOrders}
        {chartPositions}
        {chartExecutions}
        {chart}
        {chartSettingsModal}
        <TsModal open={showLinkHelpModal}>
          <Box sx={{ maxWidth: '65vw', maxHeight: '75vh', overflowY: 'auto' }}>
            <Heading size={HeadingSize.H2}>Chart Linking Guide</Heading>
            <p style={{ fontSize: '1.5em' }}>
              To use the new chart linking system, you must now use the indicator section to control your linked color. Once you click the button to link a chart, a new study will be added to the chart. You can edit the settings of the study to change the
              linked color of the specific chart.
            </p>
            <p style={{ fontSize: '1.5em' }}>Here is a short video showing you how to change the color link of a specific chart.</p>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              <video src='https://topstepx-data.s3.amazonaws.com/HgkQtws2C3.mp4' controls autoPlay muted style={{ maxWidth: '60vw', maxHeight: '45vh' }}></video>
            </Box>
            <Box sx={{ marginTop: '1em', display: 'flex', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              <StyledButton
                onClick={() => {
                  setShowLinkHelpModal(false);
                }}
              >
                Close
              </StyledButton>
            </Box>
          </Box>
        </TsModal>
      </>
    );
  }, [showLinkHelpModal, chart, chartSettingsModal, chartPositions, chartOrders, chartExecutions]);
};

export default React.memo(Chart);
