import { styled } from '@mui/material';

export const ErrorMessage = styled('div')(({ theme }) => ({
  color: theme.palette.red,
  fontWeight: 'bold',
  fontSize: '1.2em',
  margin: '0.5em',
  marginBottom: '0',
  textAlign: 'center',
  padding: '0.5em',
  backgroundColor: theme.palette.lightBg,
  borderRadius: '0.25em',
  border: `1px solid ${theme.palette.red}`,
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 999
}));
