import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { Tooltip } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { TradingAccountType, TradingRuleType } from '@/api/userApi';
import { useDeviceContext } from '@/contexts/DeviceContext';
import Decimal from 'decimal.js';
import { useOrders } from '@/contexts/OrdersContext';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const MaxLossInfo: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { activeTradingAccount, getAccountTemplateData, getBalanceSource } = useTradingAccount();
  const { highestUnrealizedBalance, unrealizedPnl } = useOrders();
  const { customSettings } = useSettings();

  const targetMllBalance = useMemo(() => {
    const templateId = activeTradingAccount.templateId;
    const minimumMll = activeTradingAccount.minimumMll;       // The lowest value a maximum loss can be
    // for Template accounts
    if (templateId) {
      const templateInfo = getAccountTemplateData(templateId);
      const maximumLoss = activeTradingAccount.maximumLoss  !== null ? new Decimal(activeTradingAccount.maximumLoss) : null;;     // The highest value a maximum Loss can trail up to. Currently, only for template accounts.
      
      let mllValue: Decimal;

      if (templateInfo?.rules && templateInfo.template) {
        const ruleInfo = templateInfo.rules.find((rule) => rule.ruleId == TradingRuleType.MaximumLossLimit);

        if (ruleInfo) {
          const config = ruleInfo.configuration.maximumLossLimit;
          if (!config) return null;

          if (config.limit) {  
            const maxValue = getBalanceSource(activeTradingAccount, config.maximumSource);
            let currentValue = getBalanceSource(activeTradingAccount, config.source);

            const currentMll = new Decimal(currentValue).minus(config.limit);
            if (!!maxValue) {
                mllValue = Decimal.min(maxValue, currentMll);
            } else {
                mllValue = currentMll;
            }
          }

          if (config.percentage) {
            const maxValue = getBalanceSource(activeTradingAccount, config.maximumSource);
            let currentValue = getBalanceSource(activeTradingAccount, config.source);
            const currentMll = new Decimal(currentValue).minus(new Decimal(currentValue).mul(config.percentage));
            if (!!maxValue) {
                mllValue = Decimal.min(maxValue, currentMll);
            } else {
                mllValue = currentMll;
            }
          }

        if (maximumLoss) {
          return Decimal.min(maximumLoss, mllValue);
        }

        return mllValue;

        } else {
          return new Decimal(0);
        }
      } else {
        return new Decimal(0);
      }
    }

    // For Combine accounts
    return new Decimal(Math.min(activeTradingAccount.highestBalance - activeTradingAccount.drawDownLimit, activeTradingAccount.startingBalance));
  }, [activeTradingAccount, getAccountTemplateData, highestUnrealizedBalance, unrealizedPnl]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  const mll = useMemo(() => {
    if (activeTradingAccount.minimumMll || activeTradingAccount.minimumMll === 0) {
      const min = new Decimal(activeTradingAccount.minimumMll);
      if (!targetMllBalance) return min;
      return Decimal.max(min, targetMllBalance);
    }
    return targetMllBalance;
  }, [activeTradingAccount.minimumMll, targetMllBalance]);

  return useMemo(
    () =>
      activeTradingAccount?.type !== TradingAccountType.Live && (
        <div className={isMobile ? styles.balanceMobile : styles.balance} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
          <Tooltip title='Maximum Loss Limit: Do not let your account balance drop below this value.'>
            <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
              <span>MLL: </span>
              {customSettings.streamerModeMLL ? '******' : <span className={classNames(styles.balanceAmount)}>${formatPrice(mll.toNumber())}</span>}
            </div>
          </Tooltip>
        </div>
      ),
    [mll, customSettings.streamerModeMLL, customSettings.topNavTextSize, isMobile]
  );
};

export default React.memo(MaxLossInfo);
