import {
  CustomIndicator,
  RawStudyMetaInfoId,
  PineJS,
  LibraryPineStudy,
  IPineStudyResult,
  StudyPlotType
} from '@/charting_library';

export interface HoagsLevels {
  LWH: number;
  YH: number;
  YVAH: number;
  VPOC: number;
  S: number;
  YVAL: number;
  YL: number;
  WKOL: number;
  WKOH: number;
  LWL: number;
}

const HoagsIndicator: (pinejs: PineJS, interval: number, getHoagsLevels: (symbol: string) => HoagsLevels) => CustomIndicator = (pinejs, interval, getHoagsLevels) => {
  return {
    name: 'Topstep Daily Levels',
    metainfo: {
      _metainfoVersion: 51,
      id: 'hoags_indicator@tv-basicstudies-1' as RawStudyMetaInfoId,
      name: 'Topstep Daily Levels',
      description: 'Topstep Daily Levels',
      shortDescription: 'Topstep Levels',

      isCustomIndicator: true,
      priceScale: 2,
      is_price_study: true, // whether the study should appear on the main series pane.
      linkedToSeries: true,

      format: {
        type: 'price',
        precision: 2
      },

      plots: [
        { id: 'LWH', type: 'line' as StudyPlotType.Line },
        { id: 'YH', type: 'line' as StudyPlotType.Line },
        { id: 'YVAH', type: 'line' as StudyPlotType.Line },
        { id: 'VPOC', type: 'line' as StudyPlotType.Line },
        { id: 'S', type: 'line' as StudyPlotType.Line },
        { id: 'YVAL', type: 'line' as StudyPlotType.Line },
        { id: 'YL', type: 'line' as StudyPlotType.Line },
        { id: 'WKOL', type: 'line' as StudyPlotType.Line },
        { id: 'WKOH', type: 'line' as StudyPlotType.Line },
        { id: 'LWL', type: 'line' as StudyPlotType.Line },
      ],

      styles: {
        LWH: { title: 'Last Weeks High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'red' },
        YH: { title: 'Yesterday\'s High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'orange' },
        YVAH: { title: 'Yesterday\'s Value Area High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'yellow' },
        VPOC: { title: 'Yesterday\'s Volume Point of Control', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'green' },
        S: { title: 'Settlement', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'cyan' },
        YVAL: { title: 'Yesterday\'s Value Area Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'blue' },
        YL: { title: 'Yesterday\'s Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'indigo' },
        WKOL: { title: 'Weekly Kickoff Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'violet' },
        WKOH: { title: 'Weekly Kickoff High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'pink' },
        LWL: { title: 'Last Weeks Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'white' },
      },

      defaults: {
        styles: {
          LWH: { title: 'Last Weeks High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'red' },
          YH: { title: 'Yesterday\'s High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'orange' },
          YVAH: { title: 'Yesterday\'s Value Area High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'yellow' },
          VPOC: { title: 'Yesterday\'s Volume Point of Control', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'green' },
          S: { title: 'Settlement', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'cyan' },
          YVAL: { title: 'Yesterday\'s Value Area Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'blue' },
          YL: { title: 'Yesterday\'s Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'indigo' },
          WKOL: { title: 'Weekly Kickoff Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'violet' },
          WKOH: { title: 'Weekly Kickoff High', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'pink' },
          LWL: { title: 'Last Weeks Low', linestyle: 0, linewidth: 1, plottype: 2, trackPrice: true, transparency: 0, visible: true, color: 'white' },
        },
        inputs: {}
      },
      inputs: []
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        this._interval = interval;
        this._getHoagsLevels = getHoagsLevels;
        this._levels = this._getHoagsLevels(context.symbol.ticker);

        // console.log('symbol?', this._context.symbol.ticker);
        // console.log('levels?', this._levels);

        // Schedule the update
        setInterval(() => {
          if (this._context?.symbol?.ticker) {
            this._levels = this._getHoagsLevels(this._context.symbol.ticker);
          }
          // this.update();
        }, this._interval);
      };

      this.main = function (context, inputCallback) {
        return [
          this._levels.LWH,
          this._levels.YH,
          this._levels.YVAH,
          this._levels.VPOC,
          this._levels.S,
          this._levels.YVAL,
          this._levels.YL,
          this._levels.WKOL,
          this._levels.WKOH,
          this._levels.LWL
        ];
      };
    }
  };
};

export default HoagsIndicator;
