import React, { useCallback, useMemo, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import LayoutSelector from '../components/topstep/layoutSelector';
import AccountSelector from '../components/topstep/accountSelector';
import Balance from '../components/topstep/balance';
import StrapiAlert from '@/components/topstep/strapiAlert';
import UnrealizedPnl from '../components/topstep/unrealizedPnl';
import RealizedPnl from '../components/topstep/realizedPnl';
import MaxLoss from '../components/topstep/maxLoss';
import DailyLoss from '../components/topstep/dailyLossTargetBar';
import CopyTradingIndicator from '../components/topstep/copyTradingIndicator';
import Button, { ButtonType } from '../components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowRestore, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useThemeSelector } from '../contexts/ThemeContext';
import { Box, Dialog, Tooltip } from '@mui/material';
import { useTradingAccount } from '../contexts/TradingAccountContext';

import { Row } from './LayoutComponents';
import styles from './UserLayout.module.scss';
import Ping from '@/components/topstep/ping';
import { useApi } from '@/contexts/ApiContext';
import { PersonalDailyAction, TradingAccountStatus, TradingAccountType, ITradingAccountModel, ViolationType } from '@/api/userApi';
import DailyProfitTargetBar from '@/components/topstep/dailyProfitTargetBar';
import { StyledButton } from '@/components/styledComponents';
import CountdownTimer from '@/components/topstep/countdown';
import LockoutCountdown from '@/components/topstep/lockoutExpirationCountdown';
import PersonalLockoutModal from '@/components/topstep/personalLockoutModal';

interface Props {
  showLayoutSelector?: boolean;
}

export const Navbar: React.FC<Props> = ({ showLayoutSelector }): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const { activeTradingAccount } = useTradingAccount();
  const { tradingAccountApi } = useApi();

  const showNewLayoutModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const canStopTrade = useMemo(() => {
    return !activeTradingAccount?.isFollower && (!activeTradingAccount?.lockoutExpiration) && (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible) && activeTradingAccount.type != TradingAccountType.Practice;
  }, [activeTradingAccount?.isFollower, activeTradingAccount?.status, activeTradingAccount?.lockoutExpiration, activeTradingAccount.type]);

  const getAccountName = (account: ITradingAccountModel): string => {
    if (account){
      if (account.nickname) {
        return account.nickname + ' (' + account.accountName + ')';
      } else {
        return account.accountName;
      }
    } else {
      return 'Unknown Account';
    }
  };

  const getCountdown = () => {

    if (activeTradingAccount?.activeViolation && activeTradingAccount?.activeViolation?.expiresAt) {
      const expires = activeTradingAccount?.activeViolation?.expiresAt;
      switch (activeTradingAccount?.activeViolation?.type) {
        case ViolationType.PersonalStop:
        case ViolationType.PersonalDailyLossLimit:
        case ViolationType.PersonalDailyProfitTarget:
          return <LockoutCountdown name='Lockout' expires={expires} />;
        case ViolationType.DailyLossLimit:
        case ViolationType.MaximumLossLimit:
          return <LockoutCountdown name='Violation' expires={expires} />;
      }
    }

    if (activeTradingAccount?.lockoutExpiration) {
      return <LockoutCountdown name='Lockout' expires={activeTradingAccount?.lockoutExpiration} />;
    }

    return <></>;
  };

  return (
    <>
      <StrapiAlert />

      <Row width='100%' bgcolor='darkBg' justifyContent='space-between' zIndex={99999} padding='5px 0' margin={0}>
        <Row justifyContent='left' gap='.5em' data-intercom-target='navbar'>
          <AccountSelector style={{ maxWidth: '18em' }} />
          <CopyTradingIndicator />
          <Balance />
          <MaxLoss />
          <RealizedPnl />
          <UnrealizedPnl />
          <DailyLoss />
          {activeTradingAccount?.personalDailyProfitTargetAction > PersonalDailyAction.None && <DailyProfitTargetBar />}
          {canStopTrade && (
            <div data-intercom-target='manual-lock-out' className={styles.stopSignBg} onClick={showNewLayoutModal}>
              <Tooltip title='Manual Lock-Out' style={{ color: 'white' }}>
                <FontAwesomeIcon icon={faOctagonExclamation} />
              </Tooltip>
            </div>
          )}
          {getCountdown()}
        </Row>
        <Row justifyContent='right'>
          {/* <IconButton sx={{ ml: 1 }} onClick={toggleSelectedTheme} color='inherit'>
          {selectedTheme === ThemeNames.dark ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}
          <Ping />
          {showLayoutSelector && <LayoutSelector />}
        </Row>
        {showModal && (
          <Dialog onClose={() => setShowModal(false)} open={showModal}>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
              <div className={styles.modalHeader}>
                Confirm Trading <strong>Lock-Out</strong>?
              </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em', paddingTop: 0 }}>
              <div className={styles.modalBody}>
                By clicking yes below, you will be <strong>Locked Out</strong> from trading on this account ({getAccountName(activeTradingAccount)}) for the selected duration. <br /> <br />
                Once locked out, any open positions or working orders will be automatically flattened/canceled, and any copy-traded follower positions will be exited. You will not be able to trade on this account for the selected lockout period.
                <br />
                <br />
                <span style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Warning: </span> 
                <span  style={{ fontSize: '1.15em' }}>This action is irreversible.</span>
                <br />
                <span style={{ display: 'block', textAlign: 'center', fontSize: '1.2em', margin: '10px 0' }}>
                  Select from the following options:
                </span>             
            </div>
            </Box>
            <PersonalLockoutModal 
              mini 
              onConfirm={() => {
                setShowModal(false);
              }}
              onCancel={() => {
                setShowModal(false)
              }}
            />
          </Dialog>
        )}
      </Row>
    </>
  );
};
