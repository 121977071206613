import AddBox from '@mui/icons-material/AddBox';
import DockLayout, { BoxBase, DragDropDiv, DragState, LayoutBase, LayoutData, PanelBase, PanelData, TabBase, TabData } from 'rc-dock';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLayouts } from '../../contexts/LayoutsContext';
import Accounts from './components/accounts/accounts';
import Chart from './components/charts/chart';
import Dom from './components/dom/dom';
import Ordercard from './components/ordercard/ordercard';
import Orders from './components/orders/orders';
import Positions from './components/positions/positions';
import Quotes from './components/quoteBoard/quotes';
import TimeSales from './components/timesales/sales';
import Trades from './components/trades/trades';
import MarketStatusNotifier from './components/marketStatus';
import Tilt from './components/tilt/tilt';
import { Box } from '@mui/material';
import { IconDefinition, faHandshake, faMoneyCheckDollar, faRankingStar } from '@fortawesome/pro-regular-svg-icons';
import { faChartCandlestick, faDollar, faLineColumns, faListTimeline, faMoneyBillTrendUp, faScaleUnbalancedFlip, faTv, faSquare, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '@components/Loading';
import { Tooltip } from '@mui/material';
import ViolationNotifications from '@/views/trader/components/notifications/ViolationNotifications';
import { TradingPlatforms as TradingPlatform, config } from '@/config';
import IntercomTour from '@/views/trader/components/intercomTour';
import LinkOrdersModal from '@/views/trader/components/linkOrders/linkOrdersModal';
import dayjs from 'dayjs';
import axios from 'axios';
import { useApi } from '@/contexts/ApiContext';
import TabTitle from '@/components/topstep/tabTitle';

enum TabIds {
  ChartTab = 'chartTab',
  DomTab = 'domTab',
  OrderCard = 'orderCardTab',
  TimeAndSales = 'timeAndSalesTab',
  Accounts = 'accountsTab',
  Terminal = 'terminalTab',
  Position = 'positionTab',
  Orders = 'ordersTab',
  Quotes = 'quotesTab',
  TopStepTV = 'topStepTVTab',
  Blank = 'blankTab',
  Trades = 'trades',
  AddComponents = 'addComponents',
  Notification = 'notification'
}
enum TabGroups {
  Normal = 'normal',
  Settings = 'settings',
  Chart = 'chart',
  Dom = 'dom'
}

interface CustomTab extends TabBase {
  type: TabIds;
  props: any;
}

interface CustomTabData extends TabData {
  type: TabIds;
  props?: any;
  platform?: TradingPlatform;
}

type PageComponent = {
  type: TabIds;
  title: string;
  icon: IconDefinition;
  platform?: TradingPlatform;
};

const components: PageComponent[] = [
  {
    type: TabIds.ChartTab,
    title: 'Chart',
    icon: faChartCandlestick
  },
  {
    type: TabIds.DomTab,
    title: 'DOM',
    icon: faLineColumns
  },
  {
    type: TabIds.OrderCard,
    title: 'Order',
    icon: faDollar
  },
  {
    type: TabIds.Accounts,
    title: 'Accounts',
    icon: faUsers
  },
  {
    type: TabIds.Position,
    title: 'Positions',
    icon: faMoneyBillTrendUp
  },
  {
    type: TabIds.Trades,
    title: 'Trades',
    icon: faHandshake
  },
  {
    type: TabIds.Orders,
    title: 'Orders',
    icon: faListTimeline
  },
  {
    type: TabIds.TimeAndSales,
    title: 'Time and Sales',
    icon: faScaleUnbalancedFlip
  },
  {
    type: TabIds.Quotes,
    title: 'Quotes',
    icon: faMoneyCheckDollar
  },
  {
    type: TabIds.Terminal,
    title: 'The Tilt™',
    icon: faRankingStar,
    platform: TradingPlatform.TopstepX
  },
  {
    type: TabIds.TopStepTV,
    title: 'TopstepTV',
    icon: faTv,
    platform: TradingPlatform.TopstepX
  },
  {
    type: TabIds.Blank,
    title: 'Spacer',
    icon: faSquare
  }
];

type AddComponentTabProps = {
  dockLayout: MutableRefObject<DockLayout>;
  createTab: (id: string, type: TabIds) => any;
  getTabByType: (type: TabIds) => any;
};

type PageComponentItemProps = {
  component: PageComponent;
  onDragStart: (type: TabIds, e: DragState, buttonRef: React.MutableRefObject<DragDropDiv>) => void;
};

const PageComponentItem: React.FC<PageComponentItemProps> = ({ component, onDragStart }): JSX.Element => {
  const buttonRef = useRef<DragDropDiv>();

  return (
    <DragDropDiv key={component.type} ref={buttonRef} onDragStartT={(e) => onDragStart(component.type, e, buttonRef)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center',
          alignItems: 'center',
          alignContent: 'center',
          cursor: 'pointer'
        }}
      >
        <FontAwesomeIcon style={{ fontSize: '2em', margin: '1em' }} icon={component.icon}></FontAwesomeIcon>
        <span>{component.title}</span>
      </Box>
    </DragDropDiv>
  );
};
const AddComponentsTab: React.FC<AddComponentTabProps> = ({ dockLayout, createTab, getTabByType }): JSX.Element => {
  const { activeLayout } = useLayouts();
  const hasChart = getTabByType(TabIds.ChartTab);

  const onDragStart = useCallback(
    (type: TabIds, e: DragState, buttonRef: React.MutableRefObject<DragDropDiv>) => {
      if (type === TabIds.ChartTab) {
        if (getTabByType(TabIds.ChartTab)) {
          return;
        }
      }
      e.setData(
        {
          tab: createTab(`${type}-${Date.now()}`, type),
          panelSize: [400, 300]
        },
        dockLayout.current.getDockId()
      );
      e.startDrag(buttonRef.current.element, buttonRef.current.element);
    },
    [hasChart, activeLayout]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
      }}
    >
      {components
        .filter((y) => y.type !== TabIds.ChartTab || !hasChart)
        .filter((y) => {
          if (config.platform !== TradingPlatform.TopstepX) {
            // Exclude Terminal and TopStepTV only if platform is not TopstepX
            return ![TabIds.Terminal, TabIds.TopStepTV].includes(y.type);
          }
          return true;
        })
        .map((x) => (
          <PageComponentItem key={x.title} component={x} onDragStart={onDragStart} />
        ))}
    </Box>
  );
};

const TraderView: React.FC = (): JSX.Element => {
  const { activeLayout, setActiveLayout, setSaveCurrentLayoutCallback, autoSaveLayout } = useLayouts();
  const dockRef = useRef<DockLayout>();
  const [showLinkOrdersModal, setShowLinkOrdersModal] = useState(false);
  const youtubeUrl = useRef('');
  const { strapiApi } = useApi();
  const centerRef = useRef<() => void>(null);

  useEffect(() => {
    if (config.platform == TradingPlatform.TopstepX) {
      const fetchData = () => {
        strapiApi
          .youtubeUrl()
          .then((res) => {
            youtubeUrl.current = res;
          })
          .catch((err) => {
            console.log('Cant retrieve youtube url', err);
          });
      };

      // Fetch data initially
      fetchData();

      // Fetch data every 60 seconds
      const interval = setInterval(fetchData, 60000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, []);

  const onChanged = useCallback(() => {
    if (!dockRef.current) return;
    autoSaveLayout(dockRef.current.saveLayout());
  }, []);

  const disableHotkeys = useCallback((children: any[] | null = null) => {
    if (!dockRef.current) return;
    
    let ch: (BoxBase & PanelBase)[];

    if (!children) {
      ch = dockRef.current.getLayout().dockbox.children as (BoxBase & PanelBase)[];
    } else {
      ch = children as (BoxBase & PanelBase)[];
    }

    for (const child of ch) {
      if (child.tabs) {
        for (const tab of child.tabs) {
          const t = tab as any;
          // console.log(t);

          if (Object.hasOwn(t, 'props')) {
            if (Object.hasOwn(t.props, 'hotkeysEnabled')) {
              if (t.props.hotkeysEnabled) {
                t.props.hotkeysEnabled = false;
              }
            }

            if (Object.hasOwn(t.props, 'callbacks')) {
              if (Object.hasOwn(t.props.callbacks, 'onHotkeyEnabledChange')) {
                t.props.callbacks.onHotkeyEnabledChange(false);
              }

              if (Object.hasOwn(t.props.callbacks, 'refreshTabTitle')) {
                t.props.callbacks.refreshTabTitle();
              }
            }
          }
        }
      }
      if (child.children) {
        disableHotkeys(child.children);
      }
    }
  }, []);

  const findTabByType = useCallback((type: TabIds, children: any[]) => {
    const ch = children as (BoxBase & PanelBase)[];
    for (const child of ch) {
      if (child.tabs) {
        for (const tab of child.tabs) {
          const t = tab as any;
          if (t.type === type) return tab;
        }
      }
      if (child.children) {
        const res = findTabByType(type, child.children);
        if (res) return res;
      }
    }

    return null;
  }, []);

  const getTabByType = useCallback((type: TabIds) => {
    const dockboxTab = findTabByType(type, dockRef.current.getLayout().dockbox.children);
    if (dockboxTab) return dockboxTab;
    const floatTab = findTabByType(type, dockRef.current.getLayout().floatbox.children);
    if (floatTab) return floatTab;

    return null;
  }, []);

  const createTab = useCallback(
    (id: string, type: TabIds, props?: any, layoutName?: string): CustomTabData => {
      props = props || {};
      switch (type) {
        case TabIds.AddComponents:
          const retn: CustomTabData = {
            type: TabIds.AddComponents,
            id: id,
            title: 'Add Components',
            content: <AddComponentsTab dockLayout={dockRef} createTab={createTab} getTabByType={getTabByType} />,
            cached: true,
            props: props,
            group: TabGroups.Settings,
            closable: true
          };
          return retn;
        case TabIds.ChartTab:
          return {
            type: TabIds.ChartTab,
            id: id,
            title: 'Chart',
            content: (data: any) => {
              return <Chart tabData={data.props} layoutName={layoutName} />;
            },
            cached: false,
            props: props,
            group: TabGroups.Normal,
            closable: true
          };
        case TabIds.DomTab:
          return {
            type: TabIds.DomTab,
            id: id,
            minWidth: 250,
            title: <TabTitle hotkeysSupported={true} title='DOM' tabData={props} changed={onChanged} onHotkeysEnabled={disableHotkeys} />,
            content: (data: any) => {
              return <Dom tabData={data.props} changed={onChanged} showLinkedOrders={() => setShowLinkOrdersModal(true)} />;
            },
            cached: true,
            closable: true,
            props: props,
            group: TabGroups.Dom
          };
        case TabIds.OrderCard: {
          return {
            type: TabIds.OrderCard,
            id: id,
            minWidth: 150,
            title: <TabTitle hotkeysSupported={true} title='Order' tabData={props} changed={onChanged} onHotkeysEnabled={disableHotkeys} />,
            content: (data: any) => <Ordercard tabData={data.props} changed={onChanged} />,
            cached: false,
            closable: true,
            props: props,
            group: TabGroups.Normal
          };
        }
        case TabIds.TimeAndSales:
          return {
            type: TabIds.TimeAndSales,
            id: id,
            title: <TabTitle title='Time and Sales' tabData={props} changed={onChanged} />,
            content: (data: any) => {
              return <TimeSales tabData={data.props} changed={onChanged} />;
            },
            cached: false,
            closable: true,
            props: props,
            group: TabGroups.Normal
          };
        case TabIds.Terminal:
          if (config.platform == TradingPlatform.TopstepX) {
            return {
              type: TabIds.Terminal,
              id: id,
              title: <span data-intercom-target='tilt'>The Tilt™</span>,
              content: <Tilt />,
              cached: true,
              closable: true,
              group: TabGroups.Normal,
              platform: TradingPlatform.TopstepX
            };
          } else {
            return null;
          }
        case TabIds.Accounts:
          return {
            type: TabIds.Accounts,
            id: id,
            title: 'Accounts',
            content: (data: any) => <Accounts tabData={data.props} changed={onChanged} />,
            props: props,
            cached: false,
            closable: true,
            group: TabGroups.Normal
          };
        case TabIds.Position:
          return {
            type: TabIds.Position,
            id: id,
            title: 'Positions',
            content: (data: any) => <Positions tabData={data.props} changed={onChanged} />,
            props: props,
            cached: false,
            closable: true,
            group: TabGroups.Normal
          };
        case TabIds.Trades:
          return {
            type: TabIds.Trades,
            id: id,
            title: 'Trades',
            content: (data: any) => <Trades tabData={data.props} changed={onChanged} />,
            props: props,
            cached: false,
            closable: true,
            group: TabGroups.Normal
          };
        case TabIds.Quotes:
          return {
            type: TabIds.Quotes,
            id: id,
            title: 'Quotes',
            content: (data: any) => <Quotes tabData={data.props} changed={onChanged} />,
            props: props,
            cached: false,
            closable: true,
            group: TabGroups.Normal
          };
        case TabIds.Orders:
          return {
            type: TabIds.Orders,
            id: id,
            title: 'Orders',
            content: (data: any) => <Orders tabData={data.props} changed={onChanged} />,
            cached: false,
            closable: true,
            props: props,
            group: TabGroups.Normal
          };

        case TabIds.TopStepTV:
          if (config.platform == TradingPlatform.TopstepX) {
            return {
              type: TabIds.TopStepTV,
              id: id,
              title: 'TopstepTV',
              content: (
                <div style={{ height: '100%', width: '100%' }}>
                  <iframe
                    width='100%'
                    height='100%'
                    src={`https://www.youtube.com/embed/${youtubeUrl.current}`}
                    title='TopStep TV'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                    frameBorder={0}
                  ></iframe>
                </div>
              ),
              cached: true,
              closable: true,
              group: TabGroups.Normal,
              platform: TradingPlatform.TopstepX
            };
          } else {
            return null;
          }
        case TabIds.Blank:
          return {
            type: TabIds.Blank,
            id: id,
            title: 'Spacer',
            content: <div style={{ height: '100%', width: '100%' }}></div>,
            cached: true,
            closable: true,
            group: TabGroups.Normal
          };
        default:
          return {
            type,
            id,
            title: 'Unknown',
            content: <></>,
            cached: true,
            group: TabGroups.Normal
          };
      }
    },
    [getTabByType]
  );

  const addBox = useCallback((panelData: any, context: DockLayout) => {
    return (
      <Tooltip data-intercom-target='add-component' title='Add Components'>
        <AddBox
          style={{
            color: '#cfd4cd',
            cursor: 'pointer',
            fontSize: '1.5em'
          }}
          onClick={() => {
            const existing = getTabByType(TabIds.AddComponents);
            if (existing) {
              context.dockMove(existing, null, 'float');
              return;
            }

            context.dockMove(createTab(`${TabIds.AddComponents}-${dayjs().valueOf()}`, TabIds.AddComponents), panelData, 'float');
          }}
        />
      </Tooltip>
    );
  }, []);

  const groups = useMemo(() => {
    return {
      [TabGroups.Dom]: {
        closable: true,
        floatable: true,
        panelExtra: (panelData, context: DockLayout) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>{addBox(panelData, context)}</div>
              <div className='dock-extra-content' style={{ display: 'flex', alignItems: 'center', marginRight: 6 }}>
                <span
                  className={panelData.parent.mode === 'maximize' ? 'dock-panel-min-btn' : 'dock-panel-max-btn'}
                  key='maximize'
                  title={panelData.parent.mode === 'maximize' ? 'Restore' : 'Maximize'}
                  onClick={() => context.dockMove(panelData, null, 'maximize')}
                ></span>
              </div>
            </>
          );
        }
      },
      [TabGroups.Normal]: {
        closable: true,
        floatable: true,
        panelExtra: (panelData: PanelData, context: DockLayout) => {
          const activePanelId = panelData.activeId;
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>{addBox(panelData, context)}</div>
              <div className='dock-extra-content' style={{ display: 'flex', alignItems: 'center', marginRight: 6 }}>
                <span
                  className={panelData.parent.mode === 'maximize' ? 'dock-panel-min-btn' : 'dock-panel-max-btn'}
                  key='maximize'
                  title={panelData.parent.mode === 'maximize' ? 'Restore' : 'Maximize'}
                  onClick={() => context.dockMove(panelData, null, 'maximize')}
                ></span>
              </div>
            </>
          );
        }
      }
    };
  }, []);

  const loadTab = useCallback(
    (savedTab: CustomTab) => {
      if (!savedTab) {
        return createTab(TabIds.Blank, TabIds.Blank, {}, activeLayout?.name);
      }
      let toFind = savedTab.type || savedTab.id;
      if (!savedTab.type && toFind.indexOf('-') > -1) {
        toFind = toFind.split('-')[0];
      }

      return createTab(savedTab.id, toFind as TabIds, savedTab.props, activeLayout?.name);
    },
    [activeLayout?.name]
  );

  const onLayoutChange = useCallback(
    (layout: LayoutBase) => {
      setActiveLayout({ ...activeLayout, layout }, true);
    },
    [activeLayout]
  );

  const saveTab = useCallback((tab: TabData): TabBase => {
    const t = tab as any;
    const props = { ...t.props };
    if (props.callbacks) {
      delete props.callbacks;
    }
    return { id: tab.id, type: t.type, props: props } as TabBase;
  }, []);

  // useEffect(() => {
  //   if (!dockRef.current) return;
  //   const timeout = setTimeout(() => {
  //     const tab = dockRef.current.find(TabIds.Trades);
  //     const tab2 = dockRef.current.find(TabIds.Accounts);

  //     if (!tab && tab2) {
  //       dockRef.current.dockMove(createTab(TabIds.Trades, TabIds.Trades), tab2.parent, 'middle');
  //     }
  //   }, 1000);

  //   return () => clearTimeout(timeout);
  // }, [dockRef, activeLayout]);

  const baseLayout = useMemo(() => {
    const defLayout = {
      maxbox: { id: '+13', mode: 'maximize', size: 1, children: [] },
      dockbox: {
        id: '+19',
        mode: 'horizontal',
        size: 200,
        children: [
          {
            id: '+17',
            mode: 'vertical',
            size: 1111,
            children: [
              {
                id: '+14',
                mode: 'horizontal',
                size: 543,
                children: [
                  { id: '+3', size: 878, tabs: [{ id: 'chartTab', type: 'chartTab', props: { interval: '5' } }], group: 'normal', activeId: 'chartTab' },
                  {
                    id: '+5',
                    mode: 'vertical',
                    size: 285,
                    children: [
                      {
                        id: '+6',
                        size: 327,
                        tabs: [{ id: 'orderCardTab', type: 'orderCardTab', props: { symbol: 'F.US.EP', orderType: 2, linkedColor: 3, orderQuantity: 1 } }],
                        group: 'normal',
                        activeId: 'orderCardTab'
                      },
                      { id: '+18', size: 208, tabs: [{ id: 'timeAndSalesTab', type: 'timeAndSalesTab', props: { symbol: 'F.US.EP', linkedColor: 3 } }], group: 'normal', activeId: 'timeAndSalesTab' }
                    ]
                  }
                ]
              },
              {
                id: '+9',
                size: 81,
                tabs: [
                  { id: 'accountsTab', type: 'accountsTab', props: {} },
                  { id: 'positionTab', type: 'positionTab', props: {} },
                  { id: 'ordersTab', type: 'ordersTab', props: {} },
                  { id: 'trades', type: 'trades', props: {} },
                  { id: 'trades', type: 'trades', props: {} },
                  { id: 'quotesTab', type: 'quotesTab', props: {} },
                  { id: 'terminalTab', type: 'terminalTab', props: {} }
                ],
                group: 'normal',
                activeId: 'quotesTab'
              }
            ]
          },
          { id: '+20', size: 341, tabs: [{ id: 'domTab', type: 'domTab', props: { symbol: 'F.US.EP', autoCenter: false, linkedColor: 3, orderAmount: 1 } }], group: 'dom', activeId: 'domTab' }
        ]
      },
      floatbox: { id: '+11', mode: 'float', size: 1, children: [] },
      windowbox: { id: '+12', mode: 'window', size: 1, children: [] }
    };

    return defLayout as LayoutBase;
  }, []);

  useEffect(() => {
    if (!activeLayout || !activeLayout?.layout?.dockbox) {
      setActiveLayout({ name: 'default', layout: baseLayout, draft: false, id: 0 }, false);
    }
  }, [activeLayout]);

  useEffect(() => {
    if (dockRef.current && activeLayout?.name) {
      dockRef.current.loadLayout({ ...activeLayout.layout });
    }
  }, [activeLayout?.id, dockRef.current]);

  useEffect(() => {
    setSaveCurrentLayoutCallback(() => {
      console.log('Saving current layout');
      if (!dockRef.current) return;
      return dockRef.current.saveLayout();
    });
  }, []);

  const filteredLayout = useCallback(() => {
    const layout = dockRef.current.getLayout();
    getTabByType;
  }, [activeLayout?.layout]);
  if (!activeLayout || !activeLayout?.layout?.dockbox) return <Loading />;

  return (
    <>
      <DockLayout ref={dockRef} defaultLayout={{ ...(activeLayout?.layout as LayoutData) }} saveTab={saveTab} onLayoutChange={onLayoutChange} loadTab={loadTab} groups={groups} style={{ width: '100%', height: '100%' }} />
      <MarketStatusNotifier />
      <ViolationNotifications />
      <IntercomTour />
      <LinkOrdersModal open={showLinkOrdersModal} onClose={() => setShowLinkOrdersModal(false)} />
    </>
  );
};

export default TraderView;
