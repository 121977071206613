import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './modal.module.scss';
import Button, { ButtonProps, ButtonType } from './button';
import { useModal } from '../../contexts/ModalContext';
import Heading, { HeadingSize } from './heading';

export interface ModalAction {
  label: string;
  className?: string;
  props?: Partial<ButtonProps>;
  onClick: () => void;
}
type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
  header: React.ReactNode;
  actions: ModalAction[];
  zIndex?: number;
};

const Modal: React.FC<ModalProps> = ({
  children,
  header,
  actions,
  className,
  zIndex,
  ...props
}): JSX.Element => {
  const actionsUi = useMemo(() => {
    return actions.map((action, index) => {
      return (
        <Button
          key={index}
          onClick={action.onClick}
          className={classNames(styles.actionButton, action.className)}
          {...action.props}
        >
          {action.label}
        </Button>
      );
    });
  }, [actions]);
  return (
    <div style={{zIndex: zIndex}} className={classNames(styles.modal, className)} {...props}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>{actionsUi}</div>
    </div>
  );
};

type NotificationModalProps = {
  className?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
};

export const NotificationModal: React.FC<NotificationModalProps> = ({
  header,
  className,
  children,
  ...props
}): JSX.Element => {
  const { hideModal } = useModal();
  const actions = useMemo(() => {
    const actions: ModalAction[] = [
      {
        label: 'Dismiss',
        onClick: hideModal,
        props: { buttonType: ButtonType.outline, style: { padding: '0.5em 1em' } }
      }
    ];

    return actions;
  }, []);

  const showHeader = useMemo(() => {
    return (
      header || (
        <Heading className={styles.notificationHeader} size={HeadingSize.H2}>
          Notification
        </Heading>
      )
    );
  }, [header]);

  return (
    <Modal header={showHeader} className={className} {...props} actions={actions}>
      <div>{children}</div>
    </Modal>
  );
};

type ConfirmModalProps = {
  onConfirm: () => void;
  onCancel?: () => void;
  className?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onConfirm,
  onCancel,
  header,
  className,
  children,
  ...props
}): JSX.Element => {
  const actions = useMemo(() => {
    const actions: ModalAction[] = [
      { label: 'Cancel', onClick: onCancel },
      { label: 'Confirm', props: { buttonType: ButtonType.green }, onClick: onConfirm }
    ];

    return actions;
  }, [onCancel, onConfirm]);
  return (
    <Modal header={header} className={className} {...props} actions={actions}>
      {children}
    </Modal>
  );
};

export default React.memo(Modal);
