import DockLayout, { LayoutBase, LayoutData, TabBase, TabData } from 'rc-dock';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UserLayout from '../../layout/UserLayout';
import MarketNews from './components/marketNews/marketNews';
import PowerPlayers from './components/powerPlayers/powerPlayers';
import Tv from './components/tv/tv';
import Coaching from './components/coaching/coaching';
import Button from '../../components/topstep/button';
import styles from './resourcesView.module.scss';
import Panel from '../../components/topstep/panel';
import classNames from 'classnames';
import Reflect from './components/reflect/reflect';
import Time from './components/time/time';
import Social from './components/social/social';
import Podcast from './components/podcast/podcast';
import Trade from './components/trade/trade';
import { Column, Row } from '../../layout/LayoutComponents';
import { Timeline } from 'react-twitter-widgets';
import { Box } from '@mui/material';

const ResourcesView: React.FC = (): JSX.Element => {
  return (
    <div className={styles.page}>
      <div className={styles.col1b}>
        <Panel title='Market News' className={styles.fit}>
          <MarketNews />
        </Panel>
      </div>
      <div className={styles.col2}>
        <div className={classNames(styles.fit, styles.splitHorizontally)}>
          <Panel title='Economic Events' className={styles.fit}>
            <Trade />
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ResourcesView;
