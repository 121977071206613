import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';
import { Page } from '../../layout/LayoutComponents';
import { Box } from '@mui/material';
import axios from 'axios';
import { useApi } from '@/contexts/ApiContext';
import { Datum } from '@/api/dataApi';


const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    border: 'none',
    boxShadow: 'none',
}));

const QuickVideos: React.FC = ({ }): JSX.Element => {
    const [videos, setVideos] = React.useState<Datum[]>([]);
    const { strapiApi } = useApi();

    useEffect(() => {
        strapiApi.getVideos().then((res) => {
            setVideos(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    if (videos.length > 0) {
        return (
            <Page sx={{ maxWidth: '100%', overflow: 'auto', display: 'block' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ width: '98%', display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'start', background: '#2a2b2f', color: '#fff', padding: 20, marginTop: 15, marginRight: 20, borderRadius: 6, border: 'solid 1px #616161' }}>
                        <div><span style={{ fontWeight: 600, fontSize: '1.5em' }}>Watch 5 Min (or less) TopstepX Tutorials!</span></div>
                        <div><span style={{ fontSize: '1.3em', color: '#cdcdcd' }}>Watch our product expert and Performance Coach Robert Cooney go through the TopstepX platform in this series of 5 Minutes (or less!) walk-through tutorials.</span></div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: 100, marginTop: 20 }}>
                        {videos && videos.map((video, index) => {
                            return (
                                <Item key={index}>
                                    <NavLink to={video.attributes.youtubeLink} target="_blank">
                                        <img src={`https://strapi-cms.topstep.com${video.attributes.image.data.attributes.url}`} alt="video" style={{ maxWidth: 275, borderRadius: 10 }} />
                                    </NavLink>
                                </Item>
                            )
                        })}
                    </div>
                </Box>
            </Page>
        )
    }
    else {
        return (
            <div></div>
        )
    }
};

export default QuickVideos;
