import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultTsModal } from '@/components/modal';
import { Box, Button, Dialog, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useApi } from '@/contexts/ApiContext';
import { DeleteJournalLogRequest, GetJournalLogDayRequest, GetTradesForDayRequest, ITradeModel, JournalLog, JournalLogUpsertRequest, JournalUpsertResult, TradeModel, TradesInRangeRequest, TradeSummary } from '@/api/userApi';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import PerformanceTradesGrid from './performanceTradesGrid';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quilljs';
import ImageUploader from "quill-image-uploader";
import { set } from 'lodash';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import JournalEditor from './journalEditor';

dayjs.extend(utc);
dayjs.extend(timezone);

type DayPerformanceModalProps = {
  accountId: number;
  date: Dayjs;
  show: boolean;
  onHide: () => void;
  onJournalAdded: (journal: JournalLog) => void;
  onJournalRemoved: (journal: JournalLog) => void;
};

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const formatDollarPrice = (value?: number): string => {
  if (isNaN(value)) return '';
  return priceFormatter.format(value);
};

const getDollarTextFromValue = (value: number) => {
  const formatted = formatDollarPrice(value);
  if (value > 0) {
    return <span style={{ color: '#56d660' }}>{formatted}</span>;
  } else if (value < 0) {
    return <span style={{ color: '#d6565e' }}>{formatted}</span>;
  } else {
    return <span>{formatted}</span>;
  }
};

const profitLineChart = (data: any) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        title: {
          text: ''
        },
        xAxis: {
          title: {
            text: 'Time',
            type: 'datetime',
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          },
          labels: {
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            },
            formatter: function () {
              return dayjs(this.value).tz(dayjs.tz.guess()).format('hh:mm A');
            }
          }
        },
        yAxis: {
          gridLineColor: '#3d3c43',
          title: {
            text: 'Profit',
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          },
          labels: {
            style: {
              color: '#FFFFFF',
              fontSize: '1.1em'
            }
          }
        },
        tooltip: {
          backgroundColor: '#000000', // Set tooltip background color to black
          style: {
            color: '#FFFFFF' // Set text color to white
          },
          useHTML: true, // Enable HTML in the tooltip
          headerFormat: '<span style="color: #FFFFFF;">Day Cumulative Profit</span><br/>', // Custom title for the tooltip
          pointFormat: 'Time: {point.timeStr}<br>Profit: {point.text}'
        },
        series: [
          {
            type: 'area',
            data: data,
            zones: [
              {
                value: 0, // Anything below 0 (negative values)
                color: '#d6565e',
                fillColor: 'rgba(214, 86, 96, 0.2)' // Light red shading
              },
              {
                color: '#56d660', // Positive values
                fillColor: 'rgba(86, 214, 96, 0.2)' // Light green shading
              }
            ],
            zoneAxis: 'y'
          }
        ],
        chart: {
          backgroundColor: 'transparent',
          style: {
            width: '100%'
          },
          height: 350 // Set the max height here (e.g., 400 pixels)
        },
        legend: {
          enabled: false // Remove the series legend
        },
        credits: {
          enabled: false
        }
      }}
    />
  );
};

const DayPerformanceModal = (props: DayPerformanceModalProps) => {
  const { statisticsApi, journalLogApi, tradeApi, userDataUploadApi } = useApi();
  const [dayTradeData, setDayTradeData] = React.useState<TradeModel[]>([]);
  const { activeTradingAccount } = useTradingAccount();

  const [dayJournal, setDayJournal] = React.useState<JournalLog>(null);
  const [dayJournalText, setDayJournalText] = React.useState<string>('');
  const [tradeJournals, setTradeJournals] = React.useState<JournalLog[]>([]);
  const [showJournal, setShowJournal] = useState(!!dayJournal?.content);

  useEffect(() => {
    if (dayJournal) {
      setDayJournalText(dayJournal.content);
    }
  }, [dayJournal, showJournal]);

  const journalChanged = useMemo(() => {
    return dayJournalText !== dayJournal?.content ?? '';
  }, [dayJournalText, dayJournal]);

  useEffect(() => {
    tradeApi.getInRange(new TradesInRangeRequest({
      tradingAccountId: props.accountId,
      start: props.date,
      end: props.date
    })).then((response) => {
        setDayTradeData(response.sort((a, b) => a.createdAt.diff(b.createdAt)));
      })
      .catch((error) => {});

    journalLogApi
      .getForDay(
        new GetJournalLogDayRequest({
          tradingAccountId: props.accountId,
          tradeDay: props.date
        })
      )
      .then((response) => {
        // get the one journal log that does not have a trade id
        const journal = response.find((j) => j.tradeId === null);
        setDayJournal(journal);

        // make new list with everything but the journal log that does not have a trade id
        const tradeJournals = response.filter((j) => j.tradeId !== null);
        setTradeJournals(tradeJournals);
      });
  }, [props.show, props.date, props.accountId]);

  const handleSaveDayJournal = (data: string) => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .upsert(
          new JournalLogUpsertRequest({
            content: data,
            tradeDay: props.date,
            tradingAccountId: props.accountId,
            tradeId: null
          })
        )
        .then((res) => {

          if (!res.success) {
            reject();
            return;
          }

          resolve();

          const log = {
            content: data,
            tradeDay: props.date,
            tradingAccountId: props.accountId
          } as JournalLog;

          setDayJournal(log); // update the journal log
          props.onJournalAdded(log); // update the journal log in the parent component
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Saving journal...',
      success: 'Journal saved',
      error: 'Error saving journal'
    });
  };

  const handleDeleteDayJournal = () => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .delete(
          new DeleteJournalLogRequest({
            tradeDay: props.date,
            tradingAccountId: props.accountId,
            tradeId: null
          })
        )
        .then((res) => {
          if (!res) {
            reject();
            return;
          }

          props.onJournalRemoved(dayJournal);

          resolve();
          setDayJournal(null);
          setDayJournalText('');
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Deleting journal...',
      success: 'Journal deleted',
      error: 'Error deleting journal'
    });
  };

  const tradeCumulativeData = useMemo(() => {
    // Each data point is the sum of all previous data points plus the current data point
    return dayTradeData.map((stat, index) => {
      const previousData = dayTradeData.slice(0, index);
      const previousSum = previousData.reduce((acc, prev) => acc + prev.pnL - prev.fees, 0);
      return {
        x: stat.createdAt.toDate(),
        y: previousSum + stat.pnL - stat.fees,
        text: (previousSum + stat.pnL - stat.fees).toFixed(2),
        color: previousSum + stat.pnL > 0 ? '#56d660' : '#d6565e',
        name: getDollarTextFromValue(previousSum + stat.pnL - stat.fees),
        // format ie 12:53 PM
        timeStr: stat.createdAt.tz(dayjs.tz.guess()).format('hh:mm A')
      };
    });
  }, [dayTradeData]);

  const journalBox = useMemo(() => {
    if (props.accountId != activeTradingAccount?.accountId) {
      return null;
    }

    // Show even if there are no trades
    // if (!dayTradeData || dayTradeData.length == 0) {
    //  return null;
    //}

    console.log('DayJournal', dayJournal, showJournal);
    if (showJournal || !!dayJournal?.content) {
      console.log('Show Journal');
      return (
        <>
          <Typography textAlign={'center'} margin='0.5em 0' variant='h4' color='white'>
            Day Journal
          </Typography>
          <JournalEditor 
            initialValue={dayJournalText}
            onChange={(content) => setDayJournalText(content)}
          />
          <Button sx={{ marginLeft: '0.5em', marginTop: '0.5em', marginBottom: '2em' }} onClick={() => handleSaveDayJournal(dayJournalText)} variant='contained' color='primary'>
            Save Journal
          </Button>
          {dayJournal && (
            <Button variant='contained' sx={{ marginLeft: '1em', marginTop: '0.5em', marginBottom: '2em' }} color='warning' onClick={() => { handleDeleteDayJournal(); }}>
              Delete Journal
            </Button>
          )}
          {journalChanged && (
            <Button variant='contained' sx={{ marginLeft: '1em', marginTop: '0.5em', marginBottom: '2em' }} color='error' onClick={() => { setShowJournal(false); setDayJournalText(dayJournal?.content || ''); }}>
              Cancel
            </Button>
          )}
        </>
      );
    } else {
      console.log('Show Add Journal');
      return (
        <Box sx={{ width: '100%', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
          <Typography margin='0.5em 0' variant='h4' color='white'>
            Day Journal
          </Typography>
          <Typography margin='0.5em 0' variant='body1' color='white'>
            No journal for this day. Add a journal to keep track of your thoughts and trades for the day.
          </Typography>
          <Button sx={{ marginTop: '1em', marginBottom: '2em' }} onClick={() => setShowJournal(true)} variant='contained' color='primary'>
            Add Journal
          </Button>
        </Box>
      );
    }
  }, [props.accountId, activeTradingAccount, dayTradeData, dayJournal, tradeJournals, showJournal, dayJournalText, journalChanged, handleSaveDayJournal]);

  return (
    <Dialog open={props.show} onClose={props.onHide} maxWidth={false}>
      <Box padding='2em'>
        <Typography marginBottom='0.5em' variant='h5' color='white'>
          Day Performance: {props.date.format('MM/DD/YY')}
        </Typography>
        <Box sx={{ fontSize: '1.5em' }}>{profitLineChart(tradeCumulativeData)}</Box>
        {journalBox}
        <PerformanceTradesGrid onJournalRemoved={props.onJournalRemoved} onJournalAdded={props.onJournalAdded} height={300} accountId={props.accountId} trades={dayTradeData} startDate={props.date.startOf('day')} endDate={props.date.endOf('day')} />
      </Box>
    </Dialog>
  );
};

export default DayPerformanceModal;
