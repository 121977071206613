import { DeleteJournalLogRequest, GetDailyStatisticsRequest, GetJournalLogRangeRequest, GetJournalLogTradeRequest, ITradeModel, JournalLog, JournalLogUpsertRequest, TradeModel, TradeSummary, TradesInRangeRequest } from '@/api/userApi';
import { useApi } from '@/contexts/ApiContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DataGridPro, GridApiPro, GridColDef, GridEventListener, GridValueGetterParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { useSymbol } from '@/contexts/SymbolContext';
import styles from './performanceTradesGrid.module.scss';
import { ContractPriceSpan, CurrencySpan, DateSpan, DateSpanHumanFormat, NumberSpan } from '@/components/numbers';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { TsModal } from '@/components/modal';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { toast } from 'react-toastify';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import JournalEditor from './journalEditor';

type PerformanceTradesGridProps = {
  accountId?: number;
  trades?: ITradeModel[];
  height?: number | string;
  startDate?: Dayjs;
  endDate?: Dayjs;
  onJournalAdded?: (journal: JournalLog) => void;
  onJournalRemoved?: (journal: JournalLog) => void;
  lastJournalUpdate?: Dayjs;

  noHumanDates?: boolean;

  apiRef?: React.MutableRefObject<GridApiPro>;
  rowHeight?: number;
  columnHeaderHeight?: number;
  density?: 'compact' | 'standard' | 'comfortable';
  slots?: { [slot: string]: () => React.JSX.Element };
  initialState?: GridInitialStatePro;
  pageSizeOptions?: number[];
  onStateChange?: GridEventListener<'stateChange'>;
};

const PerformanceTradesGrid = (props: PerformanceTradesGridProps) => {
  const { isMobile } = useDeviceContext();
  const { getContractByProductId } = useSymbol();
  const { journalLogApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();

  const [allJournalData, setAllJournalData] = useState<JournalLog[]>([]);

  const [journalTrade, setJournalTrade] = useState<TradeModel | undefined>(undefined);
  const [journalData, setJournalData] = useState<JournalLog | undefined>(undefined);

  const onClickJournal = (trade: TradeModel) => {
    // get one match from allJournalData
    const res = allJournalData.find((j) => j.tradeId === trade.id);
    setJournalData(res);
    setJournalTrade(trade);
  };

  useEffect(() => {
    journalLogApi
      .getForRange(
        new GetJournalLogRangeRequest({
          startDate: props.startDate?.subtract(1, 'day'),
          endDate: props.endDate?.add(1, 'day'),
          tradingAccountId: props.accountId
        })
      )
      .then((res) => {
        setAllJournalData(res);
      });
  }, [props.startDate, props.endDate, props.accountId, props.lastJournalUpdate]);

  const columns: (journal: boolean) => GridColDef<TradeModel>[] = useCallback(
    (journal: boolean) => {
      const base = [
        {
          field: 'id',
          headerName: 'ID',
          sortable: true,
          filterable: true,
          flex: 1,
          headerClassName: styles.header,
          minWidth: isMobile ? 75 : undefined
        },
        {
          field: 'symbolName',
          headerName: 'Symbol',
          sortable: true,
          filterable: true,
          flex: 0.5,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            const contract = getContractByProductId(params.row.symbolId);
            if (!contract) return <span>{params.row.symbolId}</span>;
            return <span>{contract.productName}</span>;
          },
          valueGetter: (params: GridValueGetterParams<TradeModel>) => {
            const contract = getContractByProductId(params.row.symbolId);
            if (!contract) return params.row.symbolId;
            return contract.productName;
          },
          minWidth: isMobile ? 100 : 50
        },
        {
          field: 'positionSize',
          headerName: 'Size',
          sortable: true,
          type: 'number',
          flex: 0.5,
          headerClassName: isMobile ? styles.mobileHeader : styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return <NumberSpan>{Math.abs(params.row.positionSize)}</NumberSpan>;
          },
          valueGetter: (params: GridValueGetterParams<TradeModel>) => {
            return Math.abs(params.row.positionSize);
          },
          minWidth: isMobile ? 70 : undefined
        },
        {
          field: 'entryTime',
          headerName: 'Entry Time',
          sortable: true,
          filterable: true,
          type: 'number',
          flex: 2,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return props.noHumanDates ? <DateSpan>{params.row.createdAt}</DateSpan> : <DateSpanHumanFormat>{params.row.createdAt}</DateSpanHumanFormat>;
          },
          valueGetter: (params: GridValueGetterParams<TradeModel>) => {
            return params.row.createdAt.unix();
          },
          minWidth: 150,
          sortingOrder: ['desc', 'asc']
        },
        {
          field: 'exitedAt',
          headerName: 'Exit Time',
          sortable: true,
          filterable: true,
          type: 'number',
          flex: 2,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return props.noHumanDates ? <DateSpan>{params.row.exitedAt}</DateSpan> : <DateSpanHumanFormat>{params.row.exitedAt}</DateSpanHumanFormat>;
          },
          valueGetter: (params: GridValueGetterParams<TradeModel>) => {
            return params.row.exitedAt.unix();
          },
          minWidth: 150
        },
        {
          field: 'entryPrice',
          headerName: 'Entry Price',
          sortable: true,
          filterable: true,
          type: 'number',
          flex: 1,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            const contract = getContractByProductId(params.row.symbolId);
            if (!contract) return <span>{params.row.entryPrice}</span>;
            return <ContractPriceSpan contract={contract}>{params.row.entryPrice}</ContractPriceSpan>;
          },
          align: 'right',
          headerAlign: 'right',
          minWidth: isMobile ? 120 : undefined
        },
        {
          field: 'exitPrice',
          headerName: 'Exit Price',
          filterable: true,
          sortable: true,
          flex: 1,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            if (!params.row.exitPrice) return <span></span>;
            const contract = getContractByProductId(params.row.symbolId);
            if (!contract) return <span>{params.row.exitPrice}</span>;
            return <ContractPriceSpan contract={contract}>{params.row.exitPrice}</ContractPriceSpan>;
          },
          align: 'right',
          headerAlign: 'right',
          minWidth: isMobile ? 120 : undefined
        },
        {
          field: 'pnL',
          headerName: 'P&L',
          sortable: true,
          type: 'number',
          flex: 1,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return <CurrencySpan showColors>{params.row.pnL}</CurrencySpan>;
          },
          align: 'right',
          headerAlign: 'right',
          minWidth: isMobile ? 100 : undefined
        },
        {
          field: 'commisions',
          headerName: 'Commissions',
          sortable: true,
          type: 'string',
          flex: 1,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return <span>$0</span>;
          },
          align: 'right',
          headerAlign: 'right',
          minWidth: isMobile ? 100 : undefined
        },
        {
          field: 'fees',
          headerName: 'Fees',
          sortable: true,
          flex: 1,
          headerClassName: styles.header,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            return <CurrencySpan showColors>{-1 * params.row.fees}</CurrencySpan>;
          },
          align: 'right',
          headerAlign: 'right',
          minWidth: isMobile ? 100 : undefined
        },
        {
          field: 'direction',
          headerName: 'Direction',
          minWidth: isMobile ? 100 : 70,
          sortable: true,
          filterable: true,
          flex: 0.5,
          headerClassName: styles.header,
          valueGetter: (params: GridValueGetterParams<TradeModel>) => {
            if (params.row.exitedAt.unix() != 0) {
              return params.row.positionSize < 0 ? 'Long' : 'Short';
            }
          },
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            if (params.row.exitedAt.unix() != 0) {
              return params.row.positionSize < 0 ? 'Long' : 'Short';
            }
          }
        }
      ];

      if (journal && props.accountId === activeTradingAccount?.accountId) {
        base.unshift({
          field: 'journal',
          headerName: 'Journal',
          sortable: false,
          filterable: false,
          flex: 1,
          headerClassName: styles.header,
          minWidth: 50,
          maxWidth: 50,
          renderCell: (params: GridValueGetterParams<TradeModel>) => {
            let style: 'primary' | 'disabled' = 'disabled';

            // if we have a trade journal, change the style to contained
            const journal = allJournalData.find((j) => j.tradeId === params.row.id);
            if (journal) {
              style = 'primary';
            }

            return (
              <IconButton onClick={() => onClickJournal(params.row)} sx={{ padding: '3px' }} color='primary'>
                {journal ? <TextSnippetIcon color={style} /> : <NoteAddIcon color={style} />}
              </IconButton>
            );
          }
        } as any);
      }

      return base as GridColDef<TradeModel>[];
    },
    [getContractByProductId, isMobile, props.accountId, activeTradingAccount, allJournalData]
  );

  const handleSaveTradeJournal = (data: string) => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .upsert(
          new JournalLogUpsertRequest({
            content: data,
            tradeDay: journalTrade.tradeDay,
            tradingAccountId: props.accountId,
            tradeId: journalTrade.id
          })
        )
        .then((res) => {
          if (!res.success) {
            reject();
            return;
          }

          resolve();

          // insert into allJournalData or replace existing
          const index = allJournalData.findIndex((j) => j.tradeId === journalTrade.id);
          if (index === -1) {
            const log = {
              content: data,
              tradeDay: journalTrade.tradeDay,
              tradeId: journalTrade.id,
              tradingAccountId: props.accountId
            } as JournalLog;

            allJournalData.push(log);
            props.onJournalAdded(log);
          } else {
            allJournalData[index].content = data;
          }
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Saving journal...',
      success: 'Journal saved',
      error: 'Error saving journal'
    });
  };

  const handleDeleteJournal = () => {
    const promise = new Promise<void>((resolve, reject) => {
      journalLogApi
        .delete(
          new DeleteJournalLogRequest({
            tradeDay: journalTrade.tradeDay,
            tradingAccountId: props.accountId,
            tradeId: journalTrade.id
          })
        )
        .then((res) => {
          if (!res) {
            reject();
            return;
          }

          resolve();

          // remove from allJournalData
          const index = allJournalData.findIndex((j) => j.tradeId === journalTrade.id);
          if (index !== -1) {
            const journalData = allJournalData[index];
            allJournalData.splice(index, 1);
          }

          props.onJournalRemoved(journalData);
          setJournalData(undefined);
          setJournalTrade(undefined);
        })
        .catch((error) => {
          reject();
        });
    });

    toast.promise(promise, {
      pending: 'Deleting journal...',
      success: 'Journal deleted',
      error: 'Error deleting journal'
    });
  };

  return useMemo(
    () => (
      <Box display='flex' height={props.height ?? 500}>
        <DataGridPro
          sx={{ width: '1000px' }}
          apiRef={props.apiRef}
          rows={props.trades ?? []}
          columns={columns(true)}
          pageSizeOptions={props.pageSizeOptions ?? [20, 50, 100]}
          rowHeight={props.rowHeight ?? 35}
          columnHeaderHeight={props.columnHeaderHeight}
          pagination={true}
          getRowId={(row) => row.id}
          slots={props.slots}
          initialState={
            props.initialState
              ? props.initialState
              : {
                  sorting: {
                    sortModel: [{ field: 'entryTime', sort: 'desc' }]
                  }
                }
          }
          onStateChange={props.onStateChange}
          density={props.density ?? 'standard'}
        />

        <TsModal open={journalTrade !== undefined} onClose={() => setJournalTrade(undefined)}>
          <Box sx={{ width: '90vw', maxWidth: '850px', minHeight: '350px' }}>
            <Typography sx={{ marginBottom: '1em' }} variant='h5' color='white'>
              Journal for Trade: ID {journalTrade?.id}
            </Typography>
            {/* Do a grid with just that one trade */}
            <DataGridPro
              rows={[journalTrade ?? {}]}
              columns={columns(false)}
              pageSizeOptions={[20, 50, 100]}
              rowHeight={35}
              pagination={true}
              getRowId={(row) => 0}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'entryTime', sort: 'desc' }]
                }
              }}
              sx={{ marginBottom: '1em' }}
            />
            <Typography textAlign={'center'} margin='0.5em 0' variant='h4' color='white'>
              Trade Journal Content
            </Typography>
            <JournalEditor initialValue={journalData?.content ?? ''} onChange={(content) => setJournalData({ ...journalData, content: content } as JournalLog)} />
            <Box sx={{ display: 'flex', width: '100%', gap: '1em', rowGap: '1em', flexDirection: 'row', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
              <Button sx={{ marginLeft: '0.5em', marginTop: '0.5em', marginBottom: '2em', flex: 1 }} onClick={() => handleSaveTradeJournal(journalData?.content ?? '')} variant='contained' color='primary'>
                Save Journal
              </Button>
              {allJournalData.find((j) => j.tradeId === journalTrade?.id) && (
                <Button sx={{ marginTop: '0.5em', marginBottom: '2em', flex: 1 }} onClick={handleDeleteJournal} variant='contained' color='warning'>
                  Delete Journal
                </Button>
              )}
              <Button sx={{ marginTop: '0.5em', marginBottom: '2em', marginRight: '0.5em', flex: 1 }} onClick={() => setJournalTrade(undefined)} variant='contained' color='neutral'>
                Close
              </Button>
            </Box>
          </Box>
        </TsModal>
      </Box>
    ),
    [props, journalTrade, journalData, allJournalData]
  );
};

export default PerformanceTradesGrid;
