import { StudyTargetPriceScale } from '@/charting_library';
import {
  IChartWidgetApi,
  IChartingLibraryWidget,
  IOrderLineAdapter,
  ResolutionString,
  Timezone,
  widget,
  TradingTerminalWidgetOptions,
  IActionVariant,
  ActionsFactory,
  ActionId,
  CustomIndicator,
  RawStudyMetaInfoId,
  RawStudyMetaInfo,
  StudyPlotType,
  LibraryPineStudy,
  IPineStudyResult,
  StudyLinePlotPreferences,
  OhlcStudyPlotStyle,
  IContext,
  ChartDescriptionContext,
  WatermarkContentData,
  LibraryPineStudyConstructor,
  StudyMetaInfo,
  PineJS
} from '@/charting_library';

const LinkIndicator: (pinejs: PineJS, setLinkChanged: () => void) => CustomIndicator = (pinejs, setLinkChanged) => {
  return {
    name: 'Color Link',
    metainfo: {
      _metainfoVersion: 51,
      id: 'color_link@tv-basicstudies-1' as RawStudyMetaInfoId,
      name: 'Color Link',
      description: 'Color Link',
      shortDescription: 'Link',

      isCustomIndicator: true,
      priceScale: 2,
      is_price_study: true, // whether the study should appear on the main series pane.
      linkedToSeries: true,

      format: {
        type: 'price',
        precision: 2
      },

      plots: [{ id: 'plot_0', type: 'data' as StudyPlotType.Data, target: '', targetField: 'topColor' }],
      styles: {
        plot_0: {
          char: '🚫',
          color: 'red',
          title: 'Color Link'
        }
      },
      palettes: {},

      defaults: {
        palettes: {},
        styles: {
          plot_0: {
            char: '🚫',
            color: 'red',
            title: 'Color Link'
          }
        },
        inputs: {
          color: '🚫'
        }
      },
      inputs: [
        {
          id: 'color',
          type: 'text' as any,
          defval: '🚫',
          name: 'Color',
          options: ['🚫', '⚪', '🔴', '🔵', '🟡', '🟣', '🟠']
        }
      ]
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        setLinkChanged();
      };

      this.main = function (context, inputCallback) {
        return [1, 2, 3];
      };
    }
  };
};

export default LinkIndicator;
