import React, { useEffect } from "react";

const YourComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://do.featurebase.app/js/sdk.js";
    script.id = "featurebase-sdk";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const win = window;

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }
    win.Featurebase("embed", {
      /* Required */
      organization: "s2f",

      /* Optional */
      basePath: '/support', // Sync urls between your website & our embed. Example: '/feedback'. Refer to the url synchronizing section below to learn more.

      // Aesthetic or Display
      theme: "dark", // options: light [default], dark. Remove for auto.
      initialPage: "Board", // options: Board [default], Changelog, Roadmap
      hideMenu: false, // Hides the top navigation bar
      hideLogo: true, // Hides the logo in the top navigation bar & leaves the Sign In button visible.

      filters: null, // Default filters to apply to the board view. Copy the filters from the URL when you have the filters you want selected. Example: 'b=63f827df2d62cb301468aac4&sortBy=upvotes:desc'
      jwtToken: null, // Automatically sign in a user with a JWT token. See the JWT section below.
    });
  }, []);

  return <div data-featurebase-embed></div>;
};

export default YourComponent;
