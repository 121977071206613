import { config } from '@/config';
import React, { useEffect, useRef }  from 'react';
import { Crisp } from "crisp-sdk-web";

const CrispChat = ({ isSupportPage }) => {
  const crispLoaded = useRef(false);
  
  useEffect(() => {
    if (!config.crispKey) {
      return;
    }

    if(!isSupportPage) {
      return;
    }

    if(!crispLoaded.current) {
      Crisp.configure(config.crispKey);
      crispLoaded.current = true;
    }
  }, [config.crispKey, isSupportPage]);

  useEffect(() => {
    if(!crispLoaded.current) {
      return;
    }

    if (!isSupportPage) {
      Crisp.chat.hide();
    } else {
      Crisp.chat.show();
    }
  }, [isSupportPage]);
 
  return <></>;
};

export default React.memo(CrispChat);
