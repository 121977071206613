import { UserContractModel } from '@/api/userApi';
import { useSymbol } from '@/contexts/SymbolContext';
import React, { useEffect, useMemo, useState } from 'react';

const MobileContractKey = 'mobileContractProductId';

export interface IMobileContextContext {
  mobileContract: UserContractModel;
  setMobileContract: (contract: UserContractModel) => void;
}

export interface IMobileContextProviderProps {
  children: React.ReactNode;
}

export const MobileContext = React.createContext<IMobileContextContext>({} as any);

export const useMobile = () => React.useContext(MobileContext);

export const MobileContextProvider: React.FC<IMobileContextProviderProps> = (props) => {
  const symbols = useSymbol();
  const [firstLoad, setFirstLoad] = useState(true);
  const [contract, setContract] = React.useState<UserContractModel>(!!localStorage.getItem(MobileContractKey) ? symbols.getContractByProductId(localStorage.getItem(MobileContractKey) as string) : symbols.getDefaultContract());

  const values = useMemo(() => {
    const values: IMobileContextContext = {
      mobileContract: contract,
      setMobileContract: (contract: UserContractModel) => {
        setContract(contract);
        localStorage.setItem(MobileContractKey, contract.productId);
      }
    };

    return values;
  }, [contract, firstLoad, props.children]);

  return useMemo(() => {
    return <MobileContext.Provider value={values}>{props.children}</MobileContext.Provider>;
  }, [values, props.children]);
};
