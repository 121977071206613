import dayjs, { Dayjs } from 'dayjs';
const chicagoTimezone = "America/Chicago";
export const marketCloseHour = 16;
export const marketOpenHour = 17;

export const getCurrentMarketTime = () => {
    return dayjs().tz(chicagoTimezone);
} 
export const getCurrentTradeDay = () => {
    return getTradeDay(dayjs());
}

export const getCurrentTradeDayStart = () => {
    return getTradeDayStart(dayjs());
}

export const getTradeDay = (day: Dayjs) => {
    const convertedToChicago = day.tz(chicagoTimezone);
    if(convertedToChicago.hour() >= marketCloseHour) {
        return convertedToChicago.add(1, 'day').startOf('day');
    } else {
        return convertedToChicago.startOf('day');
    }
}

export const getTradeDayStart = (day: Dayjs) => {
    const tradeDay = getTradeDay(day);
    //trade day is always technically midnight of the next day, so we need to subtract a day and set the time to market open
    return tradeDay.add(-1, 'day').hour(marketOpenHour);
}