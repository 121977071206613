import React, { useCallback, useMemo, useState } from 'react';
import { PersonalDailyAction, TradingAccountStatus, TradingAccountType, ITradingAccountModel } from '@/api/userApi';
import { PercentSpan } from "@/components/numbers";
import Balance from "@/components/topstep/balance";
import DailyLossTargetBar from "@/components/topstep/dailyLossTargetBar";
import DailyProfitTargetBar from "@/components/topstep/dailyProfitTargetBar";
import MaxLoss from "@/components/topstep/maxLoss";
import RealizedPnl from "@/components/topstep/realizedPnl";
import UnrealizedPnl from "@/components/topstep/unrealizedPnl";
import { useTradingAccount } from "@/contexts/TradingAccountContext";
import { tradingAccountStatusMap, violationTypeMap } from 'src/data/enumTypeMaps';
import { Box, Dialog, Typography, Tooltip } from '@mui/material';
import { useApi } from '@/contexts/ApiContext';
import { StyledButton } from '@/components/styledComponents';
import styles from '../../layout/UserLayout.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons';
import PersonalLockoutModal from '@/components/topstep/personalLockoutModal';

const MobileAccountInfo = () => {
    const [showModal, setShowModal] = useState(false);
    const { activeTradingAccount } = useTradingAccount();

    const getAccountName = (account: ITradingAccountModel): string => {
        if (account){
          if (account.nickname) {
            return account.nickname + ' (' + account.accountName + ')';
          } else {
            return account.accountName;
          }
        } else {
          return 'Unknown Account';
        }
      };

    const showNewLayoutModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const canStopTrade = useMemo(() => {
        return (
            !activeTradingAccount?.isFollower &&
            (activeTradingAccount?.status === TradingAccountStatus.Active || activeTradingAccount?.status == TradingAccountStatus.Ineligible) &&
            activeTradingAccount.type != TradingAccountType.Practice
        );
    }, [activeTradingAccount?.isFollower, activeTradingAccount?.status, activeTradingAccount.type]);


    return (
        <>
            <Box display="flex" flexDirection="column" padding="2em" height="100%" sx={{ background: '#1c1e23' }}>
                <Typography variant="h5" color="white" sx={{ textAlign: 'center' }}><Typography variant="span" color="rgb(235, 188, 77)">{getAccountName(activeTradingAccount)}</Typography></Typography>

                {activeTradingAccount.status == TradingAccountStatus.Active && <span style={{ textAlign: 'center', marginBottom: 6 }}>Active</span>}
                {activeTradingAccount.status != TradingAccountStatus.Active && <span style={{ textAlign: 'center', marginBottom: 6 }}>({tradingAccountStatusMap[activeTradingAccount.status]})</span>}
                {activeTradingAccount.activeViolation && <span style={{ marginLeft: '0.3em', textAlign: 'center' }}>({violationTypeMap[activeTradingAccount.activeViolation.type]})</span>}

                {canStopTrade && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                        background: '#595959',
                        borderRadius: 10,
                        fontSize: '1.3em',
                        cursor: 'pointer'
                    }} onClick={showNewLayoutModal}>
                        Lockout <FontAwesomeIcon style={{ marginLeft: 6 }} icon={faOctagonExclamation} />
                    </div>
                )}
                {activeTradingAccount.isLeader && <>
                    <Typography variant="h6" color="white">Leader Account</Typography>
                </>}
                {activeTradingAccount.isFollower && <>
                    <Typography variant="h6" color="white">Follower Account</Typography>
                </>}
                <Box display="flex" flexDirection="column" gap={2}>
                    <Balance padding={8} fontSize={14} variant="dark" />
                    <Box display="flex" flexDirection="row" justifyContent="center" gap={1.5}>
                        <RealizedPnl padding={8} fontSize={14} variant="dark" />
                        <UnrealizedPnl padding={8} fontSize={14} variant="dark" />
                    </Box>
                    <MaxLoss padding={8} fontSize={14} variant="dark" />
                    <DailyLossTargetBar padding={8} fontSize={14} variant="dark" />
                    <DailyProfitTargetBar padding={8} fontSize={14} variant="dark" />
                </Box>
            </Box>
            {showModal && (
                <Dialog onClose={() => setShowModal(false)} open={showModal}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
                        <div className={styles.modalHeader}>
                            Confirm Trading <strong>Lock-Out</strong>?
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1em', paddingTop: 0 }}>
                    <div className={styles.modalBody}>
                        By clicking yes below, you will be <strong>Locked Out</strong> from trading on this account ({getAccountName(activeTradingAccount)}) for the selected duration. <br /> <br />
                        Once locked out, any open positions or working orders will be automatically flattened/canceled, and any copy-traded follower positions will be exited. You will not be able to trade on this account for the selected lockout period.
                        <br />
                        <br />
                        <span style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Warning: </span> 
                        <span  style={{ fontSize: '1.15em' }}>This action is irreversible.</span>
                        <br />
                        <span style={{ display: 'block', textAlign: 'center', fontSize: '1.2em', margin: '10px 0' }}>
                        Select from the following options:
                        </span>             
                    </div>
                    </Box>
                    <PersonalLockoutModal 
                        mini 
                        onConfirm={() => {
                            setShowModal(false);
                        }}
                        onCancel={() => {
                            setShowModal(false)
                        }}
                    />
                </Dialog>
            )}
        </>
    );
};

export default MobileAccountInfo;