import React from 'react';
import classNames from 'classnames';
import styles from './heading.module.scss';

export enum HeadingSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5'
}

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
  className?: string;
  size?: HeadingSize;
};

const Heading: React.FC<HeadingProps> = ({ className, size, ...props }): JSX.Element => {
  return (
    <div
      className={classNames(styles.heading, styles[size || HeadingSize.H1], className)}
      {...props}
    />
  );
};

export default React.memo(Heading);
