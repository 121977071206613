import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import styles from './settings.module.scss';
import { StyledButton } from '@/components/styledComponents';

interface PrivacySettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const PrivacySettings: React.FC<PrivacySettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const { activeTradingAccount } = useTradingAccount();

  const [isLoading, setIsLoading] = useState(false);
  const [streamerModeBalance, setStreamerModeBalance] = useState<boolean>(false);
  const [streamerModeMLL, setStreamerModeMLL] = useState<boolean>(false);
  const [streamerModeRPL, setStreamerModeRPL] = useState<boolean>(false);
  const [streamerModeUPL, setStreamerModeUPL] = useState<boolean>(false);
  const [streamerModeDLL, setStreamerModeDLL] = useState<boolean>(false);
  const [streamerModeAccountName, setStreamerModeAccountName] = useState<boolean>(false);

  useEffect(() => {
    setStreamerModeBalance(settings.customSettings.streamerModeBalance);
    setStreamerModeMLL(settings.customSettings.streamerModeMLL);
    setStreamerModeRPL(settings.customSettings.streamerModeRPL);
    setStreamerModeUPL(settings.customSettings.streamerModeUPL);
    setStreamerModeDLL(settings.customSettings.streamerModeDLL);
    setStreamerModeAccountName(settings.customSettings.streamerModeAccountName);

  }, [settings]);

  const cancel = useCallback(() => {
    setStreamerModeBalance(settings.customSettings.streamerModeBalance);
    setStreamerModeMLL(settings.customSettings.streamerModeMLL);
    setStreamerModeRPL(settings.customSettings.streamerModeRPL);
    setStreamerModeUPL(settings.customSettings.streamerModeUPL);
    setStreamerModeDLL(settings.customSettings.streamerModeDLL);
    setStreamerModeAccountName(settings.customSettings.streamerModeAccountName);
    onCancelled && onCancelled();
  }, [settings]);

  const onSave = useCallback(() => {
    settings.customSettings.streamerModeBalance = streamerModeBalance;
    settings.customSettings.streamerModeMLL = streamerModeMLL;
    settings.customSettings.streamerModeRPL = streamerModeRPL;
    settings.customSettings.streamerModeUPL = streamerModeUPL;
    settings.customSettings.streamerModeDLL = streamerModeDLL;
    settings.customSettings.streamerModeAccountName = streamerModeAccountName;
    setIsLoading(true);

    Promise.all([settings.saveCustomSettings(settings.customSettings)])
      .then(() => {
        setIsLoading(false);
        onSaved && onSaved();
        toast('Settings saved', { type: 'success', hideProgressBar: true });
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });
  }, [activeTradingAccount.accountId, streamerModeBalance, streamerModeMLL, streamerModeRPL, streamerModeUPL, streamerModeDLL, streamerModeAccountName, settings.saveCustomSettings]);

  const isChanged = useMemo(() => {
    return (
      streamerModeBalance !== settings.customSettings.streamerModeBalance ||
      streamerModeMLL !== settings.customSettings.streamerModeMLL ||
      streamerModeRPL !== settings.customSettings.streamerModeRPL ||
      streamerModeUPL !== settings.customSettings.streamerModeUPL ||
      streamerModeDLL !== settings.customSettings.streamerModeDLL ||
      streamerModeAccountName != settings.customSettings.streamerModeAccountName
    );
  }, [
    streamerModeBalance,
    streamerModeMLL,
    streamerModeRPL,
    streamerModeUPL,
    streamerModeDLL,
    streamerModeAccountName,
    settings,
    settings.customSettings,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction
  ]);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          // alignContent: 'space-around',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Privacy Settings
            <HelpMessage message='Hide your account balances, info, etc.' />
          </FormLabel>
          <FormControlLabel control={<Checkbox checked={streamerModeBalance} onChange={() => setStreamerModeBalance(!streamerModeBalance)} />} label='Streamer Mode - Balance' />
          <FormControlLabel control={<Checkbox checked={streamerModeMLL} onChange={() => setStreamerModeMLL(!streamerModeMLL)} />} label='Streamer Mode - MLL' />
          <FormControlLabel control={<Checkbox checked={streamerModeRPL} onChange={() => setStreamerModeRPL(!streamerModeRPL)} />} label='Streamer Mode - RP&L' />
          <FormControlLabel control={<Checkbox checked={streamerModeUPL} onChange={() => setStreamerModeUPL(!streamerModeUPL)} />} label='Streamer Mode - UP&L' />
          <FormControlLabel control={<Checkbox checked={streamerModeDLL} onChange={() => setStreamerModeDLL(!streamerModeDLL)} />} label='Streamer Mode - DLL' />
          <FormControlLabel control={<Checkbox checked={streamerModeAccountName} onChange={() => setStreamerModeAccountName(!streamerModeAccountName)} />} label='Streamer Mode - Account Name' />
        </FormGroup>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons) && (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} style={{ margin: '1em' }}>
            Cancel
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} style={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default PrivacySettings;
