import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

interface TimePickerProps {
  startTime?: Dayjs;
  min?: Dayjs;
  max?: Dayjs;
  onChange?: (time: PickedTime) => void;
}

export type PickedTime = {
  hour: number;
  minute: number;
}

const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
const amPmOptions = ['AM', 'PM'];

const TimePicker: React.FC<TimePickerProps> = ({
  startTime = dayjs().hour(12).minute(0),
  min,
  max,
  onChange,
}) => {
  const [hour, setHour] = useState(startTime.format('hh'));
  const [minute, setMinute] = useState(startTime.format('mm'));
  const [amPm, setAmPm] = useState(startTime.format('A'));

  useEffect(() => {
    setHour(startTime.format('hh'));
    setMinute(startTime.format('mm'));
    setAmPm(startTime.format('A'));
  }, [startTime]);

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHour = event.target.value;
    setHour(newHour);
    notifyChange(newHour, minute, amPm);
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMinute = event.target.value;
    setMinute(newMinute);
    notifyChange(hour, newMinute, amPm);
  };

  const handleAmPmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmPm = event.target.value;
    setAmPm(newAmPm);
    notifyChange(hour, minute, newAmPm);
  };

  const notifyChange = (newHour: string, newMinute: string, newAmPm: string) => {
    // Convert to 24-hour time
    const updatedTime = {
      hour: parseInt(newHour, 10) % 12 + (newAmPm === 'PM' ? 12 : 0),
      minute: parseInt(newMinute, 10),
    };

    if (onChange) {
      onChange(updatedTime);
    }
  };

  // Utility to check if a time option should be disabled based on min/max
  const isDisabled = (testHour: string, testMinute: string, testAmPm: string): boolean => {
    const formattedHour = (parseInt(testHour, 10) % 12) + (testAmPm === 'PM' ? 12 : 0);

    // Consider both min and max dates
    let possibleDates = [startTime.startOf('day'), startTime.add(1, 'day').startOf('day')];

    // If min and max are on different days, include their dates
    if (min) {
      possibleDates.push(min.startOf('day'));
    }
    if (max) {
      possibleDates.push(max.startOf('day'));
    }

    // Remove duplicate dates
    possibleDates = possibleDates.filter(
      (date, index, self) => index === self.findIndex((d) => d.isSame(date, 'day'))
    );

    // Check if any possible date results in a time within min and max
    return !possibleDates.some((date) => {
      const testTime = date.set('hour', formattedHour).set('minute', parseInt(testMinute, 10));
      return (
        (!min || !testTime.isBefore(min)) &&
        (!max || !testTime.isAfter(max))
      );
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
      <Grid container spacing={1}>
        {/* Hour Selector */}
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            value={hour}
            onChange={handleHourChange}
            SelectProps={{ native: true }}
          >
            {hours.map((h) => (
              <option
                key={h}
                value={h}
                disabled={isDisabled(h, minute, amPm)}
              >
                {h}
              </option>
            ))}
          </TextField>
        </Grid>

        {/* Minute Selector */}
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            value={minute}
            onChange={handleMinuteChange}
            SelectProps={{ native: true }}
          >
            {minutes.map((m) => (
              <option
                key={m}
                value={m}
                disabled={isDisabled(hour, m, amPm)}
              >
                {m}
              </option>
            ))}
          </TextField>
        </Grid>

        {/* AM/PM Selector */}
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            value={amPm}
            onChange={handleAmPmChange}
            SelectProps={{ native: true }}
          >
            {amPmOptions.map((period) => (
              <option
                key={period}
                value={period}
                disabled={isDisabled(hour, minute, period)}
              >
                {period}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimePicker;
