import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './performanceView.module.scss';
import Box from '@mui/material/Box';
import { FormControlLabel, Switch, Tooltip, Typography, styled } from '@mui/material';
import { LargeLightCardWrapper, LightCardWrapper } from '@/components/styledComponents';
import HelpMessage from '@/components/helpMessage';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { formatPrice } from '@/helpers/formatter';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { useApi } from '@/contexts/ApiContext';
import { AverageWinLossData, DayStatistic, TradesInRangeRequest, TradeModel, CompleteDayStatistic, GetDailyStatisticsRequest, GetMonthlyStatisticsRequest, GetWeeklyStatisticsRequest, ProfitFactorData, SetAccountSharingRequest, JournalLog, GetJournalLogRangeRequest, TradingAccountModel } from '@/api/userApi';
import { IExtendedTradingAccountModel, useTradingAccount } from '@/contexts/TradingAccountContext';
import { Calendar, CustomProvider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import DayPerformanceModal from './dayPerformanceModal';
import WeekPerformanceModal  from './weekPerformanceModal';
import PerformanceTradesGrid from './performanceTradesGrid';
import Button from '@/components/topstep/button';
import { set } from 'lodash';
import CoachTip from './performanceCoach';
import PersonalizedTip from './personalizedTip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { useSymbol } from '@/contexts/SymbolContext';
import { FormatBoldRounded } from '@mui/icons-material';


dayjs.extend(isSameOrBefore);

const chartFontSize = '1.1em';
const pieFontSize = '1.2em';
type PerformanceCardProps = {
  title: string;
  value?: string | ReactNode;
  content: ReactNode | string;
  help: string;
  width?: string | number;
  height?: string | number;
};

const CardTitle = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '1.3em',
  fontWeight: 'lighter',
  marginTop: '0.6em',
  marginBottom: '0.6em'
}));

const CardValue = styled('p')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '2.4em',
  fontWeight: 'bold',
  marginTop: '0.6em',
  marginBottom: '0.6em'
}));

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2
});

const formatDollarPrice = (value?: number): string => {
  if (isNaN(value)) return '';
  return priceFormatter.format(value);
};

const formatPercent = (value?: number): string => {
  if (isNaN(value)) return '';
  return percentFormatter.format(value);
};

const getDollarTextFromValue = (value: number) => {
  const formatted = formatDollarPrice(value);
  if (value > 0) {
    return <span style={{ color: '#56d660' }}>{formatted}</span>;
  } else if (value < 0) {
    return <span style={{ color: '#d6565e' }}>{formatted}</span>;
  } else {
    return <span>{formatted}</span>;
  }
};

const getPercentTextFromValue = (value: number, colored: boolean = false) => {
  const formatted = formatPercent(value / 100);
  if (value > 0) {
    return <span style={{ color: colored ? '#56d660' : undefined }}>{formatted}</span>;
  } else if (value < 0) {
    return <span style={{ color: colored ? '#d6565e' : undefined }}>{formatted}</span>;
  } else {
    return <span>{formatted}</span>;
  }
};

const calculateMostActiveDay = (
  dayStatistics: DayStatistic[]
): {
  day: string;
  activeDays: number;
  totalTrades: number;
  avgTrades: number;
  breakdown: string;
} => {
  const dayCount = {
    Sunday: { totalTrades: 0, days: 0 },
    Monday: { totalTrades: 0, days: 0 },
    Tuesday: { totalTrades: 0, days: 0 },
    Wednesday: { totalTrades: 0, days: 0 },
    Thursday: { totalTrades: 0, days: 0 },
    Friday: { totalTrades: 0, days: 0 },
    Saturday: { totalTrades: 0, days: 0 }
  };

  dayStatistics.forEach((stat) => {
    const date = dayjs(stat.tradeDate).tz('America/Chicago');
    const dayOfWeek = date.format('dddd') as keyof typeof dayCount;
    dayCount[dayOfWeek].totalTrades += stat.totalTrades;
    dayCount[dayOfWeek].days += 1;
  });

  let mostActiveDay = '';
  let maxActiveDays = 0;
  let breakdown = '';

  for (const [day, data] of Object.entries(dayCount)) {
    const averageTrades = data.days > 0 ? data.totalTrades / data.days : 0;
    breakdown += `${day}: ${data.days} active days, ${data.totalTrades} total trades, ${averageTrades.toFixed(2)} avg trades\n`;

    if (data.days > maxActiveDays || (data.days === maxActiveDays && data.totalTrades > (dayCount[mostActiveDay as keyof typeof dayCount]?.totalTrades || 0))) {
      mostActiveDay = day;
      maxActiveDays = data.days;
    }
  }

  // If no active days were found, return a default object
  if (mostActiveDay === '') {
    return {
      day: 'No active days',
      activeDays: 0,
      totalTrades: 0,
      avgTrades: 0,
      breakdown: breakdown
    };
  }

  const mostActiveDayData = dayCount[mostActiveDay as keyof typeof dayCount];

  return {
    day: mostActiveDay,
    activeDays: maxActiveDays,
    totalTrades: mostActiveDayData.totalTrades,
    avgTrades: mostActiveDayData.totalTrades / maxActiveDays,
    breakdown: breakdown
  };
};

const calculateMostProfitableDay = (dayStatistics: DayStatistic[]): { day: string; profit: number; breakdown: string } => {
  let mostProfitableDay = '';
  let maxProfit = -Infinity;
  let breakdown = '';

  dayStatistics.forEach((stat) => {
    const dayOfWeek = dayjs(stat.tradeDate).format('dddd');
    const dailyProfit = Number((stat.totalPnL - stat.totalFees).toFixed(2));

    breakdown += `${dayOfWeek} (${stat.tradeDate}): ${stat.totalPnL} - ${stat.totalFees} = ${dailyProfit}\n`;

    if (dailyProfit > maxProfit) {
      mostProfitableDay = dayOfWeek;
      maxProfit = dailyProfit;
    }
  });

  return {
    day: mostProfitableDay,
    profit: maxProfit,
    breakdown: breakdown
  };
};

const calculateLeastProfitableDay = (dayStatistics: DayStatistic[]): { day: string; profit: number; breakdown: string } => {
  let leastProfitableDay = '';
  let minProfit = Infinity;
  let breakdown = '';

  dayStatistics.forEach((stat) => {
    const dayOfWeek = dayjs(stat.tradeDate).format('dddd');
    const dailyProfit = Number((stat.totalPnL - stat.totalFees).toFixed(2));

    breakdown += `${dayOfWeek} (${stat.tradeDate}): ${stat.totalPnL} - ${stat.totalFees} = ${dailyProfit}\n`;

    if (dailyProfit < minProfit) {
      leastProfitableDay = dayOfWeek;
      minProfit = dailyProfit;
    }
  });

  return {
    day: leastProfitableDay,
    profit: minProfit,
    breakdown: breakdown
  };
};

const processTradeDurations = (trades: TradeModel[]) => {
  const durationBuckets = [
    { name: 'Under 15 sec', min: 0, max: 15 },
    { name: '15-45 sec', min: 15, max: 45 },
    { name: '45 sec - 1 min', min: 45, max: 60 },
    { name: '1 min - 2 min', min: 60, max: 120 },
    { name: '2 min - 5 min', min: 120, max: 300 },
    { name: '5 min - 10 min', min: 300, max: 600 },
    { name: '10 min - 30 min', min: 600, max: 1800 },
    { name: '30 min - 1 hour', min: 1800, max: 3600 },
    { name: '1 hour - 2 hours', min: 3600, max: 7200 },
    { name: '2 hours - 4 hours', min: 7200, max: 14400 },
    { name: '4 hours and up', min: 14400, max: Infinity }
  ];

  const bucketCounts = durationBuckets.map((bucket) => ({ name: bucket.name, count: 0 }));

  trades.forEach((trade) => {
    const durationInSeconds = trade.exitedAt.diff(trade.createdAt, 'second');
    const bucketIndex = durationBuckets.findIndex((bucket) => durationInSeconds >= bucket.min && durationInSeconds < bucket.max);
    if (bucketIndex !== -1) {
      bucketCounts[bucketIndex].count++;
    }
  });

  return bucketCounts;
};

const processWinRateByDuration = (trades: TradeModel[]) => {
  const durationBuckets = [
    { name: 'Under 15 sec', min: 0, max: 15 },
    { name: '15 - 45 sec', min: 15, max: 45 },
    { name: '45 sec - 1 min', min: 45, max: 60 },
    { name: '1 min - 2 min', min: 60, max: 120 },
    { name: '2 min - 5 min', min: 120, max: 300 },
    { name: '5 min - 10 min', min: 300, max: 600 },
    { name: '10 min - 30 min', min: 600, max: 1800 },
    { name: '30 min - 1 hour', min: 1800, max: 3600 },
    { name: '1 hour - 2 hours', min: 3600, max: 7200 },
    { name: '2 hours - 4 hours', min: 7200, max: 14400 },
    { name: '4 hours and up', min: 14400, max: Infinity }
  ];

  const bucketStats = durationBuckets.map((bucket) => ({
    name: bucket.name,
    winCount: 0,
    totalCount: 0
  }));

  trades.forEach((trade) => {
    const durationInSeconds = trade.exitedAt.diff(trade.createdAt, 'second');
    const bucketIndex = durationBuckets.findIndex((bucket) => durationInSeconds >= bucket.min && durationInSeconds < bucket.max);
    if (bucketIndex !== -1) {
      bucketStats[bucketIndex].totalCount++;
      if (trade.pnL > 0) {
        bucketStats[bucketIndex].winCount++;
      }
    }
  });

  const winRates = bucketStats.map((bucket) => ({
    name: bucket.name,
    winRate: bucket.totalCount > 0 ? (bucket.winCount / bucket.totalCount) * 100 : 0
  }));

  const overallWinRate = (trades.filter((trade) => trade.pnL > 0).length / trades.length) * 100;

  return { winRates, overallWinRate };
};

const PerformanceCard = (props: PerformanceCardProps) => {
  return (
    <LightCardWrapper width={props.width} height='8vw' maxHeight='120px' minHeight='86px' minWidth='313px' flex={1} style={{ gap: '2em' }}>
      <Box>
        {/* Left side content */}
        <CardTitle style={{ marginRight: '2em', position: 'relative' }}>
          {props.title}
          <HelpMessage message={props.help} style={{ position: 'absolute', zIndex: 10 }} />
        </CardTitle>
        <CardValue>{props.value}</CardValue>
      </Box>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='end' flexGrow={1} style={{ fontSize: '1.5rem' }}>
        {/* Right side content */}
        {props.content}
      </Box>
    </LightCardWrapper>
  );
};

const LargePerformanceCard = (props: PerformanceCardProps) => {
  return (
    <LargeLightCardWrapper width={props.width} height={props.height ?? 400} flex={1} style={{ gap: '2em' }}>
      <Box>
        <CardTitle style={{ marginRight: '2em', position: 'relative' }}>
          {props.title}
          <HelpMessage message={props.help} style={{ position: 'absolute', zIndex: 10 }} />
        </CardTitle>
        {props.content}
      </Box>
    </LargeLightCardWrapper>
  );
};

const halfPie = (data: any) => {
  if (!data) return null;
  return (
    <div style={{ marginBottom: '-55px', marginRight: '-25px', width: '200px', fontSize: '1.5em' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          series: [
            {
              type: 'pie',
              startAngle: -90,
              endAngle: 90,
              data: data,
              size: '100px',
              innerSize: '80%',
              dataLabels: {
                enabled: true,
                connectorShape: 'none', // or set it to 'straight' or 'none' to remove lines
                backgroundColor: 'rgba(0,0,0,0.6)',
                borderRadius: '5px',
                style: {
                  color: '#fff',
                  fontSize: pieFontSize
                }
              }
            }
          ],
          tooltip: {
            backgroundColor: '#000000', // Set tooltip background color to black
            style: {
              color: '#FFFFFF' // Set text color to white
            },
            useHTML: true, // Enable HTML in the tooltip
            headerFormat: '<span style="color: #FFFFFF;">Trades</span><br/>', // Custom title for the tooltip
            pointFormat: '{point.type}: {point.y}'
          },
          chart: {
            backgroundColor: 'transparent',
            height: 120
          },
          plotOptions: {
            pie: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                connectorWidth: 0, // this removes the line connecting to the labels
                distance: 2 // adjust the distance as per your requirement
              }
            }
          }
        }}
      />
    </div>
  );
};

const fullPie = (data: any) => {
  if (!data) return null;
  return (
    <div style={{ marginBottom: '-5px', marginRight: '-25px', width: '220px', overflow: 'hidden', fontSize: '1.3em' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          series: [
            {
              type: 'pie',
              data: data,
              size: '90px',
              innerSize: '80%',
              dataLabels: {
                enabled: true,
                backgroundColor: 'rgba(0,0,0,0.6)',
                borderRadius: '5px',
                connectorShape: 'none', // or set it to 'straight' or 'none' to remove lines
                style: {
                  width: 'auto',
                  'white-space': 'nowrap', // Set the text to not wrap
                  color: '#fff',
                  fontSize: pieFontSize
                },
                formatter: function () {
                  return this.point.sign + (this.point.dollar === '$' ? formatDollarPrice(this.y) : this.y);
                }
              }
            }
          ],
          chart: {
            backgroundColor: 'transparent',
            height: 120
          },
          tooltip: {
            backgroundColor: '#000000', // Set tooltip background color to black
            style: {
              color: '#FFFFFF' // Set text color to white
            },
            useHTML: true, // Enable HTML in the tooltip
            headerFormat: '<span style="color: #FFFFFF;">Total</span><br/>', // Custom title for the tooltip
            pointFormat: '{point.type}: {point.sign}{point.dollar}{point.abs}'
          },
          plotOptions: {
            pie: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                connectorWidth: 0, // this removes the line connecting to the labels
                distance: 0 // adjust the distance as per your requirement
              }
            }
          }
        }}
      />
    </div>
  );
};

const TradeDurationChart = ({ trades }: { trades: TradeModel[] }) => {
  const chartData = useMemo(() => processTradeDurations(trades), [trades]);

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent'
    },
    title: {
      text: 'Trade Count',
      style: { color: '#FFFFFF' }
    },
    xAxis: {
      categories: chartData.map((item) => item.name),
      title: { text: null },
      labels: { style: { color: '#FFFFFF' } }
    },
    yAxis: {
      gridLineColor: '#3d3c43',
      min: 0,
      labels: { style: { color: '#FFFFFF' } }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          style: { color: '#FFFFFF' }
        }
      }
    },
    legend: { enabled: false },
    credits: { enabled: false },
    series: [
      {
        name: 'Trades',
        data: chartData.map((item) => item.count),
        color: '#bebdc5',
        borderColor: 'none',
        type: 'bar'
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const WinRateByDurationChart = ({ trades }: { trades: TradeModel[] }) => {
  const { winRates, overallWinRate } = useMemo(() => processWinRateByDuration(trades), [trades]);

  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent'
    },
    title: {
      text: 'Win Rate',
      style: { color: '#FFFFFF' }
    },
    xAxis: {
      categories: winRates.map((item) => item.name),
      title: { text: null },
      labels: { style: { color: '#FFFFFF' } }
    },
    yAxis: {
      min: 0,
      max: 100,
      gridLineColor: '#3d3c43',
      labels: {
        style: { color: '#FFFFFF' },
        format: '{value}%'
      }
    },
    tooltip: {
      backgroundColor: '#000000', // Set tooltip background color to black
      style: {
        color: '#FFFFFF', // Set text color to white
        fontSize: chartFontSize
      },
      useHTML: true, // Enable HTML in the tooltip
      pointFormat: '{point.percent}'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y:.1f}%',
          style: { color: '#FFFFFF' }
        }
      }
    },
    legend: { enabled: false },
    credits: { enabled: false },
    series: [
      {
        name: 'Win Rate',
        data: winRates.map((item) => ({
          y: item.winRate,
          color: item.winRate >= overallWinRate ? '#56d660' : '#d6565e',
          borderColor: 'none',
          percent: formatPercent(item.winRate / 100)
        })),
        type: 'bar'
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const profitLineChart = (data: any) => {
  return (
    <Box sx={{ fontSize: chartFontSize }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            title: {
              text: 'Date',
              type: 'datetime',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              },
              formatter: function () {
                return dayjs(this.value).tz(dayjs.tz.guess()).format('MM/DD'); // Format the labels as mm/dd/yyyy
              }
            }
          },
          yAxis: {
            gridLineColor: '#3d3c43',
            title: {
              text: 'Profit',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            }
          },
          tooltip: {
            backgroundColor: '#000000', // Set tooltip background color to black
            style: {
              color: '#FFFFFF', // Set text color to white
              fontSize: chartFontSize
            },
            useHTML: true, // Enable HTML in the tooltip
            headerFormat: '<span style="color: #FFFFFF;">Day Cumulative Profit</span><br/>', // Custom title for the tooltip

            pointFormat: 'Date: {point.dateStr}<br>Profit: {point.name}'
          },
          series: [
            {
              type: 'area',
              data: data,
              zones: [
                {
                  value: 0, // Anything below 0 (negative values)
                  color: '#d6565e',
                  fillColor: 'rgba(214, 86, 96, 0.2)' // Light red shading
                },
                {
                  color: '#56d660', // Positive values
                  fillColor: 'rgba(86, 214, 96, 0.2)' // Light green shading
                }
              ],
              zoneAxis: 'y'
            }
          ],
          chart: {
            backgroundColor: 'transparent',
            height: 350 // Set the max height here (e.g., 400 pixels)
          },
          legend: {
            enabled: false // Remove the series legend
          }
        }}
      />
    </Box>
  );
};

const dailyBalanceChart = (data: any, minValue: number) => {
  return (
    <Box sx={{ fontSize: chartFontSize }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            type: 'datetime',
            title: {
              text: 'Date',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              },
              formatter: function () {
                return dayjs(this.value).tz(dayjs.tz.guess()).format('MM/DD');
              }
            }
          },
          yAxis: {
            gridLineColor: '#3d3c43',
            title: {
              text: 'Balance',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            min: minValue - 500
          },
          tooltip: {
            backgroundColor: '#000000',
            style: {
              color: '#FFFFFF',
              fontSize: chartFontSize
            },
            useHTML: true,
            headerFormat: '<span style="color: #FFFFFF;">Ending Account Balance</span><br/>',
            pointFormatter: function () {
              return `Date: ${this.dateStr}<br>Balance: ${this.name}`;
            }
          },
          series: [
            {
              type: 'area',
              data: data.data,
              color: '#929295',
              fillColor: 'rgba(146, 146, 149, 0.2)'
            }
          ],
          chart: {
            backgroundColor: 'transparent',
            height: 350
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            area: {
              marker: {
                radius: 2
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              threshold: null
            }
          }
        }}
      />
    </Box>
  );
};

const profitBarChart = (data: any) => {
  return (
    <Box sx={{ fontSize: chartFontSize }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            title: {
              text: 'Date',
              type: 'datetime',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              },
              formatter: function () {
                return Highcharts.dateFormat('%m/%d', this.value); // Format the labels as mm/dd/yyyy
              }
            }
          },
          yAxis: {
            gridLineColor: '#3d3c43',
            title: {
              text: 'Profit',
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            },
            labels: {
              style: {
                color: '#FFFFFF',
                fontSize: chartFontSize
              }
            }
          },
          tooltip: {
            backgroundColor: '#000000', // Set tooltip background color to black
            style: {
              color: '#FFFFFF', // Set text color to white
              fontSize: chartFontSize
            },
            useHTML: true, // Enable HTML in the tooltip
            headerFormat: '<span style="color: #FFFFFF;">Day Profit</span><br/>', // Custom title for the tooltip
            pointFormat: 'Date: {point.dateStr}<br>Profit: {point.name}'
          },
          series: [
            {
              type: 'column',
              data: data,
              borderWidth: 0,
              zones: [
                {
                  value: 0, // Anything below 0 (negative values)
                  color: '#d6565e',
                  fillColor: 'rgba(214, 86, 96, 0.2)' // Light red shading
                },
                {
                  color: '#56d660', // Positive values
                  fillColor: 'rgba(86, 214, 96, 0.2)' // Light green shading
                }
              ],
              zoneAxis: 'y'
            }
          ],
          chart: {
            backgroundColor: 'transparent',
            height: 350 // Set the max height here (e.g., 400 pixels)
          },
          legend: {
            enabled: false // Remove the series legend
          }
        }}
      />
    </Box>
  );
};

const winLossLine = (win: number, loss: number) => {
  const winPercent = (win / (win + loss)) * 100;
  const lossPercent = (loss / (win + loss)) * 100;

  return (
    <Box position='relative' display='flex' width='100%' flexGrow={1} flexDirection='column' gap={1}>
      <Box position='absolute' width='100%' display='flex' flexDirection='row'>
        {/* Two bars which add up to 100% */}
        <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average win: {getDollarTextFromValue(win)}</span>}>
          <Box width='100%' height='10px' bgcolor='#56d660' borderRadius='10px 0 0 10px' style={{ width: `${winPercent}%` }} />
        </Tooltip>
        <Tooltip title={<span style={{ fontSize: '1.2em' }}>Average loss: {getDollarTextFromValue(loss)}</span>}>
          <Box width='100%' height='10px' bgcolor='#d6565e' borderRadius='0 10px 10px 0' style={{ width: `${Math.abs(lossPercent)}%` }} />
        </Tooltip>
      </Box>
      <Box marginTop='1.5em' width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
        <span style={{ fontSize: '1.3em' }} color='#56d660'>
          {getDollarTextFromValue(win)}
        </span>
        <span style={{ fontSize: '1.3em' }} color='#d6565e'>
          {getDollarTextFromValue(loss)}
        </span>
      </Box>
    </Box>
  );
};

const PerformanceView = () => {
  const [dateFilter, setDateFilter] = useState<DateRange<Dayjs>>([dayjs().subtract(30, 'day').startOf('day').tz('America/Chicago'), dayjs().endOf('day').tz('America/Chicago')]);
  const [selectedRange, setSelectedRange] = useState<string | null>(null);
  // Setting the date range presets
  const setDayRange = () => {
    const today = dayjs();
    setDateFilter([today.startOf('day'), today.endOf('day')]);
    setSelectedRange('day');
  };
  const setWeekRange = () => {
    const today = dayjs();
    const weekAgo = today.subtract(7, 'days');
    setDateFilter([weekAgo.startOf('day'), today.add(2,'day').endOf('day')]);
    setSelectedRange('week');
  };
  const setMonthRange = () => {
    const today = dayjs();
    const monthAgo = today.subtract(30, 'days');
    setDateFilter([monthAgo.startOf('day'), today.add(2,'day').endOf('day')]);
    setSelectedRange('month');
  };

  // Saving preference for date range selection
  useEffect(() => {
    const savedRange = localStorage.getItem('selectedRange');
    if (savedRange) {
      setSelectedRange(savedRange);
      if (savedRange === 'day') setDayRange();
      if (savedRange === 'week') setWeekRange();
      if (savedRange === 'month') setMonthRange();
      if (savedRange === null) setMonthRange();
    }
  }, []);
  useEffect(() => {
    if (selectedRange) {
      localStorage.setItem('selectedRange', selectedRange);
    }
  }, [selectedRange]);

  const { activeTradingAccount } = useTradingAccount();
  const { statisticsApi, tradeApi, journalLogApi } = useApi();
  const { getContractByProductId } = useSymbol();

  const [dayStatistics, setDayStatistics] = useState<DayStatistic[]>([]);
  const [dailyStatistics, setDailyStatistics] = useState<CompleteDayStatistic[]>([]);
  const [weeklyStatistics, setWeeklyStatistics] = useState<CompleteDayStatistic[]>([]);
  const [monthlyStatistics, setMonthlyStatistics] = useState<CompleteDayStatistic[]>([]);
  const [selectedWeek, setSelectedWeek] = useState(null);

  const [todayStatistics, setTodayStatistics] = useState<DayStatistic>(new DayStatistic());
  const [profitFactor, setProfitFactor] = useState<ProfitFactorData>({} as ProfitFactorData);
  const [winLossStatistics, setWinLossStatistics] = useState<AverageWinLossData>({} as AverageWinLossData);
  const [consistency, setConsistency] = useState<number>(0);
  const [trades, setTrades] = useState<TradeModel[]>([]);
  const [journalLogs, setJournalLogs] = useState<JournalLog[]>([]);

  const [dayModalDate, setDayModalDate] = useState<Dayjs | undefined>(undefined);

  const [tradingAccountId, setTradingAccountId] = useState<number>(activeTradingAccount?.accountId ?? parseInt(new URLSearchParams(window.location.search).get('share')));

  const [shareBlocked, setShareBlocked] = useState<boolean>(false);

  const getAccountName = (account: IExtendedTradingAccountModel): string => {
    if (account) {
      if (account.nickname) {
        return account.nickname + ' (' + account.accountName + ')';
      } else {
        return account.accountName;
      }
    } else {
      return 'Unknown Account';
    }
  };

  const [accountName, setAccountName] = useState<string>(getAccountName(activeTradingAccount));

  const [lastJournalUpdate, setLastJournalUpdate] = useState<Dayjs | undefined>(undefined);

  const totalLots = useMemo(() => trades.reduce((acc, trade) => acc + Math.abs(trade.positionSize), 0), [trades]);

  const averageTradeDuration = useMemo(() => {
    const totalDuration = trades.reduce((acc, trade) => {
      const duration = dayjs(trade.exitedAt).diff(dayjs(trade.createdAt), 'seconds');
      return acc + duration;
    }, 0);
    return trades.length > 0 ? totalDuration / trades.length : 0;
  }, [trades]);

  useEffect(() => {
    // If this is shared, ignore the active account
    const urlParams = new URLSearchParams(window.location.search);
    const share = urlParams.get('share');
    if (share) return;

    if (activeTradingAccount) {
      setTradingAccountId(activeTradingAccount.accountId);
    }

    setAccountName(getAccountName(activeTradingAccount));
  }, [activeTradingAccount]);

  const mostActiveDay = useMemo(() => {
    return calculateMostActiveDay(dayStatistics);
  }, [dayStatistics]);

  const mostProfitableDay = useMemo(() => {
    return calculateMostProfitableDay(dayStatistics);
  }, [dayStatistics]);

  const leastProfitableDay = useMemo(() => {
    return calculateLeastProfitableDay(dayStatistics);
  }, [dayStatistics]);

  // If there is a 'share' attribute in the url, use that as the tradingAccountId
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const share = urlParams.get('share');
    if (share) {
      // Ensure account is actually sharing
      let id = parseInt(share);
      statisticsApi
        .checkAccountSharing(id)
        .then((data) => {
          if (data) {
            setTradingAccountId(id);
            setShareBlocked(false);

            // Get account name
            statisticsApi
              .getAccountName(id)
              .then((name) => {
                setAccountName(name);
              })
              .catch((err) => {
                console.error(err);
                setAccountName('Unknown Account');
              });
          } else {
            setShareBlocked(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setShareBlocked(true);
        });
    }
  }, []);

  useEffect(() => {
    loadStats();
  }, []);

  useEffect(() => {
    loadStats();
  }, [tradingAccountId, dateFilter]);

  const loadStats = () => {
    statisticsApi
      .getDayStats(
        new GetDailyStatisticsRequest({
          startTradeDay: dateFilter[0],
          endTradeDay: dateFilter[1],
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setDayStatistics(data);

        // Calculate consistency
        const totalProfit = data.reduce((acc, stat) => acc + (stat.totalPnL - stat.totalFees), 0);
        const bestDayProfit = Math.max(...data.map((stat) => stat.totalPnL - stat.totalFees));
        const consistencyValue = totalProfit !== 0 ? (bestDayProfit / Math.abs(totalProfit)) * 100 : 0;
        setConsistency(consistencyValue);
      });

    statisticsApi.getTodayStats(tradingAccountId).then((data) => {
      setTodayStatistics(data[0]);
    });

    statisticsApi
      .getProfitFactor(
        new GetDailyStatisticsRequest({
          startTradeDay: dateFilter[0],
          endTradeDay: dateFilter[1],
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setProfitFactor(data);
      });

    statisticsApi
      .getWinLossAvg(
        new GetDailyStatisticsRequest({
          startTradeDay: dateFilter[0],
          endTradeDay: dateFilter[1],
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setWinLossStatistics(data);
      });

    statisticsApi
      .getDaily(
        new GetDailyStatisticsRequest({
          startTradeDay: dateFilter[0],
          endTradeDay: dateFilter[1],
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setDailyStatistics(data);
      });

    tradeApi
      .getInRange(
        new TradesInRangeRequest({
          start: dateFilter[0],
          end: dateFilter[1],
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setTrades(data);
      });
  };

  const totalProfitAndLoss = useMemo(() => {
    return dayStatistics.reduce((acc, stat) => acc + stat.totalPnL - stat.totalFees, 0);
  }, [dayStatistics]);

  const totalWinningTrades = useMemo(() => {
    return dayStatistics.reduce((acc, stat) => acc + stat.winningTrades, 0);
  }, [dayStatistics]);

  const totalLosingTrades = useMemo(() => {
    return dayStatistics.reduce((acc, stat) => acc + stat.losingTrades, 0);
  }, [dayStatistics]);

  const totalNeutralTrades = useMemo(() => {
    return dayStatistics.reduce((acc, stat) => acc + stat.neutralTrades, 0);
  }, [dayStatistics]);

  const totalTrades = useMemo(() => {
    return dayStatistics.reduce((acc, stat) => acc + stat.totalTrades, 0);
  }, [dayStatistics]);

  const dayWinPercentText = useMemo(() => {
    if (!todayStatistics || todayStatistics.totalTrades === 0) return 'No trades';
    return formatPercent(todayStatistics.winningTrades / todayStatistics.totalTrades);
  }, [todayStatistics]);

  const totalLongTrades = useMemo(() => {
      return trades.filter((trade) => trade.positionSize <= 0).length;
  }, [trades]);
  
  const totalShortTrades = useMemo(() => {
      return trades.filter((trade) => trade.positionSize > 0).length;
  }, [trades]);

  const longShortPercent = useMemo(() => {
    if (totalLongTrades + totalShortTrades === 0) {
      return 0;
    }
    return (totalLongTrades / (totalLongTrades + totalShortTrades)) * 100;
  }, [totalLongTrades, totalShortTrades]);

  const tradeWinPercent = useMemo(() => {
    if (totalWinningTrades + totalLosingTrades === 0) {
      return 0;
    }
    return (totalWinningTrades / (totalWinningTrades + totalLosingTrades)) * 100;
  }, [totalWinningTrades, totalLosingTrades]);

  const tradeWinPercentText = useMemo(() => {
    return getPercentTextFromValue(tradeWinPercent, false);
  }, [totalWinningTrades, totalLosingTrades]);

  const longShortPercentText = useMemo(() => {
    return getPercentTextFromValue(longShortPercent, false);
  }, [totalLongTrades, totalShortTrades]);

  const profitFactorText = useMemo(() => {
    if (!profitFactor) return 'N/A';
    if (profitFactor.totalLoss === 0) return '1.00';
    if (profitFactor.totalProfit === 0) return '0.00';
    let pf = Math.abs(profitFactor.totalProfit / profitFactor.totalLoss).toFixed(2);
    if (pf === 'NaN') return 'N/A';
    return pf;
  }, [profitFactor]);

  const averageWinLossText = useMemo(() => {
    if (!winLossStatistics) return 'N/A';
    if (winLossStatistics.averageLoss === 0 && winLossStatistics.averageWin === 0) return 'N/A';
    const res = Math.abs(winLossStatistics.averageWin / winLossStatistics.averageLoss).toFixed(2);
    if (res == 'Infinity') return '∞';
    return res;
  }, [winLossStatistics]);

  const bestDayPercentOfTotalProfit = useMemo(() => {
    if (totalProfitAndLoss <= 0) return 0;
    const bestDayProfit = Math.max(...dayStatistics.map((stat) => stat.totalPnL - stat.totalFees));
    return (bestDayProfit / totalProfitAndLoss) * 100;
  }, [dayStatistics, totalProfitAndLoss]);

  const [bestTrade, worstTrade] = useMemo(() => {
    if (trades.length === 0) return [null, null];
    const sortedTrades = [...trades].sort((a, b) => b.pnL - a.pnL);
    return [sortedTrades[0], sortedTrades[sortedTrades.length - 1]];
  }, [trades]);

  // Chart Data //

  const tradeWinPieData = useMemo(() => {
    if (totalLosingTrades + totalNeutralTrades + totalWinningTrades === 0) return null;
    const trades = [];
    if (totalLosingTrades > 0) trades.push({ y: totalLosingTrades, type: 'Losing', name: `${totalLosingTrades}`, color: '#d6565e', dataLabels: { style: { color: '#d6565e' } } });
    if (totalNeutralTrades > 0) trades.push({ y: totalNeutralTrades, type: 'Neutral', name: `${totalNeutralTrades}`, color: '#567ed6', dataLabels: { style: { color: '#567ed6' } } });
    if (totalWinningTrades > 0) trades.push({ y: totalWinningTrades, type: 'Winning', name: `${totalWinningTrades}`, color: '#56d660', dataLabels: { style: { color: '#56d660' } } });
    return trades;
  }, [totalLosingTrades, totalNeutralTrades, totalWinningTrades]);

  const profitFactorData = useMemo(() => {
    if (!profitFactor || (profitFactor.totalLoss === 0 && profitFactor.totalProfit === 0)) return null;
    const data = [];

    if (profitFactor.totalLoss)
      data.push({ 
        y: -profitFactor.totalLoss, 
        total: profitFactor.totalLoss, 
        type: 'Loss', 
        abs: Math.abs(profitFactor.totalLoss), 
        sign: '-', name: `${profitFactor.totalLoss}`, 
        dollar: '$', 
        color: '#d6565e', 
        dataLabels: { style: { color: '#d6565e' } } });
    if (profitFactor.totalProfit)
      data.push({ 
        y: profitFactor.totalProfit, 
        total: profitFactor.totalProfit, 
        type: 'Gain', 
        abs: Math.abs(profitFactor.totalProfit), 
        sign: '', 
        name: `${profitFactor.totalProfit}`,  
        dollar: '$', 
        color: '#56d660', 
        dataLabels: { style: { color: '#56d660' } } });
    return data;
  }, [profitFactor]);

  const longShortData = useMemo(() => {
    if (totalLongTrades + totalShortTrades === 0) return null;
    const trades = [];
    if (totalLongTrades > 0) 
      trades.push({ 
        y: totalLongTrades,
        total: totalLongTrades,
        abs: Math.abs(totalLongTrades),
        type: 'Long',
        name: `${totalLongTrades}`,
        sign: '', 
        color: '#56d660',
        dollar: '', 
        sataLabels: { style: { color: '#56d660' } } });
    if (totalShortTrades > 0) 
      trades.push({ 
        y: totalShortTrades, 
        total: totalShortTrades, 
        abs: Math.abs(totalShortTrades), 
        type: 'Short', 
        name: `${totalShortTrades}`, 
        sign: '', 
        color: '#d6565e', 
        dollar: '', 
        dataLabels: { style: { color: '#d6565e' } } });
    return trades;
  }, [totalLongTrades, totalShortTrades]);

  const dayWinPieData = useMemo(() => {
    if (!todayStatistics) return [];

    const winPieData = [];

    if (todayStatistics.losingTrades > 0) winPieData.push({ y: todayStatistics.losingTrades, type: 'Losing', name: `${todayStatistics.losingTrades}`, color: '#d6565e', dataLabels: { style: { color: '#d6565e' } } });
    if (todayStatistics.neutralTrades > 0) winPieData.push({ y: todayStatistics.neutralTrades, type: 'Neutral', name: `${todayStatistics.neutralTrades}`, color: '#567ed6', dataLabels: { style: { color: '#567ed6' } } });
    if (todayStatistics.winningTrades > 0) winPieData.push({ y: todayStatistics.winningTrades, type: 'Winning', name: `${todayStatistics.winningTrades}`, color: '#56d660', dataLabels: { style: { color: '#56d660' } } });

    return winPieData;
  }, [todayStatistics]);

  const dailyPnlData = useMemo(() => {
    return dayStatistics.map((stat) => {
      return {
        x: stat.tradeDate.toDate(),
        y: stat.totalPnL - stat.totalFees,
        color: stat.totalPnL - stat.totalFees > 0 ? '#56d660' : '#d6565e',
        name: formatDollarPrice(stat.totalPnL - stat.totalFees),
        dateStr: stat.tradeDate.tz(dayjs.tz.guess()).format('MM/DD')
      };
    });
  }, [dayStatistics]);

  const dailyPnlCumulativeData = useMemo(() => {
    // Each data point is the sum of all previous data points plus the current data point
    return dayStatistics.map((stat, index) => {
      const previousData = dayStatistics.slice(0, index);
      const previousSum = previousData.reduce((acc, prev) => acc + prev.totalPnL - prev.totalFees, 0);
      return {
        x: stat.tradeDate.toDate(),
        y: previousSum + stat.totalPnL - stat.totalFees,
        color: previousSum + stat.totalPnL - stat.totalFees > 0 ? '#56d660' : '#d6565e',
        name: formatDollarPrice(previousSum + stat.totalPnL - stat.totalFees),
        dateStr: stat.tradeDate.tz(dayjs.tz.guess()).format('MM/DD')
      };
    });
  }, [dayStatistics]);

  const dailyBalanceData = useMemo(() => {
    if (dailyStatistics.length === 0) return { data: [], minBalance: 0 };

    const startDate = dayjs(dailyStatistics[0].tradeDay).startOf('day');
    const endDate = dayjs(dailyStatistics[dailyStatistics.length - 1].tradeDay).startOf('day');

    let currentBalance = dailyStatistics[0].balance;
    let minBalance = currentBalance;

    const data = [];
    let currentDate = startDate;
    let statIndex = 0;

    while (currentDate.isSameOrBefore(endDate)) {
      const stat = dailyStatistics[statIndex];
      if (stat && dayjs(stat.tradeDay).startOf('day').isSame(currentDate)) {
        currentBalance = stat.balance;
        statIndex++;
      }

      data.push({
        x: currentDate.toDate(),
        y: currentBalance,
        color: '#fff',
        name: formatDollarPrice(currentBalance),
        dateStr: currentDate.tz(dayjs.tz.guess()).format('MM/DD')
      });

      minBalance = Math.min(minBalance, currentBalance);
      currentDate = currentDate.add(1, 'day');
    }

    return {
      data,
      minBalance
    };
  }, [dailyStatistics]);

  // Calendar Stuff //

  const [calendarData, setCalendarData] = useState<DayStatistic[]>([]);
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(dayjs().month());

  useEffect(() => {
    onMonthChange(new Date(), true);
  }, []);

  useEffect(() => {
    onMonthChange(dayjs().month(currentCalendarMonth).toDate(), true);
  }, [tradingAccountId]);

  useEffect(() => {
    journalLogApi
      .getForRange(
        new GetJournalLogRangeRequest({
          startDate: startMonthDateRef.current,
          endDate: endMonthDateRef.current,
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setJournalLogs(data);
      });
  }, [lastJournalUpdate]);

  const dateToDataMap = useMemo(() => {
    return calendarData.reduce(
      (acc, stat) => {
        acc[stat.tradeDate.tz('America/Chicago').format('DD-MM-YYYY')] = stat;
        return acc;
      },
      {} as { [key: string]: DayStatistic }
    );
  }, [calendarData]);

  const dateToJournalMap = useMemo(() => {
    return journalLogs.reduce(
      (acc, log) => {
        const date = dayjs(log.tradeDay).tz('America/Chicago').format('DD-MM-YYYY');
        if (!acc[date]) acc[date] = [];
        acc[date].push(log);
        return acc;
      },
      {} as { [key: string]: JournalLog[] }
    );
  }, [journalLogs]);

  const weeklyData = useMemo(() => {
    if (weeklyStatistics.length > 0) {
      return weeklyStatistics.reduce((acc, stat) => {
        acc[stat.tradeDay.endOf('week').format('YYYY-MM-DD')] = {
          totalNetPnL: stat.totalPnL - stat.totalFees,
          totalTrades: stat.totalTrades
        };
        return acc;
      }, {});
    }
    return {};
  }, [weeklyStatistics]);

  const monthlyData = useMemo(() => {
    if (monthlyStatistics.length > 0) {
      const stat = monthlyStatistics[0];
      return { totalNetPnL: stat.totalPnL - stat.totalFees, totalTrades: stat.totalTrades };
    }
    return { totalNetPnL: 0, totalTrades: 0 };
  }, [monthlyStatistics]);

  const renderDateCell = (date: Date) => {
    const day = dayjs(date).format('DD-MM-YYYY');
    const stat = dateToDataMap[day];

    const logs = dateToJournalMap[day];

    return (
      <Box position='relative' display='flex' flexDirection='column' justifyContent='center' alignItems='center' width='100%' height='100%' paddingBottom='1em'>
        {stat && (
          <>
            <div style={{ fontSize: '1.5em', fontWeight: 700, color: '#FFFFFF' }}>{getDollarTextFromValue(stat.totalPnL - stat.totalFees)}</div>
            <p style={{ margin: 0, color: 'rgba(255, 255, 255, 0.5)' }}>{stat.totalTrades} trades</p>
          </>
        )}
        {logs && logs.length > 0 && (
          // note icon
          <TextSnippetIcon sx={{ position: 'absolute', right: '5px', top: '-15px' }} color='primary' />
        )}
      </Box>
    );
  };

  // Helper function to get the week number within the month
  const getWeekOfMonth = (date) => {
    const startOfMonth = dayjs(date).startOf('month');
    const startOfWeek = dayjs(date).startOf('week');
    const firstWeekOffset = startOfMonth.day(); // Day of the week the month starts on
    const adjustedStartOfWeek = startOfWeek.add(firstWeekOffset, 'day'); // Adjust start of week to account for partial week
    return adjustedStartOfWeek.diff(startOfMonth, 'week') + 1;
  };

  const renderCell = (date) => {
  const day = dayjs(date).day();
  if (day === 6) {
    const week = dayjs(date).endOf('week').format('YYYY-MM-DD');
    const data = weeklyData[week] || { totalNetPnL: 0, totalTrades: 0 };
    const weekOfMonth = getWeekOfMonth(date);
    const logs = dateToJournalMap[day];
    return (
      <Box
        position='relative'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
        paddingBottom='1em'
        style={{ cursor: 'pointer' }}
      >
        <>
          <p style={{ fontSize: '1em', margin: 0, fontWeight: 700, color: '#FFFFFF'}}>Week {weekOfMonth} </p>
          <div style={{ fontSize: '1.5em', fontWeight: 700, color: '#FFFFFF' }}>{getDollarTextFromValue(data.totalNetPnL)}</div>
          <p style={{ margin: 0, color: 'rgba(255, 255, 255, 0.5)' }}>{data.totalTrades} trades</p>
        </>
        {logs && logs.length > 0 && (
          // note icon
          <TextSnippetIcon sx={{ position: 'absolute', right: '5px', top: '-15px' }} color='primary' />
        )}
      </Box>
    );
  }
  return renderDateCell(date);
};

  const getCellClass = (date: Date) => {
    const conv = dayjs(date);

    const day = conv.format('DD-MM-YYYY');
    const month = conv.month();

    if (month !== currentCalendarMonth) return styles.cellOutside;

    const stat = dateToDataMap[day];
    if (!stat) return styles.cell;
    if (stat.totalPnL - stat.totalFees === 0) return styles.cell;

    return stat.totalPnL - stat.totalFees > 0 ? styles.greenCell : styles.redCell;
  };

  const onSelectDate = (date: Date) => {
    const dayjsDate = dayjs.tz(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'America/Chicago');

    const month = dayjsDate.month();
    if (month !== currentCalendarMonth) return;

    setDayModalDate(dayjsDate);
  };

  const startMonthDateRef = useRef<Dayjs>(null);
  const endMonthDateRef = useRef<Dayjs>(null);

  const onMonthChange = (date: Date, force: boolean = false) => {
    const djs = dayjs.tz(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, 'America/Chicago');
    const month = djs.month();

    if (!force) {
      if (month == currentCalendarMonth) return;
      setCurrentCalendarMonth(month);
    }

    const startLastMonth = djs.subtract(1, 'month').startOf('month');
    const endNextMonth = djs.add(1, 'month').endOf('month');

    startMonthDateRef.current = startLastMonth;
    endMonthDateRef.current = endNextMonth;

    const endOfWeek = djs.startOf('month').endOf('week').add(5, 'week');
    let currentWeekStart = djs.startOf('month').startOf('week');
    let currentWeekEnd = djs.startOf('month').endOf('week');
    const allDataPromises = [];

    while (currentWeekEnd.isSameOrBefore(endOfWeek)) {
      const dataPromise = statisticsApi.getWeekly(
        new GetWeeklyStatisticsRequest({
          startTradeDay: currentWeekStart,
          endTradeDay: currentWeekEnd,
          tradingAccountId: tradingAccountId
        })
      );
      allDataPromises.push(dataPromise);
      currentWeekStart = currentWeekStart.add(7, 'day');
      currentWeekEnd = currentWeekEnd.add(7, 'day');
    }
    Promise.all(allDataPromises).then((results) => {
      setWeeklyStatistics(results.flat());
    });

    statisticsApi
      .getDayStats(
        new GetDailyStatisticsRequest({
          startTradeDay: startLastMonth,
          endTradeDay: endNextMonth,
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setCalendarData(data);
      });
    statisticsApi
      .getMonthly(
        new GetMonthlyStatisticsRequest({
          startTradeDay: djs.startOf('month'),
          endTradeDay: djs.endOf('month'),
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setMonthlyStatistics(data);
      });
    journalLogApi
      .getForRange(
        new GetJournalLogRangeRequest({
          startDate: startLastMonth,
          endDate: endNextMonth,
          tradingAccountId: tradingAccountId
        })
      )
      .then((data) => {
        setJournalLogs(data);
      });
  };


  const calendar = useMemo(() => {
    return <Calendar renderCell={renderCell} cellClassName={getCellClass} onChange={onSelectDate} onMonthChange={onMonthChange} />;
  }, [renderCell, getCellClass, onSelectDate, onMonthChange, calendarData, journalLogs, tradingAccountId, currentCalendarMonth]);

  const [shareState, setShareState] = useState(activeTradingAccount?.isSharing ?? false);

  const onSwitchShare = () => {
    if (!activeTradingAccount) return;

    statisticsApi
      .setAccountSharing(new SetAccountSharingRequest({ tradingAccountId: activeTradingAccount.accountId, sharing: !activeTradingAccount.isSharing }))
      .then(() => {
        activeTradingAccount.isSharing = !activeTradingAccount.isSharing;
        setShareState(activeTradingAccount.isSharing);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const renderSymbol = (symbolId: string) => {
    const symbolData = getContractByProductId(symbolId);
    return symbolData.productName;
  };

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);

    if (minutes === 0) {
      return `${remainingSeconds} sec`;
    } else if (remainingSeconds === 0) {
      return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
    } else {
      return `${minutes} min ${remainingSeconds} sec`;
    }
  };

  // In the PerformanceCard component for Average Trade Duration:
  <PerformanceCard title='Average Trade Duration' value={formatDuration(averageTradeDuration)} content={<Box></Box>} help='The average duration of trades in minutes and seconds.' />;

  const [shareButtonText, setShareButtonText] = useState('Copy Link');
  const onClickCopyShare = () => {
    navigator.clipboard.writeText(`${window.location.origin}/share/stats?share=${activeTradingAccount.accountId}`);
    setShareButtonText('Copied!');
    setTimeout(() => {
      setShareButtonText('Copy Link');
    }, 2000);
  };

  if (shareBlocked) {
    return (
      <Typography sx={{ textAlign: 'center', margin: '2em', color: 'white' }} variant='h3'>
        This account is not currently sharing data.
      </Typography>
    );
  }

  const handleJournalRemoved = (journal: JournalLog) => {
    setLastJournalUpdate(dayjs());
  };

  const handleJournalAdded = (journal: JournalLog) => {
    setLastJournalUpdate(dayjs());
  };

  return (
    <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
      <div style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography color='white' sx={{ margin: '1em', fontWeight: 600, fontSize: '1.5rem' }}>
          {accountName}
        </Typography>
        <Box padding={2} display='flex' flexDirection='row' justifyContent='space-between'>
          <Box display="flex" flexDirection="row" alignItems="center">
            <LocalizationProvider dateLibInstance={dayjs} dateAdapter={AdapterDayjs}>
              <DateRangePicker label='Date Range' slots={{ field: SingleInputDateRangeField }} value={dateFilter} onChange={(val) => setDateFilter(val)} timezone='America/Chicago' sx={{ minWidth: '300px' }} />
            </LocalizationProvider>

            <Box display="flex" gap='0.5em' marginLeft='0.5em' > {/* Adds space between the buttons */}
              <Button
                onClick={setDayRange}
                style={{ background: selectedRange === 'day' ? '#4d4c51' : '#2a292f' }}
                className={selectedRange === 'day' ? 'selected' : ''}>
                Today
              </Button>
              <Button onClick={setWeekRange}
                style={{ background: selectedRange === 'week' ? '#4d4c51' : '#2a292f' }}
                className={selectedRange === 'week' ? 'selected' : ''}>
                Last Week
              </Button>
              <Button onClick={setMonthRange}
                style={{ background: selectedRange === 'month' ? '#4d4c51' : '#2a292f' }}
                className={selectedRange === 'month' ? 'selected' : ''}>
                Last Month
              </Button>
            </Box>
          </Box>
          <div style={{ zIndex: 99999 }}>
            {activeTradingAccount && activeTradingAccount.accountId == tradingAccountId ? (
              <>
                <FormControlLabel control={<Switch onChange={onSwitchShare} checked={activeTradingAccount.isSharing} />} label='Share' />
                {/* If it's not your account you are looking at, do not show these controls */}
                {activeTradingAccount.isSharing ? (
                  <Button onClick={onClickCopyShare} style={{ background: '#434249' }}>
                    {shareButtonText}
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <CoachTip />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard title='Total P&L' value={getDollarTextFromValue(totalProfitAndLoss)} content={<Box></Box>} help='The total realized profit and loss.' />
          <PerformanceCard title='Trade Win %' value={tradeWinPercentText} content={halfPie(tradeWinPieData)} help='Percent of winning trades vs losing trades.' />
          <PerformanceCard title='Avg Win / Avg Loss' value={averageWinLossText} content={winLossLine(winLossStatistics.averageWin, winLossStatistics.averageLoss)} help='Value greater than 1 indicates winning trades profit higher than losing trades loss.' />
        </Box>
        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard title='Day Win %' value={dayWinPercentText} content={halfPie(dayWinPieData)} help='The percentage of winning trades today.' />
          <PerformanceCard title='Profit Factor' value={profitFactorText} content={fullPie(profitFactorData)} help='Ratio of total profits divided by total losses.' />
          <PerformanceCard title='Best Day % of Total Profit' value={`${bestDayPercentOfTotalProfit.toFixed(2)}%`} content={<Box></Box>} help='Percentage of total profit contributed by the most profitable day.' />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <LargePerformanceCard width='100%' title='Daily Account Balance' content={dailyBalanceChart(dailyBalanceData, dailyBalanceData.minBalance)} help='The daily (closed) account balance on the account.' />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PersonalizedTip winRate={tradeWinPercent} totalPnl={totalProfitAndLoss} trades={trades} dayStatistics={dayStatistics} profitFactor={profitFactor} winLossStatistics={winLossStatistics} />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard
            title='Most Active Day'
            value={mostActiveDay.day}
            content={
              <Typography color='text.secondary'>
                {mostActiveDay.activeDays} active days
                <br />
                {mostActiveDay.totalTrades} total trades
                <br />
                {mostActiveDay.avgTrades.toFixed(2)} avg trades/day
              </Typography>
            }
            help='The day of the week with the most frequent trading activity.'
          />
          <PerformanceCard title='Most Profitable Day' value={mostProfitableDay.day} content={getDollarTextFromValue(mostProfitableDay.profit)} help='The day of the week with the highest total profit.' />
          <PerformanceCard title='Least Profitable Day' value={leastProfitableDay.day} content={getDollarTextFromValue(leastProfitableDay.profit)} help='The day of the week with the lowest total profit (or highest loss).' />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard title='Total Number of Trades' value={totalTrades.toString()} content={<Box></Box>} help='The total number of trades executed during the selected period.' />
          <PerformanceCard title='Total Number of Lots Traded' value={totalLots.toString()} content={<Box></Box>} help='The total number of lots traded during the selected period.' />
          <PerformanceCard title='Average Trade Duration' value={formatDuration(averageTradeDuration)} content={<Box></Box>} help='The average duration of trades in seconds.' />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard title='Avg Winning Trade' value={getDollarTextFromValue(winLossStatistics.averageWin)} content={<Box></Box>} help='Average profit of winning trades.' />
          <PerformanceCard title='Avg Losing Trade' value={getDollarTextFromValue(winLossStatistics.averageLoss)} content={<Box></Box>} help='Average loss of losing trades.' />
          <PerformanceCard title='Trade Direction %' value={longShortPercentText} content={fullPie(longShortData)} help='The percentage of long trades vs short trades.' />
        </Box>
        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <PerformanceCard
            title='Best Trade'
            value={bestTrade ? getDollarTextFromValue(bestTrade.pnL - bestTrade.fees) : 'N/A'}
            content={
              bestTrade ? (
                <Typography color='text.secondary'>
                  {bestTrade.positionSize > 0 ? `Short ${bestTrade.positionSize} ${renderSymbol(bestTrade.symbolId)} @ ${bestTrade.entryPrice}` : `Long ${Math.abs(bestTrade.positionSize)} ${renderSymbol(bestTrade.symbolId)} @ ${bestTrade.entryPrice}`}
                  <br />
                  Exited @ {bestTrade.exitPrice}
                  <br />
                  {dayjs(bestTrade.createdAt).format('MM/DD/YYYY HH:mm:ss')}
                </Typography>
              ) : (
                <Box></Box>
              )
            }
            help='Details of the most profitable trade.'
          />

          <PerformanceCard
            title='Worst Trade'
            value={worstTrade ? getDollarTextFromValue(worstTrade.pnL - worstTrade.fees) : 'N/A'}
            content={
              worstTrade ? (
                <Typography color='text.secondary'>
                  {worstTrade.positionSize > 0 ? `Short ${worstTrade.positionSize} ${renderSymbol(worstTrade.symbolId)} @ ${worstTrade.entryPrice}` : `Long ${Math.abs(worstTrade.positionSize)} ${renderSymbol(worstTrade.symbolId)} @ ${worstTrade.entryPrice}`}
                  <br />
                  Exited @ {worstTrade.exitPrice}
                  <br />
                  {dayjs(worstTrade.createdAt).format('MM/DD/YYYY HH:mm:ss')}
                </Typography>
              ) : (
                <Box></Box>
              )
            }
            help='Details of the least profitable trade.'
          />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <LargePerformanceCard width='50%' title='Daily Net Cumulative P&L' content={profitLineChart(dailyPnlCumulativeData)} help='The overall net profit and loss realized across all trading days.' />
          <LargePerformanceCard width='50%' title='Net Daily P&L' content={profitBarChart(dailyPnlData)} help='The overall net profit and loss realized per each trading day.' />
        </Box>

        <Box maxWidth='100vw' display='flex' flexDirection='row' gap='1em' padding='1em' flexWrap='wrap'>
          <LargePerformanceCard height={470} width='50%' title='Trade Duration Analysis' content={<TradeDurationChart trades={trades} />} help='Distribution of trades based on their duration.' />
          <LargePerformanceCard height={470} width='50%' title='Win Rate Analysis' content={<WinRateByDurationChart trades={trades} />} help='Win rate of trades based on their duration.' />
        </Box>

        <Box bgcolor='lightBg' margin={1.5} marginTop={1} borderRadius='1em'>
          <Box display="flex" justifyContent="center" alignItems="center" bgcolor="lightBg" borderRadius="1em" marginTop="1em">
            <Typography color='white' sx={{ margin: '1em', fontWeight: 600, fontSize: '1.5rem' }}>Monthly P/L: {getDollarTextFromValue(monthlyData.totalNetPnL)}</Typography>
          </Box>
          <CustomProvider theme='dark'>{calendar}</CustomProvider>
        </Box>

        <Box bgcolor='lightBg' margin={1.5} marginBottom={3} borderRadius='1em' maxWidth='100vw' display='flex' flexDirection='column' gap='1em' padding='1em' flexWrap='wrap'>
          <CardTitle>
            Trades
            <HelpMessage message='Your recent trades' />
          </CardTitle>

          <PerformanceTradesGrid onJournalRemoved={handleJournalRemoved} onJournalAdded={handleJournalAdded} lastJournalUpdate={lastJournalUpdate} startDate={dateFilter[0]} endDate={dateFilter[1]} accountId={tradingAccountId} trades={trades} />
        </Box>
        <br />
        <br />

        {dayModalDate ? (
          <DayPerformanceModal onJournalRemoved={handleJournalRemoved} onJournalAdded={handleJournalAdded} accountId={tradingAccountId} show={dayModalDate !== undefined} date={dayModalDate} onHide={() => setDayModalDate(undefined)}></DayPerformanceModal>
        ) : null}

        {dayModalDate && dayModalDate.day() === 6 ? (
          <WeekPerformanceModal
            accountId={tradingAccountId}
            startDate={dayjs(dayModalDate).startOf('week')}
            endDate={dayjs(dayModalDate).endOf('week')}
            show={dayModalDate !== undefined}
            onHide={() => setDayModalDate(undefined)}
            onJournalAdded={handleJournalAdded}
            onJournalRemoved={handleJournalRemoved}>
            </WeekPerformanceModal>
        ) : null}
      </div>
    </div>
  );
};

export default PerformanceView;
