import { useVisibility } from '@/contexts/VisibilityContext';
import React, { useEffect, useRef, useState, FC, useMemo, useCallback } from 'react';

interface KeyboardListenerProps {
  enabled: boolean;
  bindings: KeyBinding[];
}

type KeyBinding = {
  keys: string[];
  onKeyboard: () => void;
};

const KeyboardListener = ({ bindings, enabled }: KeyboardListenerProps) => {
  const { visible } = useVisibility();
  const keysPressed = useRef(new Set<string>());

  // for input tracking
  const isInputFocused = useRef(false);
  const activeElement = useRef<Element | null>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isInputFocused.current) return;

      try {
        var target = e.target as any;

        if (target && target.tagName && (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA')) {
          return;
        }
      } catch (e) {}

      if (e.repeat) {
        return;
      }

      if (!e.getModifierState('Alt') && keysPressed.current.has('ALT')) {
        keysPressed.current.delete('ALT');
      }

      if (!e.getModifierState('Meta') && keysPressed.current.has('META')) {
        keysPressed.current.delete('META');
      }

      keysPressed.current.add(e.key.toUpperCase());

      for (const { keys, onKeyboard } of bindings) {
        if (!keys || keys.length === 0) continue;

        const keysPressedArr = [...keysPressed.current];

        if (keys.length > 0 && keys.length === keysPressed.current.size) {
          let match = true;
          for (let i = 0; i < keys.length; i++) {
            if (keysPressedArr[i].toUpperCase() !== keys[i].toUpperCase()) {
              match = false;
              break;
            }
          }

          if (match) {
            e.preventDefault();
            onKeyboard();
          }
        } else {
          // Check partial match for cancelling event
          // is every key *so far* correct?
          let partialMatch = true;
          for (let i = 0; i < keysPressedArr.length; i++) {
            if (keysPressedArr[i].toUpperCase() !== keys[i].toUpperCase()) {
              partialMatch = false;
              break;
            }
          }

          if (partialMatch) {
            e.preventDefault();
          }
        }
      }
    },
    [bindings]
  );

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    keysPressed.current.delete(e.key.toUpperCase());
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const options = { capture: true };

    window.addEventListener('keydown', handleKeyDown, options);
    window.addEventListener('keyup', handleKeyUp, options);

    for (let i = 0; i < window.frames.length; i++) {
      try {
        window.frames[i].addEventListener('keydown', handleKeyDown, options);
        window.frames[i].addEventListener('keyup', handleKeyUp, options);
      } catch (e) {
        console.error('unable to listen to keyup/down for hotkeys', e);
      }
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown, options);
      window.removeEventListener('keyup', handleKeyUp, options);

      for (let i = 0; i < window.frames.length; i++) {
        try {
          window.frames[i].removeEventListener('keydown', handleKeyDown, options);
          window.frames[i].removeEventListener('keyup', handleKeyUp, options);
        } catch (e) {
          console.error('unable to remove keyup/down for hotkeys', e);
        }
      }
    };
  }, [handleKeyDown, handleKeyUp, enabled]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const updateFocusState = () => {
      const active = document.activeElement;
      keysPressed.current.clear();
      if (active && (active.tagName === 'INPUT' || active.tagName === 'TEXTAREA')) {
        isInputFocused.current = true;
        activeElement.current = active;
      } else {
        isInputFocused.current = false;
        activeElement.current = null;
      }
    };

    document.addEventListener('focusin', updateFocusState);
    document.addEventListener('focusout', updateFocusState);

    return () => {
      document.removeEventListener('focusin', updateFocusState);
      document.removeEventListener('focusout', updateFocusState);
    };
  }, [enabled]);

  const onHide = () => {
    // clear keys pressed
    keysPressed.current.clear();
  };

  const onShow = () => {
    keysPressed.current.clear();
  };

  useEffect(() => {
    if (visible) {
      onShow();
    } else {
      onHide();
    }
  }, [visible]);

  return <></>;
};

export default KeyboardListener;
