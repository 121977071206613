import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCqg } from '@contexts/CqgContext';
import { QuoteData } from '../../../../models/quoteData';
import styles from './quotes.module.scss';
import { useSymbol } from '../../../../contexts/SymbolContext';

import { DataGridPro, GridColDef, GridValueGetterParams, GridFooterContainer, useGridApiRef } from '@mui/x-data-grid-pro';

import { ContractPriceSpan, NumberSpan, RedGreenPercentSpan } from '@components/numbers';
import { Box, Link } from '@mui/material';

import _ from 'lodash';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import Button, { ButtonType } from '@/components/topstep/button';
import ContractSelector from '@/components/topstep/contractSelector';
import { SymbolMetadata, UserContractModel } from '@/api/userApi';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';

const defaultSymbols = ['F.US.EP', 'F.US.ENQ'];

interface QuotesProps {
  changed: () => void;
  tabData: {
    gridState: GridInitialStatePro;
    initialized?: boolean;
    savedSymbols: string[] | undefined;
  };
}

const Quotes: React.FC<QuotesProps> = (props): JSX.Element => {
  const { getContractByProductId, getDefaultContract } = useSymbol();
  const { subscribeQuotesForSymbol, unsubscribeQuotesForSymbol } = useCqg();
  const gridRef = useGridApiRef();

  const [symbolSelectorOpen, setSymbolSelectorOpen] = useState(false);
  const [contractToAdd, setContractToAdd] = useState<UserContractModel>(getDefaultContract());

  const [shownSymbols, setShownSymbols] = useState<string[]>(props.tabData.initialized !== true ? defaultSymbols : props.tabData.savedSymbols);

  const subs = useRef<Map<string, number>>(new Map());
  const { isMobile } = useDeviceContext();

  useEffect(() => {
    props.tabData.initialized = true;
    props.tabData.savedSymbols = shownSymbols;
    props.changed();
  }, [shownSymbols]);

  useEffect(() => {
    for (const symbol of shownSymbols) {
      subs.current.set(symbol, subscribeQuotesForSymbol(symbol, onSymbolData));
    }

    return () => {
      for (const [k, v] of subs.current) {
        unsubscribeQuotesForSymbol(k, v);
      }
    };
  }, []);

  const columns: GridColDef<QuoteData>[] = useMemo(() => {
    const getMetadata = (params: GridValueGetterParams<QuoteData>) => {
      if (!params.row.metadata) {
        params.row.metadata = getContractByProductId(params.row.symbol);
      }
      return params.row.metadata;
    };

    return [
      {
        field: 'symbol',
        headerName: 'Symbol',
        sortable: true,
        filterable: true,
        flex: 2,
        minWidth: 120,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          if (!metadata) {
            return params.row.symbolName;
          }
          return `${metadata?.contractName ?? params.row.symbolName}`;
        }
      },
      {
        field: 'lastPrice',
        headerName: 'Last',
        sortable: true,
        type: 'number',
        flex: 1,
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const contract = getMetadata(params);
          return (
            <ContractPriceSpan contract={contract} showGreen={params.row.changePercent > 0} showRed={params.row.changePercent < 0}>
              {params.row.lastPrice}
            </ContractPriceSpan>
          );
        },
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'change',
        headerName: 'Change',
        sortable: true,
        filterable: true,
        flex: 1,
        minWidth: isMobile ? 100 : 60,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return (
            <ContractPriceSpan contract={metadata} showGreen={params.row.change > 0} showRed={params.row.change < 0}>
              {params.row.change}
            </ContractPriceSpan>
          );
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'changePercent',
        headerName: '% Chg',
        sortable: true,
        filterable: true,
        flex: 1,
        type: 'number',
        minWidth: isMobile ? 100 : 60,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return (
            <RedGreenPercentSpan decimalPlaces={metadata?.pointValue} colorCheck={params.row.changePercent}>
              {params.row.changePercent}
            </RedGreenPercentSpan>
          );
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'open',
        headerName: 'Open',
        filterable: true,
        sortable: true,
        flex: 1,
        type: 'number',
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return <ContractPriceSpan contract={metadata}>{params.row.open}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'bestBid',
        headerName: 'Bid',
        sortable: true,
        type: 'number',
        flex: 1,
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return <ContractPriceSpan contract={metadata}>{params.row.bestBid}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'bestAsk',
        headerName: 'Ask',
        sortable: true,
        flex: 1,
        type: 'number',
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return <ContractPriceSpan contract={metadata}>{params.row.bestAsk}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'high',
        headerName: 'High',
        sortable: true,
        flex: 1,
        type: 'number',
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return <ContractPriceSpan contract={metadata}>{params.row.high}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'low',
        headerName: 'Low',
        sortable: true,
        flex: 1,
        type: 'number',
        minWidth: 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          const metadata = getMetadata(params);
          return <ContractPriceSpan contract={metadata}>{params.row.low}</ContractPriceSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'volume',
        headerName: 'Volume',
        sortable: true,
        flex: 1,
        type: 'number',
        minWidth: isMobile ? 110 : 90,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          return <NumberSpan>{params.row.volume}</NumberSpan>;
        },
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'remove',
        headerName: 'Remove',
        headerClassName: styles.header,
        type: 'actions',
        sortable: false,
        renderCell: (params: GridValueGetterParams<QuoteData>) => {
          return (
            <StyledButton
              color='error'
              variant='text'
              onClick={() => {
                removeContract(params.row.symbol);
              }}
              className={styles.removeButton}
            >
              <CloseIcon />
            </StyledButton>
          );
        }
      }
    ];
  }, [getContractByProductId, isMobile]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Quotes
      </Box>
    );
  }, []);

  const initialState: GridInitialStatePro = useMemo(() => {
    return (
      props?.tabData?.gridState ?? {
        pagination: {
          paginationModel: { page: 0, pageSize: 20 }
        }
      }
    );
  }, []);

  const backdropStyle = useMemo(() => {
    return {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  }, []);

  const selectorBoxStyle = useMemo(() => {
    return {
      width: '50%',
      minWidth: '10em',
      maxWidth: '30em',
      backgroundColor: '#2a292f',
      borderRadius: '5px'
    };
  }, []);

  const onSymbolData = useCallback((quote: QuoteData) => {
    if (!subs.current.has(quote.symbol)) return;
    gridRef.current.updateRows([quote]);
  }, []);

  const addContract = useCallback((symbol: string) => {
    setShownSymbols((prev) => {
      return [...prev.filter((y) => y != symbol), symbol];
    });

    if (subs.current.has(symbol)) return;
    subs.current.set(symbol, subscribeQuotesForSymbol(symbol, onSymbolData));
  }, []);

  const removeContract = useCallback((symbol: string) => {
    setShownSymbols((prev) => {
      return prev.filter((y) => y != symbol);
    });

    const subId = subs.current.get(symbol);
    if (subId !== undefined) {
      unsubscribeQuotesForSymbol(symbol, subId);
      subs.current.delete(symbol);
      gridRef.current.updateRows([{ symbol: symbol, _action: 'delete' }]);
    }
  }, []);

  const onAddContractClick = useCallback(() => {
    addContract(contractToAdd.productId);
    setSymbolSelectorOpen(false);
  }, [contractToAdd, setContractToAdd]);

  const symbolSelector = useMemo(() => {
    return (
      <Box sx={backdropStyle}>
        <Box sx={selectorBoxStyle}>
          <h4 className={styles.addTitle}>Add Symbol to Quotes</h4>
          <ContractSelector contract={contractToAdd} setContract={setContractToAdd} linkedColor={null} />
          <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '0.5em', width: '100%', gap: '1em' }}>
            <StyledButton sx={{ flex: 1, margin: '0.5em' }} onClick={() => setSymbolSelectorOpen(false)} color='neutral'>
              Cancel
            </StyledButton>
            <StyledButton sx={{ flex: 1, margin: '0.5em' }} onClick={onAddContractClick} color='success'>
              Add Symbol
            </StyledButton>
          </Box>
        </Box>
      </Box>
    );
  }, [contractToAdd, onAddContractClick]);

  const toolbar = useMemo(() => {
    return (
      <GridFooterContainer sx={{ height: '2.4em', minHeight: '2.4em' }}>
        <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
          <StyledButton
            sx={{ padding: '0', margin: '0.1em ' }}
            color='neutral'
            size='small'
            onClick={() => {
              setSymbolSelectorOpen(true);
            }}
          >
            Add Symbol
          </StyledButton>
        </Box>
      </GridFooterContainer>
    );
  }, []);

  const grid = useMemo(() => {
    return (
      <DataGridPro
        getRowId={(x) => x.symbol}
        rowHeight={34}
        rowPositionsDebounceMs={500}
        columnHeaderHeight={34}
        autoHeight={false}
        rows={[]}
        density='compact'
        columns={columns}
        apiRef={gridRef}
        slots={{
          footer: () => toolbar
          //   noRowsOverlay: () => noRows
        }}
        initialState={initialState}
        onStateChange={() => {
          const newState = gridRef.current.exportState();
          if (_.isEqual(newState, props.tabData.gridState)) return;

          props.tabData.gridState = newState;
          props.changed();
        }}
        pageSizeOptions={[20, 50]}
      ></DataGridPro>
    );
  }, []);

  return useMemo(
    () => (
      <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', position: 'relative' }}>
        {grid}
        {symbolSelectorOpen && <>{symbolSelector}</>}
      </Box>
    ),
    [columns, symbolSelectorOpen, setSymbolSelectorOpen, contractToAdd, setContractToAdd]
  );
};

export default Quotes;
