import React, { useCallback, useMemo, useState } from 'react';
import { useOrders } from '../../../../contexts/OrdersContext';
import { useSymbol } from '@contexts/SymbolContext';
import { FormLabel, Typography } from '@mui/material';
import { ContractPriceSpan, CurrencySpan, DateSpan, NumberSpan } from '@components/numbers';
import { Box } from '@mui/material';
import { StyledButton } from '@components/styledComponents';
import { DataGridPro, GridColDef, GridRowCount, GridValueGetterParams, useGridApiRef, GridFooterContainer } from '@mui/x-data-grid-pro';
import { TradeModel, UserExportTradeRequest } from '../../../../api/userApi';
import styles from './trades.module.scss';
import _ from 'lodash';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { TsModal } from '@/components/modal';
import { useApi } from '@/contexts/ApiContext';
import { downloadBlob } from '@/helpers/downloadHelper';
import { logException } from '@/helpers/exceptionHelper';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import Heading, { HeadingSize } from '@/components/topstep/heading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { TradingEnvironment } from '@/data/tradingEnvironment';
import classNames from 'classnames';
import PerformanceTradesGrid from '@/views/performance/performanceTradesGrid';

interface TradesProps {
  changed: () => void;
  tabData: {
    gridState: GridInitialStatePro;
  };
}

const Trades: React.FC<TradesProps> = (props): JSX.Element => {
  const { isMobile } = useDeviceContext();
  const { getContractByProductId } = useSymbol();
  const { trades } = useOrders();

  const gridRef = useGridApiRef();
  const { tradeApi } = useApi();
  const [showExportModal, setShowExportModal] = useState(false);
  const { activeTradingAccount, tradingEnvironment } = useTradingAccount();
  const [exportDateStart, setExportDateStart] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'day').startOf('day'));
  const [exportDateEnd, setExportDateEnd] = useState<dayjs.Dayjs>(dayjs().add(2, 'day').startOf('day'));

  const exportData = useCallback(() => {
    tradeApi
      .userExportCsv(new UserExportTradeRequest({ start: exportDateStart, end: exportDateEnd, tradingAccountId: activeTradingAccount.accountId, timeZone: dayjs.tz.guess() }))
      .then((res) => {
        downloadBlob(res.data, 'trades_export.csv', 'text/csv');
        setShowExportModal(false);
        toast('Orders exported successfully. Check your downloads', { type: 'success' });
      })
      .catch((e) => {
        logException(e, 'Failed to export trades');
        toast('Failed to export trades', { type: 'error' });
      });
  }, [exportDateStart, exportDateEnd, activeTradingAccount?.accountId]);

  const columns: GridColDef<TradeModel>[] = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
        filterable: true,
        flex: 1,
        headerClassName: styles.header,
        minWidth: isMobile ? 75 : undefined
      },
      {
        field: 'symbolName',
        headerName: 'Symbol',
        sortable: true,
        filterable: true,
        flex: 0.5,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          const symbolData = getContractByProductId(params.row.symbolId);
          if (!symbolData) return <span>{params.row.symbolId}</span>;
          return <span>{symbolData.productName}</span>;
        },
        valueGetter: (params: GridValueGetterParams<TradeModel>) => {
          const symbolData = getContractByProductId(params.row.symbolId);
          if (!symbolData) return params.row.symbolId;
          return symbolData.productName;
        },
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'positionSize',
        headerName: 'Size',
        sortable: true,
        type: 'number',
        flex: 0.5,
        headerClassName: isMobile ? styles.mobileHeader : styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          return <NumberSpan>{Math.abs(params.row.positionSize)}</NumberSpan>;
        },
        valueGetter: (params: GridValueGetterParams<TradeModel>) => {
          return Math.abs(params.row.positionSize);
        },
        minWidth: isMobile ? 70 : undefined
      },
      {
        field: 'entryTime',
        headerName: 'Entry Time',
        sortable: true,
        filterable: true,
        type: 'number',
        flex: 2,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          return <DateSpan>{params.row.createdAt}</DateSpan>;
        },
        valueGetter: (params: GridValueGetterParams<TradeModel>) => {
          return params.row.createdAt.unix();
        },
        minWidth: isMobile ? 150 : undefined
      },
      {
        field: 'exitedAt',
        headerName: 'Exit Time',
        sortable: true,
        filterable: true,
        type: 'number',
        flex: 2,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          return <DateSpan>{params.row.exitedAt}</DateSpan>;
        },
        valueGetter: (params: GridValueGetterParams<TradeModel>) => {
          return params.row.exitedAt.unix();
        },
        minWidth: isMobile ? 150 : undefined
      },
      {
        field: 'entryPrice',
        headerName: 'Entry Price',
        sortable: true,
        filterable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          const metadata = getContractByProductId(params.row.symbolId);
          if (!metadata) return <span>{params.row.entryPrice}</span>;
          return <ContractPriceSpan contract={metadata}>{params.row.entryPrice}</ContractPriceSpan>;
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: isMobile ? 120 : undefined
      },
      {
        field: 'exitPrice',
        headerName: 'Exit Price',
        filterable: true,
        sortable: true,
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          const metadata = getContractByProductId(params.row.symbolId);
          if (!metadata) return <span>{params.row.exitPrice}</span>;
          return <ContractPriceSpan contract={metadata}>{params.row.exitPrice}</ContractPriceSpan>;
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: isMobile ? 120 : undefined
      },
      {
        field: 'pnL',
        headerName: 'P&L',
        sortable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          return <CurrencySpan showColors>{params.row.pnL}</CurrencySpan>;
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'fees',
        headerName: 'Fees',
        sortable: true,
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          return <CurrencySpan>{params.row.fees}</CurrencySpan>;
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'direction',
        headerName: 'Direction',
        sortable: true,
        filterable: true,
        flex: 0.5,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<TradeModel>) => {
          if (params.row.exitedAt.unix() != 0) {
            return params.row.positionSize < 0 ? 'Long' : 'Short';
          }
        },
        renderCell: (params: GridValueGetterParams<TradeModel>) => {
          if (params.row.exitedAt.unix() != 0) {
            return params.row.positionSize < 0 ? 'Long' : 'Short';
          }
        },
        minWidth: isMobile ? 100 : undefined
      }
    ];
  }, [getContractByProductId, isMobile]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Trades
      </Box>
    );
  }, []);

  const initialState: GridInitialStatePro = useMemo(() => {
    return (
      props?.tabData?.gridState ?? {
        sorting: {
          sortModel: [
            {
              field: 'entryTime',
              sort: 'desc'
            }
          ]
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 20 }
        }
      }
    );
  }, []);

  const [page, setPage] = useState(0);

  const handleSetPage = (newPage: number) => {
    gridRef.current.setPage(newPage);
    setPage(newPage);
  };

  const toolbar = useMemo(() => {
    return (
      <GridFooterContainer sx={{ height: '2.4em', minHeight: '2.4em' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%' }}>
          <StyledButton
            sx={{ padding: '0', margin: '0.1em ' }}
            color='neutral'
            size='small'
            onClick={() => {
              setShowExportModal(true);
            }}
          >
            Export
          </StyledButton>
          <Typography variant='body2' sx={{ marginLeft: '1em', marginTop: '0.25em' }}>
            Page {page + 1}
          </Typography>
          <GridRowCount rowCount={trades.length} visibleRowCount={gridRef.current.state?.pagination?.paginationModel?.pageSize ?? 20} />

          {/* Next page and previous page icon buttons */}
          <Box>
            <StyledButton sx={{ padding: '0', margin: '0.1em ' }} color='neutral' size='small' onClick={() => handleSetPage(page - 1)} disabled={page == 0}>
              {'<'}
            </StyledButton>
            <StyledButton sx={{ padding: '0', margin: '0.1em ' }} color='neutral' size='small' onClick={() => handleSetPage(page + 1)} disabled={page == Math.floor(trades.length / (gridRef.current?.state?.pagination?.paginationModel?.pageSize ?? 1))}>
              {'>'}
            </StyledButton>
          </Box>
        </Box>
      </GridFooterContainer>
    );
  }, [trades, page]);

  const exportModal = useMemo(() => {
    return (
      <TsModal open={showExportModal} onClose={() => setShowExportModal(false)} title='Export Trades'>
        <Box>
          <Heading style={{ textAlign: 'center', marginBottom: '1em' }} size={HeadingSize.H2}>
            Export Trades
          </Heading>
          <LocalizationProvider dateLibInstance={dayjs} dateAdapter={AdapterDayjs}>
            <DateRangePicker
              defaultValue={[exportDateStart, exportDateEnd]}
              value={[exportDateStart, exportDateEnd]}
              onChange={(e) => {
                setExportDateStart(e[0]);
                setExportDateEnd(e[1]);
              }}
            />
          </LocalizationProvider>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>
            <StyledButton color='neutral' sx={{ margin: '1em' }} onClick={() => setShowExportModal(false)}>
              Cancel
            </StyledButton>
            <StyledButton color='success' sx={{ margin: '1em' }} onClick={exportData}>
              Export
            </StyledButton>
          </Box>
        </Box>
      </TsModal>
    );
  }, [showExportModal, exportDateStart, exportDateEnd, exportData]);

  return useMemo(() => {
    if (tradingEnvironment === TradingEnvironment.Live) {
      return (
        <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', maxHeight: isMobile ? 'calc(100% - 40px)' : undefined }}>
          {isMobile && (
            <div style={{ padding: '0.75em', textAlign: 'center' }}>
              <FormLabel sx={{ fontSize: '1.35em' }}>Trades</FormLabel>
            </div>
          )}
          <div className={classNames(styles.liveEnvironmentContainer)}>
            <span>The Trades component is only available for SIM accounts.</span>
          </div>
        </Box>
      );
    } else {
      return (
        <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', maxHeight: isMobile ? 'calc(100% - 40px)' : undefined }}>
          {isMobile && (
            <div style={{ padding: '0.75em', textAlign: 'center' }}>
              <FormLabel sx={{ fontSize: '1.35em' }}>Trades</FormLabel>
            </div>
          )}
          <PerformanceTradesGrid
            accountId={activeTradingAccount.accountId}
            startDate={dayjs('1970-01-01')}
            endDate={dayjs('2100-01-01')}
            trades={trades}
            height='100%'
            apiRef={gridRef}
            rowHeight={32}
            columnHeaderHeight={32}
            density='compact'
            slots={{
              noRowsOverlay: () => noRows,
              footer: () => toolbar
            }}
            noHumanDates
            initialState={initialState}
            pageSizeOptions={[10, 20, 50]}
            onStateChange={() => {
              const newState = gridRef.current.exportState();
              if (_.isEqual(newState, props.tabData.gridState)) return;
              props.tabData.gridState = gridRef.current.exportState();
              props.changed();
            }}
          />
          {exportModal}
        </Box>
      );
    }
  }, [trades, tradingEnvironment, isMobile, activeTradingAccount?.accountId, noRows, toolbar, initialState, props, exportModal]);
};

export default Trades;
