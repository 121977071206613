import { config } from '@/config';
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { DefaultTsModal, TsModal } from './modal';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button, { ButtonType } from '@/components/topstep/button';

const AutoUpdate = () => {
  const localVersion = config.versionFromFile;
  const [remoteVersion, setRemoteVersion] = useState(localVersion);

  const [modalShown, setModalShown] = useState(localVersion !== remoteVersion);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const swHookedRef = useRef(false);

  const onPostpone = useCallback(() => {
    setModalShown(false);
    setAutoRefresh(false);
    setRemoteVersion(localVersion);
    toast.dismiss('updateToast');
  }, [localVersion]);

  const isPWAInstalled = () => {
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  };

  // Add listener for service worker NEW_VERSION event
  useEffect(() => {
    const interval = setInterval(() => {
      if (swHookedRef.current) {
        clearInterval(interval);
        return;
      }

      console.log('Update Component: Checking for service worker...');

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then((registration) => {
            console.log('Update Component: Service Worker is ready.', registration);
            swHookedRef.current = true;

            if (!isPWAInstalled()) {
              console.log('Update Component: Not a PWA, skipping service worker check.');

              // Let service worker know that this is not a PWA
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready
                  .then((registration) => {
                    // Send NOT_PWA message to service worker
                    registration.active.postMessage({
                      type: 'NOT_PWA'
                    });
                  })
                  .catch((error) => {
                    console.error('Service Worker ready promise failed:', error);
                  });
              }
            } else {
              const url = new URL(window.location.href);

              fetchVersion();

              // Listen for messages from the service worker
              navigator.serviceWorker.addEventListener('message', (event) => {
                // Always listen for version info for debugging
                if (event.data && event.data.type === 'VERSION_INFO') {
                  console.log(`%c${event.data.info}`, 'color: lime');
                }

                // If update=true in url, prevent update loop
                if (url.searchParams.get('update') !== 'true') {
                  if (event.data && event.data.type === 'NEW_VERSION') {
                    console.log('New service worker version detected:', event.data.version);

                    // Reload page with url param ?update=true
                    const url = new URL(window.location.href);
                    url.searchParams.set('update', 'true');

                    // Reload page
                    window.location.href = url.toString();
                  }
                }
              });
            }
          })
          .catch((error) => {
            console.error('Service Worker ready promise failed:', error);
          });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchVersion = () => {
    fetch('/version.json?sw=true', {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then((result) => result.json())
      .then((dataJson) => {
        setRemoteVersion(dataJson.version);
      })
      .catch(() => {
        console.error('Failed to fetch version.json');
      });
  };

  useEffect(() => {
    if (autoRefresh) {
      const timeout = setTimeout(() => {
        window.location.reload();
      }, 3600000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoRefresh]);

  const onModalAccept = () => {
    window.location.reload();
  };

  useEffect(() => {
    const intervalId = setInterval(fetchVersion, 300000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (localVersion !== remoteVersion) {
      setModalShown(true);
      setAutoRefresh(true);

      toast.info(
        <div>
          <p style={{ margin: 2 }}>A new version is available, please refresh your browser.</p>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button buttonType={ButtonType.green} onClick={onModalAccept} style={{ padding: 5 }}>
              Refresh Now
            </Button>
            <Button buttonType={ButtonType.light} onClick={onPostpone} style={{ padding: 5 }}>
              I'll do it later
            </Button>
          </Box>
        </div>,
        {
          autoClose: false,
          closeButton: false,
          toastId: 'updateToast',
          icon: false
        }
      );
    }
  }, [remoteVersion]);

  return null;
};

export default AutoUpdate;
