import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import { NavRoutes } from '../../data/NavRoutes';
import { useApi } from '../../contexts/ApiContext';
import { PasswordResetRequest, PasswordResetResult } from '../../api/userApi';
import Heading, { HeadingSize } from '../../components/topstep/heading';
import UserPage from '@views/user/userPage';
import { Alert, Box } from '@mui/material';
import { logException } from '@/helpers/exceptionHelper';
import { StyledButton } from '@/components/styledComponents';

const ResetPasswordView: React.FC = (): JSX.Element => {
  const [redirect, setRedirect] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { forgotPasswordApi } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState(searchParams.get('code'));
  const [username, setUsername] = useState(searchParams.get('username'));

  useEffect(() => {
    if (redirect) {
      setRedirect('');
      navigate(redirect);
    }
  }, [redirect]);

  const attemptReset = useCallback((values: any) => {
    setLoading(true);
    setError(null);
    forgotPasswordApi
      .post(
        new PasswordResetRequest({
          userName: values.userName,
          token: values.code,
          password: values.password
        })
      )
      .then((res) => {
        switch (res) {
          case PasswordResetResult.InvalidUsername:
            setError('Invalid UserName');
            break;
          case PasswordResetResult.InvalidToken:
            setError('This code has expired. Please restart the password reset process.');
            break;
          case PasswordResetResult.Failed:
            setError('Failed to reset password. Please contact support.');
            break;
          case PasswordResetResult.Success:
            setRedirect(NavRoutes.Login);
            break;
          default:
            setError('Unknown Error: ' + res);
            break;
        }
        setLoading(false);
      })
      .catch((err) => {
        logException(err, 'Failed to reset password');
        setError('API Error');
        setLoading(false);
      });
  }, []);

  return (
    <UserPage>
      <div style={{ textAlign: 'center' }}>
        <Heading size={HeadingSize.H1}>Reset Password</Heading>
      </div>
      <Formik initialValues={{ userName: username, code, password: '' }} onSubmit={(values) => attemptReset(values)}>
        {(props) => (
          <Form>
            <Box style={{ justifyContent: 'center' }}>
              <Heading size={HeadingSize.H4}>Username/Email</Heading>
              <Field component={ReactstrapFormikInput} type='text' name='userName' required className='mb-2' />
              <Heading size={HeadingSize.H4}>Reset Code</Heading>
              <Field component={ReactstrapFormikInput} type='text' name='code' required className='mb-2' />
              <Heading size={HeadingSize.H4}>New Password</Heading>
              <Field component={ReactstrapFormikInput} type='password' name='password' required className='mb-2' />
              {error && (
                <Alert severity='error' style={{ border: '1px solid red', margin: '1em 0' }}>
                  {error}
                </Alert>
              )}
              <div style={{ display: 'flex', marginTop: '1em' }}>
                <StyledButton sx={{ width: '100%' }} type='submit' disabled={loading}>
                  Change Password
                </StyledButton>
              </div>
            </Box>
          </Form>
        )}
      </Formik>
    </UserPage>
  );
};

export default ResetPasswordView;
