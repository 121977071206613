import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface IVisibilityContext {
  visible: boolean;
}

export const VisibilityContext = createContext<IVisibilityContext>({} as IVisibilityContext);
export const useVisibility = () => useContext<IVisibilityContext>(VisibilityContext);

export const VisibilityProvider = ({ children }: { children: React.ReactNode }) => {
  const [visible, setVisible] = useState<boolean>(true);

  const data = useMemo(
    () => ({
      visible
    }),
    [visible]
  );

  const onShow = () => {
    setVisible(true);
  };

  const onHide = () => {
    setVisible(false);
  };

  useEffect(() => {
    let handleVisibilityChange: () => void | null;

    let handleBlur = () => {
        if (visible) {
            onHide();
        }
    };

    let handleFocus = () => {
        if (!visible) {
            onShow();
        }
    };

    // Check if the browser supports the Page Visibility API
    if (typeof document.hidden !== 'undefined') {
      handleVisibilityChange = () => {
        if (document.hidden) {
          onHide();
        } else {
          onShow();
        }
      };

      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (document.hidden === undefined) {
        console.error('Issue hooking Page Visibility API. Browser likely does not support.');
      } else {
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        document.addEventListener('focus', handleFocus);
        document.addEventListener('blur', handleBlur);
      }
    }

    return () => {
      if (handleVisibilityChange) {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('focus', handleFocus);
        document.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  return <VisibilityContext.Provider value={data}>{children}</VisibilityContext.Provider>;
};
