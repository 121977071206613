import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Row } from '../../layout/LayoutComponents';
import { useApi } from '@/contexts/ApiContext';
import { logException } from '@/helpers/exceptionHelper';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { config, TradingPlatforms } from '@/config';

const Item = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: theme.spacing(1),
  border: 'none',
  boxShadow: 'none'
}));

const StrapiAlert: React.FC = ({}): JSX.Element => {
  const [message, setMessage] = React.useState<string>();
  const [bgColor, setBgColor] = React.useState<string>('#9c670b');
  const { strapiApi } = useApi();
  const { isMobile } = useDeviceContext();
  useEffect(() => {
    const key = setInterval(() => {
      strapiApi
        .getAlert()
        .then((res) => {
          if (res?.data) {
            setMessage(res.data.attributes.message);
            if (res.data.attributes.color) setBgColor(res.data.attributes.color);
          } else {
            setMessage(null);
            setBgColor('#9c670b');
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }, 30000);

    return () => clearInterval(key);
  }, []);

  if (message && config.platform == TradingPlatforms.TopstepX) {
    return (
      <Row justifyContent='center' gap='.5em' width='100%' style={{ background: bgColor }}>
        <div style={{ fontSize: isMobile ? '1.5em' : undefined }}>{message}</div>
      </Row>
    );
  }
};

export default StrapiAlert;
