import { IPositionModel, OrderModel, OrderStatus, OrderType, SymbolMetadata } from '@/api/userApi';
import { IChartWidgetApi, IExecutionLineAdapter, ResolutionString } from '@/charting_library/charting_library';
import { IChartingLibraryWidget, IOrderLineAdapter } from '@/charting_library/charting_library';
import { calculatePnl, useOrders } from '@/contexts/OrdersContext';
import { useSettings } from '@/contexts/SettingsContext';
import { useSymbol } from '@/contexts/SymbolContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { chartPlotSide } from '@/data/enumTypeMaps';
import { roundToTickSize } from '@/helpers/decimalHelper';
import { logException } from '@/helpers/exceptionHelper';
import { formatPrice } from '@/helpers/formatter';
import { ChartGroup, ChartInstanceData, LEFT_PLOT_SIDE, ORDER_LEFT_PLOT_SIDE, ORDER_RIGHT_PLOT_SIDE, RIGHT_PLOT_SIDE } from '@/views/trader/components/charts/chartTypes';
import zIndex from '@mui/material/styles/zIndex';
import { color } from 'highcharts';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatContractPrice } from '@/helpers/formatter';

interface ChartPositionProps {
  widget: IChartingLibraryWidget;
  charts: ChartGroup[];
}

const ChartExecutions: React.FC<ChartPositionProps> = ({ widget, charts }): JSX.Element => {
  const { orders } = useOrders();
  const filledOrders = useMemo(() => orders.filter((x) => x.status == OrderStatus.Filled), [orders]);
  const { customSettings } = useSettings();
  const [resVersion, setResVersion] = useState(0);

  useEffect(() => {
    let timeout = null;

    const pThis = this;

    for (const grp of charts) {
      for (const chart of grp.charts) {
        const chartApi = chart.chart;

        chart.resChangedEvent = () => {
          chartApi.onDataLoaded().subscribe(chartApi, () => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => setResVersion((x) => x + 1), 1000);
          });
        };
        chartApi.onIntervalChanged().subscribe(chart, chart.resChangedEvent, false);
      }
    }

    return () => {
      for (const grp of charts) {
        grp.charts.forEach((chart) => {
          chart.chart.onIntervalChanged().unsubscribe(chart.chart, chart.resChangedEvent);
        });
      }
    };
  }, [charts]);

  useEffect(() => {
    if (!customSettings.showChartExecutions) return;

    const shapes = [];

    for (const grp of charts) {
      if (!grp.metadata) continue;
      const symbolTrades = filledOrders.filter((y) => y.symbolId == grp.metadata.productId).sort((a, b) => a.filledAt.diff(b.filledAt));

      if (symbolTrades.length == 0) continue;

      for (const chart of grp.charts) {
        chart.chart.refreshMarks();
        let prevBuyShape: IExecutionLineAdapter = null;
        let prevBuyTime = null;

        let prevSellShape: IExecutionLineAdapter = null;
        let prevSellTime = null;

        for (const trade of symbolTrades) {
          try {
            let shapeTime = 0;
            try {
              shapeTime = chart.chart.endOfPeriodToBarTime(trade.filledAt.unix());
            } catch (e) {
              // console.log('Chart: ', chart.chart);
              // console.log('Error converting time to bar time', e, trade.filledAt.unix());
              shapeTime = trade.filledAt.unix();
            }
            if (trade.positionSize > 0) {
              if (shapeTime == prevBuyTime) {
                prevBuyShape.setArrowColor('#ff000000');
                prevBuyShape.setArrowHeight(-4);
                prevBuyShape.setArrowSpacing(0);
                const shape = chart.chart.createExecutionShape({ disableUndo: true });
                if (shape) {
                  shape
                    .setText(`${trade.positionSize > 0 ? '+' : '-'} ${Math.abs(trade.positionSize)} @ ${formatContractPrice(trade.executePrice, grp.metadata)}`)
                    .setTime(shapeTime)
                    .setPrice(trade.executePrice)
                    .setTextColor(customSettings.executionColor || '#FFFFFF')
                    .setFont('12px Arial')
                    .setArrowHeight(14)
                    .setArrowSpacing(8)
                    .setDirection(trade.positionSize > 0 ? 'buy' : 'sell');

                  prevBuyShape = shape;
                  shapes.push(shape);
                }

                continue;
              }
            } else {
              if (shapeTime == prevSellTime) {
                prevSellShape.setArrowColor('#ff000000');
                prevSellShape.setArrowHeight(-4);
                prevSellShape.setArrowSpacing(0);

                const shape = chart.chart.createExecutionShape({ disableUndo: true });
                if (shape) {
                  shape
                    .setText(`${trade.positionSize > 0 ? '+' : '-'} ${Math.abs(trade.positionSize)} @ ${formatContractPrice(trade.executePrice, grp.metadata)}`)
                    .setTime(shapeTime)
                    .setPrice(trade.executePrice)
                    .setTextColor(customSettings.executionColor || '#FFFFFF')
                    .setFont('12px Arial')
                    .setArrowHeight(14)
                    .setArrowSpacing(8)
                    .setDirection(trade.positionSize > 0 ? 'buy' : 'sell');

                  prevSellShape = shape;
                  shapes.push(shape);
                }

                continue;
              }
            }

            const shape = chart.chart.createExecutionShape({ disableUndo: true });

            if (shape) {
              if (trade.positionSize > 0) {
                prevBuyShape = shape;
                prevBuyTime = shapeTime;
              } else {
                prevSellShape = shape;
                prevSellTime = shapeTime;
              }

              shape
                .setText(`${trade.positionSize > 0 ? '+' : '-'} ${Math.abs(trade.positionSize)} @ ${formatContractPrice(trade.executePrice, grp.metadata)}`)
                .setTime(shapeTime)
                .setPrice(trade.executePrice)
                .setTextColor(customSettings.executionColor || '#FFFFFF')
                .setFont('12px Arial')
                .setArrowHeight(14)
                .setArrowSpacing(8)

                .setDirection(trade.positionSize > 0 ? 'buy' : 'sell');

              shapes.push(shape);
            }
          } catch (e) {
            console.log('Error creating execution shape', e);
          }
        }
      }
    }

    return () => {
      for (const shape of shapes) {
        shape.remove();
      }
    };
  }, [filledOrders, charts, customSettings.showChartExecutions, customSettings.executionColor, resVersion]);
  return <> </>;
};

export default React.memo(ChartExecutions);
