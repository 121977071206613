import React from 'react';
import { NotificationModalWithTip } from '@/components/topstep/notificationModal';
import { openInNewTab } from '@/helpers/urlHelper';
import CoachTip from '@/views/trader/components/notifications/CoachTip';
import ReactMarkdown from 'react-markdown';
import { config } from '@/config';
import { CustomViolationModel } from '@/api/userApi';

interface CustomViolationModalProps {
    customViolation: CustomViolationModel;
    onHide: () => void;
}

const CustomViolationModal: React.FC<CustomViolationModalProps> = (props) => {
  return (
    <NotificationModalWithTip
      footer={<CoachTip />}
      header={props.customViolation.title}
      onClickOutline={props.onHide}
      onClickBlue={() => openInNewTab('https://app.topsteptrader.com/dashboard')}
      buttonLabelOutline={'DISMISS THIS NOTIFICATION'}
      buttonLabelBlue={'VISIT MY TOPSTEP TRADING DASHBOARD'}
      platform={config.platform}
    >
      <div>
        <ReactMarkdown>{props.customViolation.message}</ReactMarkdown>
      </div>
    </NotificationModalWithTip>
  );
};

export default React.memo(CustomViolationModal);