import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import MarketDataOrder from '../../../../models/marketDataOrder';
import { DragType, useDragDrop } from '../../../../contexts/DragDropContext';
import { FeOrderResult, OrderPromptType } from '../../../../contexts/OrdersContext';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import { formatNumber } from 'src/helpers/formatter';

interface VolumeCellProps {
    price: number;
}

const VolumeCell: React.FC<VolumeCellProps> = ({ price }): JSX.Element => {
    const { volumes, maxVolume } = useDom();
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(20);

    // check the width of the container to determine when to render the volume profile
    useEffect(() => {
        const element = containerRef.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        resizeObserver.observe(element);

        return () => {
            resizeObserver.unobserve(element);
        };
    }, []);

    const volume = useMemo(() => {
        return volumes.get(price) || 0;
    }, [volumes.get(price), price]);

    return useMemo(() => (<div ref={containerRef}
    >
        {volume > 0 && containerWidth >= 20 && (
            <div
                className={styles.volume}
                style={{ width: ((volume / maxVolume) * 100).toFixed(2) + '%' }}
            >
                &nbsp;
            </div>
        )}
        {volume > 0 && containerWidth >= 20 && <span className={styles.bidAmount}> {formatNumber(volume)}</span>}
    </div>), [volume, maxVolume, containerWidth]);
};

export default React.memo(VolumeCell);
