import React from 'react';

export interface LoadingProps {
  message?: string;
}
const Loading: React.FC<LoadingProps> = (props) => {
  return <div className='loading'>{props.message}</div>;
};

export default Loading;
