import { LayoutBase } from 'rc-dock';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { SocketEvent, useCqg } from './CqgContext';
import TiltData from '../models/tiltData';
export interface LayoutInfo {
  name: string;
  layout: LayoutBase;
}

export interface ITiltContext {
  tiltData: TiltData[];
}

interface IInternalTiltContext extends ITiltContext {
  subscribe;
  unsubscribe;
}

export const TiltContext = React.createContext<IInternalTiltContext>({} as any);

export const useTilt = (): ITiltContext => {
  const ctx = React.useContext<IInternalTiltContext>(TiltContext);
  React.useEffect(() => {
    ctx.subscribe();
    return () => {
      ctx.unsubscribe();
    };
  });
  return ctx;
};

function TiltContextProvider({ children }: any) {
  const { on, off } = useCqg();
  const [tiltData, setTiltData] = useState<TiltData[]>([]);

  const [subscriptions, setSubscriptions] = useState<number>(0);
  const [subscriptionId, setSubscriptionId] = useState<number | null>(null);

  const subscribe = useCallback(() => {
    setSubscriptions((s) => s + 1);
  }, []);

  const unsubscribe = useCallback(() => {
    setSubscriptions((s) => s - 1);
  }, []);

  useEffect(() => {
    if (subscriptions > 0 && !subscriptionId) {
      on(SocketEvent.Tilt, onTiltData);
    } else if (subscriptions == 0 && subscriptionId) {
      off(SocketEvent.Tilt, onTiltData);
      setSubscriptionId(null);
    }
  }, [subscriptions, subscriptionId]);

  const onTiltData = useCallback((quotes: TiltData[]) => {
    setTiltData(quotes);
  }, []);

  const values = useMemo<IInternalTiltContext>(() => {
    return {
      subscribe,
      unsubscribe,
      tiltData: tiltData
    };
  }, [tiltData]);

  return <TiltContext.Provider value={values}>{children}</TiltContext.Provider>;
}

export default TiltContextProvider;
