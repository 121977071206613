import react, { useCallback, useEffect, useRef } from 'react';
import { Howl } from 'howler';
// import marketsClosed from '@assets/sounds/markets_closed.mp3';
// import marketsOpen from '@assets/sounds/markets_open.mp3';
// import orderFilled from '@assets/sounds/order_filled.mp3';
// import orderPlaced from '@assets/sounds/order_placed.mp3';
// import orderRejected from '@assets/sounds/order_rejected.mp3';
import { useSettings } from '@contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';
import config from '@/config';

export enum SoundType {
  OrderFilled,
  OrderRejected,
  MarketsClosed,
  MarketsOpen,
  OrderPlaced
}

const soundMap = new Map<SoundType, number>();

export const useSoundNotifications = () => {
  const settings = useSettings();
  const { isMobile } = useDeviceContext();

  const hasSoundNotifications = useRef(settings.soundNotifications == null || settings.soundNotifications == undefined ? true : settings.soundNotifications);

  const audioPack = useRef(settings.customSettings.audioPack || (config.audioPacks.length && config.audioPacks[0]?.name) || 'default');

  useEffect(() => {
    audioPack.current = settings.customSettings.audioPack || (config.audioPacks.length && config.audioPacks[0]?.name) || 'default';
  }, [settings.customSettings.audioPack]);

  useEffect(() => {
    if (isMobile) {
      hasSoundNotifications.current = false;
      return;
    }
    if (settings.customSettings?.audioAlerts == undefined) {
      hasSoundNotifications.current = settings.soundNotifications == null || settings.soundNotifications == undefined ? true : settings.soundNotifications;
    } else {
      hasSoundNotifications.current = settings.customSettings.audioAlerts;
    }
  }, [settings.soundNotifications, settings.customSettings?.audioAlerts, isMobile]);

  const getAudioUrl = (soundType: SoundType) => {
    const selectedAudioPack = config.audioPacks?.find((pack) => pack.name === audioPack.current);
    const defaultAudioPack = config.audioPacks?.find((pack) => pack.name === 'default');
    let alertName = '';
    switch (soundType) {
      case SoundType.OrderFilled:
        alertName = 'order_filled';
        break;
      case SoundType.OrderRejected:
        alertName = 'order_rejected';
        break;
      case SoundType.MarketsClosed:
        alertName = 'market_closed';
        break;
      case SoundType.MarketsOpen:
        alertName = 'market_open';
        break;
      case SoundType.OrderPlaced:
        alertName = 'order_placed';
        break;
      default:
        break;
    }
    const alert = selectedAudioPack?.alerts.find((a) => a.name === alertName) || defaultAudioPack?.alerts.find((a) => a.name === alertName);
    return alert ? alert.url : null;
  };

  const play = useCallback((soundType: SoundType) => {
    if (!hasSoundNotifications.current) return;

    const now = Date.now();
    const lastSound = soundMap.get(soundType);
    if (lastSound && now - lastSound < 1000) {
      return;
    }
    soundMap.set(soundType, now);
    const audioUrl = getAudioUrl(soundType);
    if (audioUrl) {
      const sound = new Howl({
        src: [audioUrl],
        volume: 0.5
      });
      sound.play();
    }
  }, []);

  return { play };
};
