import React, { useCallback, useMemo, useState } from 'react';
import { useOrders } from '../../../../contexts/OrdersContext';
import { OrderModel, OrderStatus, UserExportRequest } from '../../../../api/userApi';
import styles from './orders.module.scss';
import { ContractPriceSpan, DateSpan, NumberSpan, RedGreenSpan } from '@components/numbers';
import { Box, FormLabel } from '@mui/material';
import { DataGridPro, GridColDef, GridFooterContainer, GridRowCount, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { orderStatusMap, orderTypeMap } from 'src/data/enumTypeMaps';
import _ from 'lodash';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButton } from '@/components/styledComponents';
import { useSymbol } from '@/contexts/SymbolContext';
import { useApi } from '@/contexts/ApiContext';
import { TsModal } from '@/components/modal';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Heading, { HeadingSize } from '@/components/topstep/heading';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { logException } from '@/helpers/exceptionHelper';
import { downloadBlob } from '@/helpers/downloadHelper';
import { toast } from 'react-toastify';
import { useDeviceContext } from '@/contexts/DeviceContext';

interface OrdersProps {
  changed: () => void;
  tabData: {
    gridState: GridInitialStatePro;
  };
}

const Orders: React.FC<OrdersProps> = (props): JSX.Element => {
  const { orders, cancelOrder } = useOrders();
  const { activeTradingAccount } = useTradingAccount();
  const { getContractByProductId } = useSymbol();
  const { orderApi } = useApi();
  const [showExportModal, setShowExportModal] = useState(false);
  const gridRef = useGridApiRef();
  const [exportDateStart, setExportDateStart] = useState<dayjs.Dayjs>(dayjs().subtract(1, 'day').startOf('day'));
  const [exportDateEnd, setExportDateEnd] = useState<dayjs.Dayjs>(dayjs().add(2, 'day').startOf('day'));
  const { isMobile } = useDeviceContext();

  const columns: GridColDef<OrderModel>[] = useMemo(() => {
    const c: GridColDef<OrderModel>[] = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
        filterable: true,
        flex: 1,
        headerClassName: styles.header,
        minWidth: isMobile ? 80 : undefined
      },
      {
        field: 'createdAt',
        headerName: 'Time',
        minWidth: 150,
        sortable: true,
        flex: 2,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <DateSpan>{params.row.createdAt.local()}</DateSpan>;
        },
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return params.row.createdAt.unix();
        }
      },
      {
        field: 'symbolName',
        headerName: 'Symbol',
        sortable: true,
        filterable: true,
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <span>{params.row.symbolName}</span>;
        },
        minWidth: isMobile ? 100 : undefined
      },
      {
        field: 'positionSize',
        headerName: 'Size',
        sortable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <NumberSpan>{Math.abs(params.row.positionSize)}</NumberSpan>;
        },
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return Math.abs(params.row.positionSize);
        },
        minWidth: isMobile ? 80 : undefined
      },
      {
        field: 'positionType',
        headerName: 'Order Action',
        sortable: true,
        flex: 1,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return params.row.positionSize < 0 ? 'Sell' : 'Buy';
        },
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return <RedGreenSpan colorCheck={params.row.positionSize}>{params.row.positionSize < 0 ? 'Sell' : 'Buy'}</RedGreenSpan>;
        },
        minWidth: isMobile ? 120 : undefined
      },
      {
        field: 'type',
        headerName: 'Order Type',
        filterable: true,
        sortable: true,
        minWidth: isMobile ? 110 : 75,
        flex: 1,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return orderTypeMap[params.row.type];
        }
      },
      {
        field: 'limitPrice',
        headerName: 'Limit Price',
        minWidth: isMobile ? 110 : 100,
        sortable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          const metadata = getContractByProductId(params.row.symbolId);
          return params.row.limitPrice != null ? <ContractPriceSpan contract={metadata}>{params.row.limitPrice}</ContractPriceSpan> : <></>;
        }
      },
      {
        field: 'stopPrice',
        headerName: 'Stop Price',
        minWidth: isMobile ? 110 : 100,
        sortable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          const metadata = getContractByProductId(params.row.symbolId);
          return params.row.stopPrice != null ? <ContractPriceSpan contract={metadata}>{params.row.stopPrice}</ContractPriceSpan> : <></>;
        }
      },
      {
        field: 'executePrice',
        headerName: 'Execute Price',
        minWidth: isMobile ? 120 : 100,
        sortable: true,
        type: 'number',
        flex: 1,
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          const metadata = getContractByProductId(params.row.symbolId);
          return params.row.executePrice != null ? <ContractPriceSpan contract={metadata}>{params.row.executePrice}</ContractPriceSpan> : <></>;
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        filterable: true,
        minWidth: 100,
        flex: 2,
        headerClassName: styles.header,
        valueGetter: (params: GridValueGetterParams<OrderModel>) => {
          return orderStatusMap[params.row.status];
        },
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return (
            <Box>
              <span>{orderStatusMap[params.row.status]}</span> {params.row.rejectionReason && <span>({params.row.rejectionReason})</span>}
            </Box>
          );
        }
      },
      {
        field: 'cancel',
        headerName: 'Cancel',
        sortable: false,
        filterable: false,
        flex: 0.5,
        type: 'actions',
        headerClassName: styles.header,
        renderCell: (params: GridValueGetterParams<OrderModel>) => {
          return (
            params.row.status == OrderStatus.Open && (
              <StyledButton variant='text' onClick={() => cancelOrder(params.row.id)} className={styles.removeButton}>
                <CloseIcon />
              </StyledButton>
            )
          );
        }
      }
    ];

    return c;
  }, [cancelOrder, isMobile, getContractByProductId]);

  const noRows = useMemo(() => {
    return (
      <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        No Orders
      </Box>
    );
  }, []);

  const initialState: GridInitialStatePro = useMemo(() => {
    return (
      props?.tabData?.gridState ?? {
        sorting: {
          sortModel: [
            {
              field: 'createdAt',
              sort: 'desc'
            }
          ]
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 20 }
        }
      }
    );
  }, []);

  const toolbar = useMemo(() => {
    return (
      <GridFooterContainer sx={{ height: '2.4em', minHeight: '2.4em' }}>
        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <StyledButton
            sx={{ padding: '0', margin: '0.1em ' }}
            color='neutral'
            size='small'
            onClick={() => {
              setShowExportModal(true);
            }}
          >
            Export
          </StyledButton>
          <GridRowCount rowCount={orders.length} visibleRowCount={10} />
        </Box>
      </GridFooterContainer>
    );
  }, [orders]);

  const exportOrders = useCallback(() => {
    orderApi
      .userExportCsv(new UserExportRequest({ start: exportDateStart, end: exportDateEnd, tradingAccountId: activeTradingAccount.accountId, timeZone: dayjs.tz.guess() }))
      .then((res) => {
        downloadBlob(res.data, 'orders_export.csv', 'text/csv');
        toast.success('Orders exported successfully, check your downloads', { autoClose: 5000, type: 'success' });
        setShowExportModal(false);
      })
      .catch((e) => {
        logException(e, 'Failed to export orders');
        toast.error('Failed to export orders', { autoClose: 5000, type: 'error' });
        setShowExportModal(false);
      });
  }, [exportDateStart, exportDateEnd, activeTradingAccount?.accountId]);

  const exportModal = useMemo(() => {
    return (
      <TsModal open={showExportModal} onClose={() => setShowExportModal(false)} title='Export Orders'>
        <Box>
          <Heading style={{ textAlign: 'center', marginBottom: '1em' }} size={HeadingSize.H2}>
            Export Orders
          </Heading>
          <LocalizationProvider dateLibInstance={dayjs} dateAdapter={AdapterDayjs}>
            <DateRangePicker
              defaultValue={[exportDateStart, exportDateEnd]}
              value={[exportDateStart, exportDateEnd]}
              onChange={(e) => {
                setExportDateStart(e[0]);
                setExportDateEnd(e[1]);
              }}
            />
          </LocalizationProvider>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>
            <StyledButton color='error' sx={{ margin: '1em' }} onClick={() => setShowExportModal(false)}>
              Cancel
            </StyledButton>
            <StyledButton color='success' sx={{ margin: '1em' }} onClick={exportOrders}>
              Export
            </StyledButton>
          </Box>
        </Box>
      </TsModal>
    );
  }, [showExportModal, exportDateStart, exportDateEnd, exportOrders]);

  return (
    <Box style={{ height: '100%', width: '100%', minWidth: '100px', minHeight: '100px', maxHeight: isMobile ? 'calc(100% - 40px)' : undefined }}>
      {isMobile && (
        <div style={{ padding: '0.75em', textAlign: 'center' }}>
          <FormLabel sx={{ fontSize: '1.35em' }}>Orders</FormLabel>
        </div>
      )}
      <DataGridPro
        apiRef={gridRef}
        rows={orders}
        rowHeight={32}
        columnHeaderHeight={32}
        density='compact'
        columns={columns}
        slots={{
          noRowsOverlay: () => noRows,
          footer: () => toolbar
        }}
        initialState={initialState}
        pageSizeOptions={[10, 20, 50]}
        onStateChange={() => {
          const newState = gridRef.current.exportState();
          if (_.isEqual(newState, props.tabData.gridState)) return;

          props.tabData.gridState = newState;
          props.changed();
        }}
      ></DataGridPro>
      {exportModal}
    </Box>
  );
};

export default Orders;
