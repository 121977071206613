import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCqg } from './CqgContext';
import { useSymbol } from './SymbolContext';
import SltpData from '../models/sltpData';
import { useApi } from './ApiContext';
import {
  LinkOrderUserRequest,
  LinkOrderUserResponse,
  LinkOrderUserResult,
  LinkedOrderModel,
  OrderModel,
  OrderStatus,
  OrderType,
  PlaceOrderRequest,
  PlaceOrderResponse,
  PlaceOrderResult,
  PositionModel,
  StopLossTakeProfitModel,
  SymbolMetadata,
  TradeModel,
  UnlinkOrderUserRequest,
  UnlinkOrderUserResponse,
  UpdateStopLossRequest,
  UserContractModel
} from '../api/userApi';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';
import { useTradingAccount } from './TradingAccountContext';
import { useModal } from './ModalContext';
import { UpdateAction } from '@models/updateAction';
import { Id, TypeOptions, toast } from 'react-toastify';
import { orderTypeMap } from 'src/data/enumTypeMaps';
import { ToastContent, ToastIcon } from 'react-toastify/dist/types';
import { v4 as uuidv4 } from 'uuid';
import { SoundType, useSoundNotifications } from '@hooks/useSoundNotifications';
import { useSettings } from '@contexts/SettingsContext';
import { logException } from '@/helpers/exceptionHelper';
import * as Sentry from '@sentry/react';
import { formatContractPrice } from '@/helpers/formatter';

export enum OrderPromptType {
  Buy,
  Sell
}

export interface FEOrderModel {
  id?: number;
  symbol: string;
  orderType: OrderPromptType;
  type: OrderType;
  limitPrice?: number;
  stopPrice?: number;
  trailDistance?: number;
  amount: number;
}

export interface FeOrderResult {
  error: string;
  success: boolean;
}
export interface EditOrderModel {
  price: number;
  risk: number;
  profit: number;
}

export interface IOrdersContext {
  orders: OrderModel[];
  allPositions: PositionModel[];
  activePositions: PositionModel[];
  rawActivePositions: PositionModel[];
  trades: TradeModel[];
  sltpSettings: Map<string, SltpData>;
  linkedOrders: LinkedOrderModel[];
  linkOrders: (tradingAccountId: number, orderIds: number[]) => Promise<LinkOrderUserResponse>;
  unlinkOrders: (tradingAccountId: number, groupId: string) => Promise<UnlinkOrderUserResponse>;
  /**
   * There are no dependencies. Does not need to be cached.
   */
  placeOrderWithSymbol: (data: FEOrderModel) => Promise<PlaceOrderResponse>;
  cancelOrder: (id: number) => Promise<boolean>;
  changeOrderPrice: (id: number, price: number) => Promise<boolean>;
  closePosition: (string: string) => Promise<boolean>;
  reversePosition: (symbol: string) => Promise<void>;
  editSltpSetting: (positionId: number, stopLoss?: number, takeProfit?: number) => Promise<StopLossTakeProfitModel>;
  editRiskToMake: (positionId: number, risk?: number, toMake?: number) => Promise<StopLossTakeProfitModel>;
  /**
   * This function cancels all orders for the active account
   *
   * There are no dependencies. Does not need to be cached.
   */
  cancelAll: () => Promise<boolean>;
  /**
   * This closes all positions on the active account.
   *
   * There are no dependencies. Does not need to be cached.
   */
  closeAllPositions: () => Promise<void>;
  nonSltpOrders: OrderModel[];
  highestUnrealizedBalance: number;
  unrealizedBalance: number;
  unrealizedPnl: number;
}

export const calculatePnl = (posSize: number, entryPrice: number, price: number, multiplier: number) => {
  if (posSize < 0) {
    return posSize * (entryPrice - price) * multiplier * -1;
  } else if (posSize > 0) {
    return posSize * (price - entryPrice) * multiplier;
  } else {
    return 0;
  }
};
const enum ToastEvent {
  Show = 0,
  Clear = 1,
  DidMount = 2,
  WillUnmount = 3,
  Change = 4,
  ClearWaitingQueue = 5
}
interface MyToastUpdate {
  type: TypeOptions;
  icon: ToastIcon;
  data: any;
  pauseOnFocusLoss: boolean;
  hideProgressBar: boolean;
}

export const placedOrderStatusMap = {
  [OrderStatus.Cancelled]: 'Cancelled',
  [OrderStatus.Expired]: 'Expired',
  [OrderStatus.Filled]: 'Filled',
  [OrderStatus.Open]: 'Placed',
  [OrderStatus.Rejected]: 'Rejected',
  [OrderStatus.Pending]: 'Pending'
};
export const placedOrderStatusTypeMap = {
  [OrderStatus.Cancelled]: 'warning',
  [OrderStatus.Expired]: 'warning',
  [OrderStatus.Filled]: 'success',
  [OrderStatus.Open]: 'info',
  [OrderStatus.Rejected]: 'error',
  [OrderStatus.Pending]: 'info'
};
export const placedOrderStatusIconMap = {
  [OrderStatus.Cancelled]: '❌',
  [OrderStatus.Expired]: '❌',
  [OrderStatus.Filled]: '✅',
  [OrderStatus.Open]: '✅',
  [OrderStatus.Rejected]: '❌',
  [OrderStatus.Pending]: '⏳'
};
const wnd = window as any;
export const OrdersContext = React.createContext<IOrdersContext>({} as any);
export const useOrders = () => React.useContext<IOrdersContext>(OrdersContext);

function OrderContextProvider({ children }: any) {
  const { contracts } = useSymbol();
  const { activeTradingAccount, refreshTradingAccounts, tradingAccounts, setActiveTradingAccount } = useTradingAccount();

  const { subscribeQuotesForSymbol, unsubscribeQuotesForSymbol } = useCqg();
  const { orderApi, positionApi, metadataApi, tradeApi, linkedOrderApi } = useApi();
  const { userId } = useAuth();
  const { showModal } = useModal();
  const { customSettings } = useSettings();

  const symbolsMetadataRef = useRef<Map<string, UserContractModel>>(new Map());

  useEffect(() => {
    symbolsMetadataRef.current.clear();
    for (const symbol of contracts) {
      symbolsMetadataRef.current.set(symbol.productId, symbol);
    }
  }, [contracts]);

  const { subscribeOrders, unsubscribeOrders, subscribePositions, unsubscribePositions, subscribeTrades, unsubscribeTrades, subscribeOnUserReconnect, unsubscribeOnUserReconnect, subscribeLinkedOrders, unsubscribeLinkedOrders } = useCqg();

  const { play } = useSoundNotifications();
  const toasts = useRef<Map<string, Id>>(new Map());
  const [sltpSettings, setSltpSettings] = useState<Map<string, SltpData>>(new Map());
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [trades, setTrades] = useState<TradeModel[]>([]);
  const [linkedOrders, setLinkedOrders] = useState<LinkedOrderModel[]>([]);
  const [sltpOrders, setSltpOrders] = useState<number[]>([]);
  const [positions, setPositions] = useState<PositionModel[]>([]);
  //These are constantly updated with the latest PNL and cause a lot of mutations / re-renders
  const [positionsWithPnl, setPositionsWithPnl] = useState<PositionModel[]>([]);
  const activeAccountIdRef = useRef(0);

  const metadataRef = useRef<Map<string, SymbolMetadata>>(new Map());
  const activeAccountId = useMemo(() => {
    const active = activeTradingAccount?.accountId || 0;
    activeAccountIdRef.current = active;
    return active;
  }, [activeTradingAccount]);

  useEffect(() => {
    metadataApi.get().then((data) => {
      for (const metadata of data) {
        metadataRef.current.set(metadata.symbol, metadata);
      }
    });
  }, []);

  useEffect(() => {
    const refresh = () => {
      linkedOrderApi.getLinkedOrders(activeAccountId).then((data) => {
        setLinkedOrders(data.linkedOrders);
      });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeLinkedOrders(activeAccountId, (update) => {
      setLinkedOrders((existingData) => {
        if (update.action == UpdateAction.Deleted) {
          return existingData.filter((o) => o.groupId != update.data.groupId);
        } else if (update.action == UpdateAction.Created) {
          return [...existingData, update.data];
        } else {
          logException(new Error('Unknown update action'), 'Unknown update action on linked orders');
        }
      });
    });

    return () => {
      unsubscribeLinkedOrders(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  useEffect(() => {
    const refresh = () => {
      tradeApi.get(activeAccountId).then((data) => {
        setTrades(data);
      });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeTrades(activeAccountId, (update) => {
      setTrades((existingData) => {
        var data = update.data;
        const newData = existingData.filter((o) => o.id !== data.id);
        if (data.createdAt) data.createdAt = dayjs(data.createdAt);
        if (data.exitedAt) data.exitedAt = dayjs(data.exitedAt);
        if (data.tradeDay) data.tradeDay = dayjs(data.tradeDay);
        newData.push(data);
        return newData;
      });
    });

    return () => {
      unsubscribeTrades(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  useEffect(() => {
    const refresh = () => {
      orderApi
        .get(activeAccountId)
        .then((res) => {
          setOrders(res);
        })
        .catch((e) => {
          logException(e, 'Failed to refresh orders on signalr reconnect');
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subId = subscribeOrders(activeAccountId, (update) => {
      setOrders((existingData) => {
        var data = update.data;
        const newOrders = existingData.filter((o) => o.id !== data.id);
        const oldOrder = existingData.find((o) => o.id === data.id);
        if (data.cancelledAt) data.cancelledAt = dayjs(data.cancelledAt);
        if (data.createdAt) data.createdAt = dayjs(data.createdAt);
        if (data.filledAt) data.filledAt = dayjs(data.filledAt);
        if (!oldOrder || oldOrder.status != data.status) updateToast(data);
        newOrders.push(data);
        return newOrders;
      });
    });

    return () => {
      unsubscribeOrders(activeAccountId, subId);
      unsubscribeOnUserReconnect(refresh);
    };
  }, [activeAccountId]);

  useEffect(() => {
    toast.onChange((toast) => {
      if (toast.status == 'removed') {
        const orderId = toast.data + '';
        if (orderId && toasts.current.has(orderId)) {
          toasts.current.delete(orderId);
        }
      }
    });
  }, []);

  const visualAlerts = useRef(customSettings.supressAlerts !== true);
  useEffect(() => {
    visualAlerts.current = customSettings.supressAlerts !== true;
  }, [customSettings.supressAlerts]);

  const updateToast = useCallback((order: OrderModel) => {
    if (order.positionSize == 0) return;
    const symbolMetadata = symbolsMetadataRef.current.get(order.symbolId);

    switch (order.status) {
      case OrderStatus.Filled:
        play(SoundType.OrderFilled);
        break;
      case OrderStatus.Rejected:
        play(SoundType.OrderRejected);
        break;
      // case OrderStatus.Open:
      //   play(SoundType.OrderPlaced);
      //   break;
      default:
        break;
    }

    if (visualAlerts.current) {
      const baseMessage = `${order.positionSize > 0 ? '+' + order.positionSize : order.positionSize} ${symbolMetadata.productName} ${orderTypeMap[order.type]} ${order.type == OrderType.Limit && order.limitPrice ? ' @ ' + formatContractPrice(order.limitPrice, symbolMetadata) : ''
        }`;

      const content = (
        <div>
          <span style={{ fontWeight: 'bold' }}>{`${baseMessage} ${placedOrderStatusMap[order.status]}!`}</span>
          {order.rejectionReason && (
            <div>
              <span>{order.rejectionReason}</span>
            </div>
          )}
        </div>
      );
      const toastKey = order.customTag || order.id + '';
      const toastId = toasts.current.get(toastKey) || null;
      updateToastContent(toastId, content, {
        icon: placedOrderStatusIconMap[order.status],
        type: placedOrderStatusTypeMap[order.status],
        data: toastKey,
        pauseOnFocusLoss: false,
        hideProgressBar: true
      });
    }
  }, []);

  const updateToastContent = useCallback((toastId: Id, content: ToastContent<any>, options: MyToastUpdate) => {
    if (toastId) {
      toast.update(toastId, { ...options, render: content, pauseOnFocusLoss: false, hideProgressBar: true });
    } else {
      const newToastId = toast(content, options);
      const toastKey = options.data + '';

      toasts.current.set(toastKey, newToastId);
      wnd.updateMap = toasts.current;
    }
  }, []);

  const [accountIds, setAccountIds] = useState<number[]>([]);
  useEffect(() => {
    const ids = tradingAccounts.map((x) => x.accountId);
    setAccountIds((prev) => {
      var isMismatch = false;

      for (const id of ids) {
        if (!prev.includes(id)) {
          return ids;
        }
      }

      for (const id of prev) {
        if (!ids.includes(id)) {
          return ids;
        }
      }

      return prev;
    });
  }, [tradingAccounts]);

  useEffect(() => {
    const refresh = () => {
      positionApi
        .getAllByUser(userId)
        .then((data) => {
          setPositions(data);
          const sltpOrderIds = data
            .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
            .flat()
            .filter((x) => x);
          setSltpOrders(sltpOrderIds);
        })
        .catch((e) => {
          logException(e, 'Failed to refresh positions on signalr reconnect');
        });
    };

    refresh();
    subscribeOnUserReconnect(refresh);

    const subscriptions = accountIds.map((y) => {
      const subId = subscribePositions(y, (update) => {
        setPositions((prev) => {
          var position = update.data;

          const existing = prev.find((p) => p.id == position.id);

          if (update.action == UpdateAction.Deleted) {
            const newData = prev.filter((p) => p.id != position.id);
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          }

          position.entryTime = dayjs(position.entryTime);

          if (!existing) {
            const newData = [...prev, position];
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          } else {
            existing.positionSize = position.positionSize;
            existing.averagePrice = position.averagePrice;
            existing.entryTime = position.entryTime;
            existing.profitAndLoss = position.profitAndLoss;
            existing.takeProfit = position.takeProfit;
            existing.stopLoss = position.stopLoss;
            existing.risk = position.risk;
            existing.toMake = position.toMake;
            existing.stopLossOrderId = position.stopLossOrderId;
            existing.takeProfitOrderId = position.takeProfitOrderId;

            const newData = [...prev];
            const sltpOrderIds = newData
              .map((p) => [p.stopLossOrderId, p.takeProfitOrderId])
              .flat()
              .filter((x) => x);
            setSltpOrders(sltpOrderIds);
            return newData;
          }
        });
      });

      return { subId, accountId: y };
    });

    return () => {
      subscriptions.forEach((x) => unsubscribePositions(x.accountId, x.subId));
      unsubscribeOnUserReconnect(refresh);
    };
  }, [accountIds, userId]);

  useEffect(() => {
    setPositionsWithPnl(positions);
    const symbolsToWatch = [...new Set(positions.filter((y) => y.positionSize != 0).map((x) => x.symbolId))];

    const subs = symbolsToWatch.map((symbol) => {
      const id = subscribeQuotesForSymbol(symbol, (quote) => {
        if (quote.lastPrice) {
          setPositionsWithPnl((prev) => {
            const positions = prev.filter((x) => x.symbolId == symbol);
            if (!positions.length) return prev;
            const metadata = metadataRef.current.get(symbol);
            if (!metadata) return prev;

            for (const pos of positions) {
              const lastPrice = quote.lastPrice;
              var pnl = calculatePnl(pos.positionSize, pos.averagePrice, lastPrice, metadata.contractCost);
              pos.profitAndLoss = pnl;
            }

            return [...prev];
          });
        }
      });

      return { symbol, id };
    });

    return () => {
      subs.forEach((x) => unsubscribeQuotesForSymbol(x.symbol, x.id));
    };
  }, [positions]);

  const reversePosition = useCallback(async (symbol: string) => {
    try {
      await positionApi.reverse(activeAccountIdRef.current, symbol);
    } catch (e) {
      logException(e, 'Failed to reverse position');
    }
  }, []);

  const cancelAll = useCallback(async () => {
    try {
      const res = await orderApi.cancelAllAccountOrders(activeAccountIdRef.current);
      return res;
    } catch (e) {
      logException(e, 'Failed to cancel all orders');
    }
  }, []);

  const editRiskToMake = useCallback(async (positionId: number, risk?: number, toMake?: number) => {
    try {
      const res = await positionApi.putRiskToMake(positionId, risk, toMake);
      return res;
    } catch (e) {
      logException(e, 'Failed to edit Risk To Make');
    }
  }, []);

  const editSltpSetting = useCallback(async (positionId: number, stopLoss?: number, takeProfit?: number) => {
    return orderApi.updateStopLoss(new UpdateStopLossRequest({ positionId, stopLoss, takeProfit }));
  }, []);

  const cancelOrder = useCallback(async (id: number) => {
    try {
      await orderApi.cancelAccountOrderById(activeAccountIdRef.current, id);
    } catch (e) {
      logException(e, 'Failed to cancel order');
    }
    return true;
  }, []);

  const closeAllPositions = useCallback(async () => {
    try {
      await positionApi.closeAll(activeAccountIdRef.current);
    } catch (e) {
      logException(e, 'Failed to close all positions');
    }
  }, []);

  const closePosition = useCallback(async (symbol: string) => {
    try {
      await positionApi.close(activeAccountIdRef.current, symbol);
      return true;
    } catch (e) {
      logException(e, 'Failed to close position');
    }
  }, []);

  const placeOrderWithSymbol = useCallback(async (data: FEOrderModel): Promise<PlaceOrderResponse> => {
    return Sentry.startSpan({ name: 'Place Order', op: 'http' }, async (span) => {

      const tradingAccount = tradingAccounts.find((x) => x.accountId == activeAccountIdRef.current);

      if (tradingAccount?.lockoutExpiration) {
        span?.setData('result', 'Account is locked out');
        if (visualAlerts.current) {
          toast('Account is locked out', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
        }

        return null;
      }

      const tag = uuidv4();
      var order = new PlaceOrderRequest({
        accountId: activeAccountIdRef.current,
        positionSize: data.orderType === OrderPromptType.Buy ? data.amount : -data.amount,
        symbolId: data.symbol,
        type: data.type,
        trailDistance: data.trailDistance,
        limitPrice: data.type == OrderType.Limit || data.type == OrderType.StopLimit ? data.limitPrice : null,
        customTag: tag,
        stopPrice: data.type == OrderType.Stop || data.type == OrderType.StopLimit ? data.stopPrice : null
      });

      if (order.type == OrderType.Stop || order.type == OrderType.StopLimit) {
        if (!order.stopPrice) {
          if (visualAlerts.current) {
            toast('Stop Price is required for Stop Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
          }

          return;
        }
      }

      if (order.type == OrderType.Limit || order.type == OrderType.StopLimit) {
        if (!order.limitPrice && visualAlerts.current) {
          toast('Limit Price is required for Limit Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
          return;
        }
      }

      const symbolMetadata = symbolsMetadataRef.current.get(data.symbol);
      const baseMessage = `${order.positionSize > 0 ? '+' + order.positionSize : order.positionSize} ${symbolMetadata.productName} ${orderTypeMap[data.type]} ${data.type == OrderType.Limit ? ' @ ' + formatContractPrice(data.limitPrice, symbolMetadata) : ''}`;
      let toastId = null;

      if (visualAlerts.current) {
        toastId = toast(
          <div>
            <span style={{ fontWeight: 'bold' }}>{`Placing ${baseMessage}`}</span>
          </div>,
          {
            type: 'info',
            icon: () => '⏳',
            data: tag,
            pauseOnFocusLoss: false,
            hideProgressBar: true
          }
        );

        toasts.current.set(tag, toastId);
      }

      try {
        const res = await orderApi.post(order);

        if (res.errorMessage) {
          if (visualAlerts.current && toastId) {
            toast.dismiss(toastId);
            toast.error(res.errorMessage);
          }
        }

        if (order.type == OrderType.Limit || order.type == OrderType.StopLimit) {
          if (!order.limitPrice && visualAlerts.current) {
            toast('Limit Price is required for Limit Orders', { type: 'error', pauseOnFocusLoss: false, hideProgressBar: true });
            return;
          }
        }
      } catch (e) {
        logException(e, 'Failed to place order');
        if (visualAlerts.current) {
          if (toastId) toast.dismiss(toastId);

          toast(
            <div>
              <span style={{ fontWeight: 'bold' }}>{`${baseMessage} Failed`}</span>
              <br />
              API Error.
            </div>,
            {
              icon: () => '❌',
              type: 'error',
              pauseOnFocusLoss: false,
              hideProgressBar: true
            }
          );
        }
      }
    });
  }, [activeTradingAccount]);

  const changeOrderPrice = useCallback(async (id: number, price: number) => {
    return await orderApi.editOrder(id, price);
  }, []);

  const editOrder = useCallback(async (id: number, data: FEOrderModel) => {
    // res = await api.post<FeOrderResult>(`/trades/pending/edit/${id}`, {});
    //   return res.data;

    const ret: FeOrderResult = {
      error: 'NOT IMPLEMENTED',
      success: false
    };
    return ret;
  }, []);

  const filteredOrders = useMemo(() => {
    if (!orders || !orders.length) return [];
    if (!sltpOrders.length) return orders;
    const filteredOrders = orders.filter((o) => !sltpOrders.includes(o.id));
    return filteredOrders;
  }, [orders, sltpOrders]);

  const activePositionWithPnl = useMemo(() => {
    return positionsWithPnl.filter((p) => p.accountId == activeTradingAccount.accountId);
  }, [positionsWithPnl, activeTradingAccount]);

  const rawActivePositions = useMemo(() => {
    return positions.filter((p) => p.accountId == activeTradingAccount.accountId);
  }, [positions, activeTradingAccount]);

  const linkOrders = useCallback((tradingAccountId: number, orderIds: number[]) => {
    return linkedOrderApi.linkOrders(new LinkOrderUserRequest({ tradingAccountId, orderIds }));
  }, []);

  const unlinkOrders = useCallback((tradingAccountId: number, orderGroupId: string) => {
    return linkedOrderApi.unlinkOrders(new UnlinkOrderUserRequest({ groupId: orderGroupId, tradingAccountId }));
  }, []);

  const unrealizedPnl = useMemo(() => {
    return activePositionWithPnl.reduce((acc, pos) => {
      return acc + pos.profitAndLoss;
    }, 0);
  }, [activePositionWithPnl]);

  const unrealizedBalance = useMemo(() => {
    if (!activeTradingAccount) return 0;

    return activeTradingAccount.balance + unrealizedPnl;
  }, [activeTradingAccount, unrealizedPnl]);

  const highestUnrealizedBalance = useMemo(() => {
    return Math.max(unrealizedBalance, activeTradingAccount.highestUnrealizedBalance);
  }, [activeTradingAccount.highestUnrealizedBalance, unrealizedBalance]);

  useEffect(() => {
    if (!activeTradingAccount) return;
    activeTradingAccount.highestUnrealizedBalance = highestUnrealizedBalance;
  }, [unrealizedBalance, highestUnrealizedBalance]);

  useEffect(() => {
    if (!activeTradingAccount) return;
    activeTradingAccount.openPnl = unrealizedPnl;
  }, [activeTradingAccount, unrealizedPnl]);

  const values = useMemo<IOrdersContext>(() => {
    return {
      sltpSettings,
      cancelOrder,
      editRiskToMake,
      placeOrderWithSymbol,
      changeOrderPrice,
      closePosition,
      editOrder,
      editSltpSetting,
      cancelAll,
      orders: orders,
      nonSltpOrders: filteredOrders,
      activePositions: activePositionWithPnl,
      rawActivePositions: rawActivePositions,
      allPositions: positionsWithPnl,
      reversePosition,
      trades,
      closeAllPositions,
      linkedOrders,
      unlinkOrders,
      linkOrders,
      highestUnrealizedBalance,
      unrealizedBalance,
      unrealizedPnl
    };
  }, [unrealizedPnl, highestUnrealizedBalance, unrealizedBalance, sltpSettings, orders, positions, sltpOrders, activeTradingAccount, filteredOrders, activePositionWithPnl, positionsWithPnl, rawActivePositions, trades, linkedOrders]);

  return <OrdersContext.Provider value={values}>{children}</OrdersContext.Provider>;
}

export default OrderContextProvider;
