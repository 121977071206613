import { useMarketStatus } from '@contexts/MarketStatusContext';
import { SoundType, useSoundNotifications } from '@hooks/useSoundNotifications';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSettings } from '@contexts/SettingsContext';
import { ExchangeStatus, MarketStatus } from '@/api/userApi';

export interface CurrentStatus {
  previousStatus: MarketStatus | undefined;
  symbol: string;
}

const symbolCqgName = 'F.US.EP';
const symbol = '/ES';

const MarketStatusNotifier: React.FC = () => {
  const { getStatus } = useMarketStatus();
  const { play } = useSoundNotifications();
  const { customSettings } = useSettings();

  const currentMarketStatus = useRef<MarketStatus | undefined>(undefined);
  const isCurrentlyOpen = useRef<boolean | null | undefined>(undefined);

  useEffect(() => {
    const status = getStatus(symbolCqgName);
    if (status === undefined) {
      return;
    }

    const isOpen = status.simStatus === ExchangeStatus.Open && status.status === ExchangeStatus.Open;
    const isClosed = status.simStatus === ExchangeStatus.Closed || status.status === ExchangeStatus.Closed;

    if (isCurrentlyOpen.current === undefined) {
      isCurrentlyOpen.current = isOpen;
      return;
    }

    if (isClosed && isCurrentlyOpen.current) {
      isCurrentlyOpen.current = false;
      if (!customSettings.supressAlerts) {
        play(SoundType.MarketsClosed);

        toast(`${symbol} market is now closed`, {
          icon: '🔴',
          type: 'error',
          pauseOnFocusLoss: false,
          hideProgressBar: true
        });
      }
    }

    if (isOpen && !isCurrentlyOpen.current) {
      isCurrentlyOpen.current = true;
      if (!customSettings.supressAlerts) {
        play(SoundType.MarketsOpen);

        toast(`${symbol} market is now open`, {
          icon: '🟢',
          type: 'success',
          pauseOnFocusLoss: false,
          hideProgressBar: true
        });
      }
    }
  }, [symbolCqgName, getStatus, symbol]);

  return <></>;
};

export default React.memo(MarketStatusNotifier);
