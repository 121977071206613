import React from 'react';
import styles from './marketNews.module.scss';
import Heading, { HeadingSize } from '../../../../components/topstep/heading';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { Box } from '@mui/material';
import { config } from '@/config';

const MarketNews: React.FC = (): JSX.Element => {
  return (
    <Box height='100%' width='100' display='flex'>
      <AutoSizer id='financialjuice-news-widget-container'>
        {(size: Size) => {
          return (
            <iframe
              scrolling='no'
              src={`https://feed.financialjuice.com/widgets/headlines.aspx?wtype=NEWS&mode=Dark&container=financialjuice-news-widget-container&width=${size.width
                }px&height=${size.height + 45}px&backC=${config.themes.dark.lightBg.replace("#", "")}&fontC=b2b5be`}
              height={size.height || 0}
              width={size.width || 0}
              style={{ border: 'none', height: size.height + 45, width: size.width }}
              frameBorder='0'
            ></iframe>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export default MarketNews;
