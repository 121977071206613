import React, { useEffect } from 'react';
import { Box, ButtonBase, Popover, Tooltip } from '@mui/material';
import { LinkedColor, useLinked } from '@/contexts/LinkedContext';
import { set } from 'lodash';

interface TabTitleProps {
  title: string;
  changed: () => void;
  tabData: {
    linkedColor?: LinkedColor;
    hotkeysEnabled?: boolean;
    callbacks: {
      refreshTabTitle: () => void;
      onLinkedColorChange: (color: LinkedColor) => void;
      onHotkeyEnabledChange: (enabled: boolean) => void;
    };
  };

  hotkeysSupported?: boolean;
  onHotkeysEnabled?: () => void;
}

const TabTitle: React.FC<TabTitleProps> = (props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const [hotkeysEnabled, setHotkeysEnabled] = React.useState(props.tabData.hotkeysEnabled ?? false);
  const linked = useLinked();

  useEffect(() => {
    hookCallback();
  }, []);

  useEffect(() => {
    hookCallback();
  }, [props.tabData]);

  const hookCallback = () => {
    if (!props.tabData.callbacks) {
      props.tabData.callbacks = {} as any;
    }

    props.tabData.callbacks.refreshTabTitle = () => {
      setHotkeysEnabled(props.tabData.hotkeysEnabled ?? false);
    };
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hotkeyColor = () => {
    if (props.tabData.hotkeysEnabled) {
      return '#df981c';
    } else {
      return '#777';
    }
  };

  const handleHotkeyClick = (e: any) => {
    e.stopPropagation();

    const newValue = !props.tabData.hotkeysEnabled;

    if (newValue) {
      props.onHotkeysEnabled?.();
    }

    setHotkeysEnabled(newValue);

    if (props.tabData.callbacks.onHotkeyEnabledChange) {
      props.tabData.callbacks.onHotkeyEnabledChange(newValue);
    }

    props.tabData.hotkeysEnabled = newValue;

    props.changed();

    console.log('hotkey click', newValue);
  };

  return (
    <div>
      <span aria-describedby={id} style={{ cursor: 'pointer', marginRight: '0.2em' }} onClick={handleClick}>
        {linked.links[props.tabData.linkedColor ?? LinkedColor.None]?.text}
      </span>

      {props.hotkeysSupported && (
        <Box
          display='inline-flex'
          component='span'
          minWidth={15}
          minHeight={15}
          maxWidth={15}
          maxHeight={15}
          sx={{ border: `1px solid ${hotkeyColor()}`, cursor: 'pointer' }}
          borderRadius='2px'
          marginRight='0.4em'
          justifyContent='center'
          alignItems='center'
          onClick={handleHotkeyClick}
        >
          <Tooltip title='Enable Hotkeys' style={{ color: 'white' }}>
            <span style={{ padding: 0, margin: 0, color: hotkeyColor() }}>H</span>
          </Tooltip>
        </Box>
      )}

      <span>{props.title}</span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {Object.values(linked.links).map((link, index) => (
          <ButtonBase
            key={link.color}
            sx={{ padding: '0.25em', margin: '0.25em' }}
            onClick={() => {
              props.tabData.linkedColor = link.color;
              if (props.tabData?.callbacks?.onLinkedColorChange) props.tabData.callbacks.onLinkedColorChange(link.color);
              props.changed();
              handleClose();
            }}
          >
            {link.text}
          </ButtonBase>
        ))}
      </Popover>
    </div>
  );
};

export default TabTitle;
