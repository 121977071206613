import { LayoutBase } from 'rc-dock';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Loading from '../components/Loading';
import { useApi } from './ApiContext';
import { JsonDocument, LayoutModel, RootElement } from '../api/userApi';
import dayjs from 'dayjs';
export interface LayoutInfo {
  id: number;
  name: string;
  layout: LayoutBase;
  draft: boolean;
}

export interface ILayoutsContext {
  layouts: LayoutInfo[];
  setActiveLayout(layout: LayoutInfo, isDraft: boolean): void;
  activeLayout: LayoutInfo;
  saveLayout(name: string, layout: LayoutBase, draft: boolean): void;
  setSaveCurrentLayoutCallback(callback: () => LayoutBase): void;
  saveCurrentLayout(name: string): void;
  autoSaveLayout(layout: LayoutBase): void;
  deleteLayout(id: number): void;
}

export const LayoutsContext = React.createContext<ILayoutsContext>({} as any);
export const useLayouts = () => React.useContext<ILayoutsContext>(LayoutsContext);

function LayoutsContextProvider({ children }: any) {
  const [activeLayout, setActiveLayout] = useState<LayoutInfo>(null);
  const [layoutChanged, setLayoutChanged] = useState<boolean>(false);
  const saveCurrentLayoutCallback = useRef<() => LayoutBase>();
  const [layouts, setLayouts] = useState<LayoutInfo[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { layoutApi } = useApi();
  const saveDraftTimeout = useRef<any>();
  const currentActiveLayout = useRef<LayoutInfo>();
  const autoSaveLayoutRef = useRef<(layout: LayoutBase, preventSwitch?: boolean) => void>();

  useEffect(() => {
    if (!activeLayout) return;
    localStorage.setItem('activeLayout', activeLayout.name);
    localStorage.setItem('activeLayoutId', activeLayout.id.toString());
    currentActiveLayout.current = activeLayout;
    autoSaveLayoutRef.current = (layout: LayoutBase, preventSwitch?: boolean) => {
      if (saveDraftTimeout.current) clearTimeout(saveDraftTimeout.current);
      saveDraftTimeout.current = setTimeout(() => {
        saveDraftTimeout.current = null;
        saveLayout(activeLayout.name, layout, true, preventSwitch);
      }, 5000);
    };
  }, [activeLayout]);

  const autoSaveLayout = useCallback((layout: LayoutBase, preventSwitch?: boolean) => {
    if (autoSaveLayoutRef.current) {
      autoSaveLayoutRef.current(layout, preventSwitch);
    }
  }, []);

  const deleteLayout = useCallback(
    (id: number) => {
      layoutApi.delete(id).then(() => {
        setLayouts((prev) => [...prev.filter((y) => y.id != id)]);
        if (activeLayout.id == id) {
          setActiveLayout(layouts.find((x) => x.name === 'default'));
          setLayoutChanged(false);
        }
      });
    },
    [activeLayout, layouts]
  );

  useEffect(() => {
    if (!layoutChanged) {
      return;
    }

    if (saveDraftTimeout.current) {
      clearTimeout(saveDraftTimeout.current);
      saveDraftTimeout.current = null;
    }

    if (activeLayout && activeLayout.layout) {
      saveDraftTimeout.current = setTimeout(() => {
        if (saveLayout){ // Ensure saveLayout function is accessible
          saveDraftTimeout.current = null;
          if(saveCurrentLayoutCallback.current)
            saveLayout(activeLayout.name, saveCurrentLayoutCallback.current(), true);
        }
      }, 5000);
    }
  }, [activeLayout, layoutChanged]);

  const saveLayout = useCallback((name: string, layout: LayoutBase, draft: boolean, preventSwitch?: boolean) => {
    if (!draft) {
      setLayoutChanged(false);
      if (saveDraftTimeout.current) {
        clearTimeout(saveDraftTimeout.current);
        saveDraftTimeout.current = null;
      }
    }
    const doc = new JsonDocument({ rootElement: new RootElement(layout) });
    const layoutModel = new LayoutModel({ name, content: doc, createdAt: dayjs(), isDraft: draft, id: 0 });
    layoutApi.post(new LayoutModel(layoutModel)).then((id) => {
      const layoutInfo: LayoutInfo = { name, layout, draft: draft, id };

      setLayouts((prev) => {
        const filteredLayouts = prev.filter((y) => {
          if (y.name == name) {
            if (draft) {
              if (y.draft) {
                return false;
              }
            } else {
              return false; //hide the draft layout since we did a hard save
            }
          }
          return true;
        });

        return [...filteredLayouts, layoutInfo];
      });

      if (!preventSwitch) {
        setActiveLayout(layoutInfo);
      }

      setLayoutChanged(false);
    });
  }, []);

  const saveCurrentLayout = useCallback(
    (name: string) => {
      const layout = saveCurrentLayoutCallback.current();
      saveLayout(name, layout, false);
    },
    [saveCurrentLayoutCallback]
  );

  const setCallback = useCallback((callback: () => LayoutBase) => {
    saveCurrentLayoutCallback.current = callback;
  }, []);

  useEffect(() => {
    let isMounted = true;
    layoutApi.get().then((res) => {
      if (isMounted) {
        const resLayouts = res.map<LayoutInfo>((y) => {
          return { name: y.name, layout: y.content.rootElement as any, draft: y.isDraft, id: y.id };
        });

        const previousActiveLayout = localStorage.getItem('activeLayout');
        const previousActiveLayoutId = localStorage.getItem('activeLayoutId');

        const activeLayoutsById = resLayouts.filter((x) => x.id.toString() == previousActiveLayoutId);
        if (activeLayoutsById.length > 0) {
          setActiveLayout(activeLayoutsById[0]);
          setLayoutChanged(activeLayoutsById[0].draft);
        } else {
          const activeLayout = resLayouts.filter((x) => x.name === previousActiveLayout);
          if (activeLayout.length > 0) {
            setActiveLayout(activeLayout[0]);
            setLayoutChanged(activeLayout[0].draft);
          } else {
            const defaultLayout = resLayouts.filter((x) => x.name === 'default');
            if (defaultLayout.length > 0) {
              setActiveLayout(defaultLayout[0]);
              setLayoutChanged(defaultLayout[0].draft);
            } else {
              if (resLayouts.length > 0) {
                setActiveLayout(resLayouts[0]);
                setLayoutChanged(resLayouts[0].draft);
              }
            }
          }
        }

        setLayouts(resLayouts);
        setLoaded(true);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const values = useMemo<ILayoutsContext>(() => {
    return {
      saveLayout,
      layouts,
      activeLayout,
      setActiveLayout: (layout, isDraft) => {
        if (!isDraft && saveDraftTimeout.current && saveCurrentLayoutCallback.current) {
          autoSaveLayout(saveCurrentLayoutCallback.current(), true);
        }
        setActiveLayout(layout);
        setLayoutChanged(isDraft);
      },
      deleteLayout,
      setSaveCurrentLayoutCallback: setCallback,
      saveCurrentLayout,
      autoSaveLayout: autoSaveLayout
    };
  }, [layouts, activeLayout]);

  return (
    <LayoutsContext.Provider value={values}>
      {!loaded && <Loading />}
      {loaded && children}
    </LayoutsContext.Provider>
  );
}

export default LayoutsContextProvider;
