export const enum NavRoutes {
  Login = '/login',
  Logout = '/logout',
  Trade = '/trade',
  Error = '/error',
  Resources = '/resources',
  Agreements = '/agreements',
  Home = '/home',
  Register = '/register',
  Settings = '/settings',
  Stats = '/stats',
  Share = '/share',
  SharedStats = '/share/stats',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  MobileCharts = '/mobile/charts',
  Support = '/support',

  // Mobile routes

  MobileTrading = 'trading',
  MobileDom = 'dom',
  MobileOrder = 'order',
  MobileChart = 'mobile-chart',
  MobileTilt = 'tilt',
  MobileQuotes = 'quotes',
  MobileTimeAndSales = 'time-and-sales',

  MobileAccount = 'account',
  MobileAccountInfo = 'info',
  MobileAccounts = 'all-accounts',
  MobilePositions = 'positions',
  MobileOrders = 'orders',
  MobileTrades = 'trades',

  MobileSettings = 'settings',
  MobileDomSettings = 'dom-settings',
  MobileChartSettings = 'chart-settings',
  MobileRiskSettings = 'risk-settings',
  MobileCopyTrading = 'copy-trading',
  MobileMiscSettings = 'misc-settings',
}

export const MobileTradingPages = [
  NavRoutes.MobileDom,
  NavRoutes.MobileOrder,
  // NavRoutes.MobileChart,
  NavRoutes.MobileTilt,
  NavRoutes.MobileQuotes,
  NavRoutes.MobileTimeAndSales,
];

export const MobileAccountPages = [
  NavRoutes.MobileAccountInfo,
  NavRoutes.MobileAccounts,
  NavRoutes.MobilePositions,
  NavRoutes.MobileOrders,
  NavRoutes.MobileTrades,
];

export const MobileSettingsPages = [
  NavRoutes.MobileDomSettings,
  NavRoutes.MobileChartSettings,
  NavRoutes.MobileRiskSettings,
  NavRoutes.MobileCopyTrading,
  NavRoutes.MobileMiscSettings,
];
