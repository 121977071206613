import React, { useCallback, useMemo } from 'react';
import styles from './balance.module.scss';
import { formatPrice } from '../../helpers/formatter';
import { useOrders } from '../../contexts/OrdersContext';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import { LinearProgress, Tooltip, styled, linearProgressClasses, Box } from '@mui/material';
import { useSettings } from '@contexts/SettingsContext';
import { BalanceSource, PersonalDailyAction, TradingRuleType } from '@/api/userApi';
import { useDeviceContext } from '@/contexts/DeviceContext';
import Decimal from 'decimal.js';

type BalanceProps = {
  padding?: number;
  fontSize?: number;
  variant?: 'default' | 'dark';
};

const DailyLoss: React.FC<BalanceProps> = (props: BalanceProps): JSX.Element => {
  const { activeTradingAccount, getAccountTemplateData, getBalanceSource } = useTradingAccount();
  const { activePositions, highestUnrealizedBalance } = useOrders();
  const { customSettings } = useSettings();
  const { isMobile } = useDeviceContext();

  const template = useMemo(() => {
    if (!activeTradingAccount.templateId) return null;
    return getAccountTemplateData(activeTradingAccount.templateId);
  }, [getAccountTemplateData, activeTradingAccount]);

  const unrealizedPnl = useMemo(() => {
    return activePositions.reduce((acc, pos) => {
      return acc + pos.profitAndLoss;
    }, 0);
  }, [activeTradingAccount, activePositions]);

  const getPercentageFromBalanceSource = useCallback(
    (balanceSource: BalanceSource, percentage: number) => {
      const balanceValue = getBalanceSource(activeTradingAccount, balanceSource);
      if (balanceValue === null) {
        return null;
      }
      return new Decimal(balanceValue).mul(percentage).toNumber();
    },
    [activeTradingAccount]
  );

  const isDailyLossDisabled = useMemo(() => {
    if (!template || !template.rules) return true;
    const rule = template.rules.find((rule) => rule.ruleId === TradingRuleType.DailyLossLimit);
    if (!rule) return true;

    const config = rule.configuration.dailyLossLimit;

    if (!config.disableBalanceSource || !config.disableCheckSource) {
      return false;
    }

    let disableValue = new Decimal(getBalanceSource(activeTradingAccount, config.disableBalanceSource));
    let disableCheck = new Decimal(getBalanceSource(activeTradingAccount, config.disableCheckSource));

    if (config.disablePercentage && !config.disableLimit) {
      return false;
    }

    if (config.disablePercentage) {
      disableValue = disableValue.sub(new Decimal(disableValue).mul(config.disablePercentage));
    }

    if (config.disableLimit) {
      disableValue = disableValue.sub(config.disableLimit);
    }

    if (config.disableMinimumBalanceSource) {
      const disableMin = getBalanceSource(activeTradingAccount, config.disableMinimumBalanceSource);
      disableValue = Decimal.max(disableValue, disableMin);
    }

    if (activeTradingAccount.minimumMll) {
      disableValue = Decimal.max(disableValue, activeTradingAccount.minimumMll);
    }

    if (config.disableMaximumBalanceSource) {
      const disableMax = getBalanceSource(activeTradingAccount, config.disableMaximumBalanceSource);
      disableValue = Decimal.min(disableValue, disableMax);
    }

    return disableValue.greaterThanOrEqualTo(disableCheck);
  }, [template, activeTradingAccount]);

  const maxDailyLoss = useMemo(() => {
    if (!!activeTradingAccount.templateId === false) {
      return activeTradingAccount.combineDailyLoss; //
    } else {
      if (!template) return null;
      if (!template.rules) return null;
      const rule = template.rules.find((rule) => rule.ruleId === TradingRuleType.DailyLossLimit);
      if (!rule) return null;

      const config = rule.configuration.dailyLossLimit;

      if (isDailyLossDisabled) {
        return null;
      }

      if (config.limit) {
        return config.limit;
      }

      if (config.percentage) {
        return getPercentageFromBalanceSource(config.source, config.percentage);
      }
    }
  }, [template, activeTradingAccount, isDailyLossDisabled, getPercentageFromBalanceSource]);

  const maxDailyLossWithPdll = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None) {
      if (maxDailyLoss == 0 || maxDailyLoss == null) return activeTradingAccount.personalDailyLossLimit;

      return Math.min(activeTradingAccount.personalDailyLossLimit, maxDailyLoss);
    }

    return maxDailyLoss || 0;
  }, [maxDailyLoss, activeTradingAccount]);

  const unrealizedDayPnl = useMemo(() => {
    return activeTradingAccount.realizedDayPnl + unrealizedPnl;
  }, [activeTradingAccount, unrealizedPnl]);

  const currentLoss = useMemo(() => {
    if (unrealizedDayPnl >= 0) {
      return 0;
    }
    return -unrealizedDayPnl;
  }, [unrealizedDayPnl]);

  const lossPercent = useMemo(() => {
    if (currentLoss == 0) {
      return 0;
    }

    return Math.min(100, new Decimal(currentLoss / maxDailyLossWithPdll).times(100).toNumber());
  }, [currentLoss, maxDailyLossWithPdll]);

  const dllOrPdll = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None) {
      return 'PDLL';
    }
    return 'DLL';
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.personalDailyLossLimitAction]);

  const dllOrPdllTooltip = useMemo(() => {
    if (activeTradingAccount.personalDailyLossLimitAction !== PersonalDailyAction.None && activeTradingAccount.personalDailyLossLimit < maxDailyLoss) {
      return 'Personal Daily Loss Limit: Your custom personal daily loss limit.';
    }
    return 'Daily Loss Limit: The maximum amount an account can lose in a single day.';
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.personalDailyLossLimitAction, maxDailyLoss]);

  const renderFontSize = useMemo(() => {
    switch (customSettings.topNavTextSize) {
      case 0:
        return '12px';
      case 1:
        return '14px';
      case 2:
        return '16px';
      default:
        return '12px';
    }
  }, [customSettings.topNavTextSize]);

  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case 'dark':
        return '#1c1e23';
      default:
        return '#2a292f';
    }
  }, [props.variant]);

  return useMemo(
    () =>
      (maxDailyLossWithPdll && (
        <div className={isMobile ? styles.dllMobile : styles.dll} style={{ padding: props.padding, backgroundColor: backgroundColor }}>
          <Tooltip title={dllOrPdllTooltip}>
            <div className={styles.balanceAmount} style={{ display: 'flex', alignItems: 'center', fontSize: props.fontSize ?? renderFontSize }}>
              <span>{dllOrPdll}:</span>
              {!customSettings.streamerModeDLL ? (
                <>
                  <span className={styles.balanceAmount}>${formatPrice(currentLoss)}</span>
                  <BorderLinearProgress variant='determinate' value={lossPercent} />
                  <span className={styles.balanceAmount}>${formatPrice(maxDailyLossWithPdll)}</span>
                </>
              ) : (
                <span> ******</span>
              )}
            </div>
          </Tooltip>
        </div>
      )) ||
      null,
    [currentLoss, maxDailyLossWithPdll, lossPercent, customSettings.streamerModeDLL, customSettings.topNavTextSize, dllOrPdllTooltip, isMobile]
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '6em',
  borderRadius: 5,
  marginLeft: '0.5em',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.red
  }
}));

export default React.memo(DailyLoss);
