import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../components/Loading';
import { useApi } from './ApiContext';
import { SymbolMetadata, UserContractModel } from '../api/userApi';
import { useSymbol } from '@/contexts/SymbolContext';
import { useSettings } from '@/contexts/SettingsContext';

export enum LinkedColor {
  None,
  White,
  Red,
  Blue,
  Yellow,
  Purple,
  Orange
}

export const LinkedColorText: { [key in LinkedColor]: string } = {
  [LinkedColor.None]: '🚫',
  [LinkedColor.White]: '⚪',
  [LinkedColor.Red]: '🔴',
  [LinkedColor.Blue]: '🔵',
  [LinkedColor.Yellow]: '🟡',
  [LinkedColor.Purple]: '🟣',
  [LinkedColor.Orange]: '🟠'
};

export interface LinkedData {
  color: LinkedColor;
  symbol: UserContractModel;
  text: string;
}

export interface LinkMap {
  [key: string]: LinkedData;
}

export interface ILinkedContext {
  links: LinkMap;
  changeLinkSymbol: (color: LinkedColor, symbol: UserContractModel) => void;
}

export const LinkedContext = React.createContext<ILinkedContext>({} as any);
export const useLinked = () => React.useContext<ILinkedContext>(LinkedContext);

function LinkedContextProvider({ children }: any) {
  const { getContractByProductId, getDefaultContract } = useSymbol();
  const { customSettings, saveCustomSettings } = useSettings();

  const saveCustomSettingsRef = useRef(saveCustomSettings);

  useEffect(() => {
    saveCustomSettingsRef.current = saveCustomSettings;
  }, [saveCustomSettings]);

  const [links, setLinks] = useState<LinkMap>({
    [LinkedColor.None]: {
      color: LinkedColor.None,
      symbol: null,
      text: LinkedColorText[LinkedColor.None]
    },
    [LinkedColor.White]: {
      color: LinkedColor.White,
      symbol: getContractByProductId(customSettings.whiteLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.White]
    },
    [LinkedColor.Red]: {
      color: LinkedColor.Red,
      symbol: getContractByProductId(customSettings.redLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.Red]
    },
    [LinkedColor.Blue]: {
      color: LinkedColor.Blue,
      symbol: getContractByProductId(customSettings.blueLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.Blue]
    },
    [LinkedColor.Yellow]: {
      color: LinkedColor.Yellow,
      symbol: getContractByProductId(customSettings.yellowLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.Yellow]
    },
    [LinkedColor.Purple]: {
      color: LinkedColor.Purple,
      symbol: getContractByProductId(customSettings.purpleLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.Purple]
    },
    [LinkedColor.Orange]: {
      color: LinkedColor.Orange,
      symbol: getContractByProductId(customSettings.orangeLink) ?? getDefaultContract(),
      text: LinkedColorText[LinkedColor.Orange]
    }
  });

  const linksRef = useRef<LinkMap>(links);

  const changeLinkSymbol = useCallback((color: LinkedColor, contract: UserContractModel) => {
    if (linksRef.current[color].symbol.productId === contract.productId) return;
    setLinks((prev) => {
      const newLinks = { ...prev, [color]: { symbol: contract, text: LinkedColorText[color], color: color } };
      linksRef.current = newLinks;
      let settingKey = '';
      switch (color) {
        case LinkedColor.White:
          settingKey = 'whiteLink';
          break;
        case LinkedColor.Red:
          settingKey = 'redLink';
          break;
        case LinkedColor.Blue:
          settingKey = 'blueLink';
          break;
        case LinkedColor.Yellow:
          settingKey = 'yellowLink';
          break;
        case LinkedColor.Purple:
          settingKey = 'purpleLink';
          break;
        case LinkedColor.Orange:
          settingKey = 'orangeLink';
          break;
        default:
          settingKey = 'unknownLink';
      }
      saveCustomSettingsRef.current({ [settingKey]: contract.productId });
      return newLinks;
    });
  }, []);

  const values = useMemo<ILinkedContext>(() => {
    const context: ILinkedContext = {
      links,
      changeLinkSymbol
    };
    return context;
  }, [links]);

  return <LinkedContext.Provider value={values}>{children}</LinkedContext.Provider>;
}

export default LinkedContextProvider;
