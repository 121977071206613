import { OrderModel, OrderStatus, OrderType } from '@/api/userApi';
import { IChartingLibraryWidget } from '@/charting_library/charting_library';
import { useOrders } from '@/contexts/OrdersContext';
import { useSettings } from '@/contexts/SettingsContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import { roundToTickSize } from '@/helpers/decimalHelper';
import { ChartGroup, ORDER_LEFT_PLOT_SIDE, ORDER_RIGHT_PLOT_SIDE } from '@/views/trader/components/charts/chartTypes';
import React, { useEffect, useRef } from 'react';

interface ChartPositionProps {
  widget: IChartingLibraryWidget;
  charts: ChartGroup[];
}

const ChartOrders: React.FC<ChartPositionProps> = ({ widget, charts }): JSX.Element => {
  const { nonSltpOrders, changeOrderPrice, cancelOrder } = useOrders();
  const { customSettings } = useSettings();
  const { activeTradingAccount } = useTradingAccount();

  const canTrade = useRef<boolean>(true);

  useEffect(() => {
    canTrade.current = activeTradingAccount.isFollower !== true;
  }, [activeTradingAccount]);

   //order lines
   useEffect(() => {
    if (customSettings.hidePositionPlots) return;
    const lines = [];
    for (const chartGroup of charts) {
      var filteredOrders = nonSltpOrders
        .filter((x) => x.symbolId == chartGroup.actualSymbol && x.type != OrderType.Market && x.status == OrderStatus.Open)
        .reduce((acc, order) => {
          let price = order.limitPrice;
          let isStop = false;

          if (order.type == OrderType.Limit) {
            price = order.limitPrice;
          } else if (order.type == OrderType.Stop) {
            price = order.stopPrice;
            isStop = true;
          } else if (order.type == OrderType.StopLimit) {
            price = order.limitPrice ?? order.stopPrice;
          } else if (order.type == OrderType.TrailingStop) {
            isStop = true;
            price = order.stopPrice;
          } else {
            return acc;
          }

          const key = `${price}${isStop ? 's' : 'l'}`;

          var existing = acc.get(key);
          if (!existing) {
            existing = [order];
            acc.set(key, existing);
          } else {
            existing.push(order);
          }
          return acc;
        }, new Map<string, OrderModel[]>());
      for (const chartInstance of chartGroup.charts) {
        const ordersAtPrice = new Map<number, number>();
        for (const [, orders] of filteredOrders) {
          try {
            const line = chartInstance.chart.createOrderLine({});
            let orderPrice = orders[0].limitPrice;
            lines.push(line);

            const posSize = orders.reduce((acc, x) => acc + x.positionSize, 0);
            const type = orders[0].type;
            if (type == OrderType.Stop) {
              orderPrice = orders[0].stopPrice;
              line.setText(`Stop Market ${posSize > 0 ? 'Buy' : 'Sell'}`);
            } else if (type == OrderType.Limit) {
              line.setText(`Limit ${posSize > 0 ? 'Buy' : 'Sell'}`);
            } else if (type == OrderType.StopLimit) {
              line.setText(`Stop Limit ${posSize > 0 ? 'Buy' : 'Sell'}`);
            } else if (type == OrderType.TrailingStop) {
              orderPrice = orders[0].stopPrice;
              line.setText(`Trailing Stop ${posSize > 0 ? '🔽 Buy' : '🔼 Sell'}`);
            }
            const existingOrdersAtPrice = ordersAtPrice.get(orderPrice) || 0;
            if (existingOrdersAtPrice) {
              ordersAtPrice.set(orderPrice, existingOrdersAtPrice + 1);
            } else {
              ordersAtPrice.set(orderPrice, 1);
            }
            line
              .setPrice(orderPrice)
              .setBodyBorderColor('#000')
              .setQuantityBackgroundColor('#cac9cb')
              .setQuantityTextColor('#000')
              .setQuantityBorderColor('#000')
              .setBodyTextColor('#000')
              .setLineLength(customSettings.positionPlotsSide == 0 ? ORDER_LEFT_PLOT_SIDE : ORDER_RIGHT_PLOT_SIDE)
              .setLineColor('#cac9cb')
              .setCancelButtonIconColor('#000')
              .setCancelButtonBorderColor('#000')
              .setQuantity(posSize.toString());

            if (!activeTradingAccount.isFollower) {
              line
                .onMove(function () {
                  if (!canTrade.current) {
                    line.setPrice(orderPrice);
                    return;
                  }
                  const price = line.getPrice();
                  const rounded = roundToTickSize(price, chartGroup.tickSize);
                  for (const order of orders){
                    changeOrderPrice(order.id, rounded).then((success) => {
                      if (!success) {
                        line.setPrice(orderPrice);
                      }
                    }).catch((e) => {
                      line.setPrice(orderPrice);
                    });
                  }
                })
                .onCancel('onCancel called', function () {
                  if (canTrade.current) {
                    for (const order of orders) cancelOrder(order.id);
                  }
                });
            }
          } catch (e) {
            console.log('Error creating line', e);
            continue;
          }
        }
      }
    }

    return () => {
      for (const line of lines) {
        try {
          line.remove();
        } catch (e) {
          console.log('Error removing line', e);
        }
      }
    };
  }, [charts, nonSltpOrders, customSettings.hidePositionPlots, activeTradingAccount]);

  return <> </>;
};


export default React.memo(ChartOrders);
