import { Box, styled } from '@mui/material';

export const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

export const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
}); 

export const Page = styled(Row)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',
  backgroundColor: theme.palette.darkBg
}));
