import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSymbol } from '../../contexts/SymbolContext';
import { Autocomplete, AutocompleteRenderInputParams, Box, InputAdornment, MenuItem, TextField, Tooltip, Typography, createFilterOptions, styled } from '@mui/material';
import { useMarketStatus } from '@contexts/MarketStatusContext';
import { ExchangeStatus, SymbolMetadata, UserContractModel } from '@/api/userApi';
import { LinkedColor, useLinked } from '@/contexts/LinkedContext';
import { Circle, Square, Star } from '@mui/icons-material';
import { useSettings } from '@/contexts/SettingsContext';
import { faV } from '@fortawesome/pro-regular-svg-icons';
import { set } from 'lodash';
import { MarketStatus } from '@/api/dataApi';
const getMarketStatus = (marketStatus: MarketStatus) => {
  if (!marketStatus) return 'Unknown';
  if (marketStatus.simStatus == ExchangeStatus.Closed) return 'Closed';

  switch (marketStatus.status) {
    case ExchangeStatus.Open:
      return 'Open';
    case ExchangeStatus.Closed:
      return 'Closed';
    case ExchangeStatus.PreOpen:
      return 'Pre-Open';
    case ExchangeStatus.Halted:
      return 'Paused';
    default:
      return 'Unknown';
  }
};
const getMarketColor = (marketStatus: MarketStatus) => {
  if (!marketStatus) return 'grey';
  if (marketStatus.simStatus == ExchangeStatus.Closed) return 'red';

  switch (marketStatus.status) {
    case ExchangeStatus.Open:
      return 'green';
    case ExchangeStatus.Closed:
      return 'red';
    case ExchangeStatus.PreOpen:
      return '#3cc8ff';
    case ExchangeStatus.Halted:
      return '#ff912e';
    default:
      return 'grey';
  }
};

interface ContractOption {
  label: string;
  desc: string;
  value: string;
  contract: UserContractModel;
  marketStatus: MarketStatus;
}

interface ContractSelectorProps {
  contract: UserContractModel;
  setContract: (contract: UserContractModel) => void;
  linkedColor: LinkedColor;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | undefined;
}

const ContractSelector: React.FC<ContractSelectorProps> = ({ style, contract, setContract, size, linkedColor }): JSX.Element => {
  const { contracts } = useSymbol();
  const { customSettings, saveCustomSettings } = useSettings();
  const { isOpen, getStatus } = useMarketStatus();
  const { links, changeLinkSymbol } = useLinked();
  const [filterText, setFilterText] = useState(contract?.contractName ?? '');
  const renderContractMap = useRef<Map<string, boolean>>(new Map());

  const favorites = useMemo(() => {
    const favs = customSettings.favoriteContracts || [];
    const map = new Map(favs.map((x) => [x, true]));
    return map;
  }, [customSettings.favoriteContracts, customSettings.hideNonFavorites]);

  useEffect(() => {
    setFilterText(contract?.contractName ?? '');
  }, [contract]);

  useEffect(() => {
    if (!filterText && customSettings.hideNonFavorites) {
      renderContractMap.current = favorites;
    } else {
      renderContractMap.current = new Map();
    }
  }, [filterText, customSettings.hideNonFavorites, favorites]);

  const addFavorite = useCallback(
    (contract: UserContractModel) => {
      if (favorites.has(contract.productId)) return;

      customSettings.favoriteContracts = [...(customSettings.favoriteContracts || []), contract.productId];

      saveCustomSettings(customSettings);
    },
    [customSettings, favorites, saveCustomSettings]
  );

  const removeFavorite = useCallback(
    (contract: UserContractModel) => {
      if (!favorites.has(contract.productId)) return;

      customSettings.favoriteContracts = customSettings.favoriteContracts?.filter((x) => x !== contract.productId);

      saveCustomSettings(customSettings);
    },
    [customSettings, favorites, saveCustomSettings]
  );

  const toggleFavorite = useCallback(
    (e: React.MouseEvent, contract: UserContractModel) => {
      if (favorites.has(contract.productId)) removeFavorite(contract);
      else addFavorite(contract);

      e.preventDefault();
      e.stopPropagation();
    },
    [favorites, addFavorite, removeFavorite]
  );

  useEffect(() => {
    if (linkedColor) {
      const contractData = links[linkedColor];
      if (contractData && contractData.symbol && !!contractData.symbol && !contractData.symbol.disabled) {
        setContract(contractData.symbol);
      } else {
        setContract(null);
      }
    }
  }, [links[linkedColor], linkedColor]);

  const autoCompleteBox = useMemo(() => {
    let options = [...contracts]
      .filter((x) => !x.disabled)
      .sort((a, b) => {
        if (favorites.has(a.productId) && !favorites.has(b.productId)) return -1;
        if (!favorites.has(a.productId) && favorites.has(b.productId)) return 1;

        return a.productName.localeCompare(b.productName);
      })
      .map((y) => {
        return {
          label: y.contractName,
          desc: y.description,
          marketStatus: getStatus(y.productId),
          value: y.productId,
          contract: y
        } as ContractOption;
      });

    //TODO: Fix the createFilterOptions stringify
    const selected = (!!contract && options.find((x) => x.value === contract.productId)) || null;
    const filter = createFilterOptions<ContractOption>({
      stringify: (option) => `${option.contract.productId.substring(1)}${option.contract.contractName} ${option.contract.description}`
    });

    return (
      <Box sx={{ margin: '1em 0.5em', display: 'flex', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', alignContent: 'center', ...style }}>
        <Autocomplete
          sx={{ flex: 2 }}
          value={selected}
          openText='Select Contract'
          placeholder='Select Contract'
          noOptionsText={
            <Box>
              {contracts.length == 0 && (
                <>
                  <Typography>No Contracts Available</Typography>{' '}
                  <Typography fontStyle={'italic'} fontSize={'0.8em'} color='grey'>
                    Check Data Subscription
                  </Typography>
                </>
              )}
              {contracts.length > 0 && (
                <Typography>
                  No contracts found matching:
                  <Typography sx={{ display: 'inline' }} fontStyle={'italic'} fontWeight={'bold'}>
                    {' ' + filterText}
                  </Typography>{' '}
                </Typography>
              )}
            </Box>
          }
          inputValue={filterText}
          options={options}
          size={size || 'small'}
          disableClearable
          onInputChange={(event, value, reason) => {
            if (reason !== 'reset') {
              setFilterText(value);
            }
          }}
          onBlur={() => !filterText && setFilterText(selected ? selected.label : '')}
          filterOptions={(options, params) => {
            if (customSettings.hideNonFavorites && favorites.size > 0) {
              if (params.inputValue == selected?.value || params.inputValue == '') {
                let optionsFiltered = [...options].filter((y) => favorites.has(y.contract.productId));
                if (optionsFiltered.length == 0) optionsFiltered = options;
                options = optionsFiltered;
              }
            }
            return filter(options, params);
          }}
          renderOption={(props, option) => {
            return (
              <Box component='li' sx={{ '& > *': { mr: 1, ml: 0, flexShrink: 0 } }} {...props}>
                <Tooltip title={`Market State: ${getMarketStatus(option.marketStatus)}`} placement='top'>
                  <Square sx={{ color: getMarketColor(option.marketStatus), fontSize: '0.65em', marginRight: 0.5 }} />
                </Tooltip>
                <Tooltip title='Favorite'>
                  <Star sx={{ color: favorites.has(option.contract.productId) ? '#ffd700' : 'grey', pointer: 'cursor' }} onClick={(e) => toggleFavorite(e, option.contract)}></Star>
                </Tooltip>
                <span>{option.label}</span>
                <span style={{ fontSize: '.8em' }}>({option.desc})</span>
              </Box>
            );
          }}
          onChange={(e, value) => {
            if (linkedColor) changeLinkSymbol(linkedColor, value?.contract);
            else setContract(value?.contract);
            setFilterText(value.label);
          }}
          renderInput={(params: AutocompleteRenderInputParams) => {
            if (contract) {
              const status = getStatus(contract.productId);
              const color = getMarketColor(status);
              params.InputProps.startAdornment = (
                <InputAdornment position='end' sx={{ marginLeft: '0.1em' }}>
                  <Tooltip title={`Market State: ${getMarketStatus(status)}`} placement='top'>
                    <Square sx={{ color: color, fontSize: '0.65em', margin: 0 }} />
                  </Tooltip>
                </InputAdornment>
              );
            }

            return <TextField label='Contract' {...params} />;
          }}
        />
      </Box>
    );
  }, [contracts, contract, filterText, setContract, isOpen, linkedColor, getStatus, favorites, toggleFavorite, customSettings.hideNonFavorites]);

  return <Box>{autoCompleteBox}</Box>;
};

export default React.memo(ContractSelector);
