import { config } from '@/config';
import { KeyboardReturnOutlined } from '@mui/icons-material';
import React from 'react';
import { useEffect } from 'react';

const Zendesk = ({ isSupportPage }) => {
  useEffect(() => {
    if (!config.zendeskKey) return;

    let zE = (window as any).zE;

    if (!isSupportPage) {
      if (zE) {
        try { zE("messenger","hide"); } catch (e) { console.log(e); }
      }
      return;
    } else {
      try { if (zE) zE("messenger","show"); } catch (e) { console.log(e); }
      if (!document.getElementById('ze-snippet')) {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendeskKey}`;
        script.async = true;
        document.body.appendChild(script);
      }
    }

    return () => {
      //grab a new ze variable from window just in case it wasnt initialized before
      zE = (window as any).zE;
      try { if (zE) zE("messenger","hide"); } catch (e) { console.log(e); }
    }
  }, [config.zendeskKey, isSupportPage]);

  return <></>;
};

export default React.memo(Zendesk);
