import React, { useState } from 'react';
import Panel from '../../components/topstep/panel';
import CopyTrading from '../../components/copyTrading/copyTrading';

import { Column, Page } from '../../layout/LayoutComponents';
import RiskSettings from '@/components/topstep/riskSettings';
import ChartSettings from '@/components/topstep/chartSettings';
import PrivacySettings from '@/components/topstep/privacy';
import MiscSettings from '@/components/topstep/miscSettings';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { TradingAccountType } from '@api/userApi';
import styles from './settingsView.module.scss';
import { render } from 'react-dom';
import { Box } from '@mui/material';
import HotkeySettings from '@/components/topstep/hotkeySettings';

const SettingsView: React.FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>('Risk Settings');
  const { activeTradingAccount } = useTradingAccount();
  const hasDll = activeTradingAccount.type === TradingAccountType.Express || activeTradingAccount.type === TradingAccountType.Live;

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Copy Trading':
        return <CopyTrading />;
      case 'Risk Settings':
        return <RiskSettings />;
      case 'Charts & Data':
        return <ChartSettings />;
      case 'Privacy':
        return <PrivacySettings />;
      case 'Misc':
        return <MiscSettings />;
      case 'Hotkeys':
        return <HotkeySettings />;
      default:
        return <CopyTrading />;
    }
  };

  return (
    <Page sx={{ maxHeight: '100%', maxWidth: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: '100%', padding: '2em', maxHeight: '100%', maxWidth: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <div className={styles.settingsHeader}>
          Platform Settings / <span style={{ fontWeight: 600 }}>{activeTab} </span>
        </div>
        <div className={styles.settingsMenuCont}>
          <div className={`${styles.settingsButton} ${activeTab === 'Copy Trading' ? styles.active : ''}`} onClick={() => handleTabClick('Copy Trading')}>
            Copy Trading
          </div>
          <div data-intercom-target='risk-settings-tab' className={`${styles.settingsButton} ${activeTab === 'Risk Settings' ? styles.active : ''}`} onClick={() => handleTabClick('Risk Settings')}>
            Risk Settings
          </div>
          <div className={`${styles.settingsButton} ${activeTab === 'Charts & Data' ? styles.active : ''}`} onClick={() => handleTabClick('Charts & Data')}>
            Charts & Data
          </div>
          <div className={`${styles.settingsButton} ${activeTab === 'Privacy' ? styles.active : ''}`} onClick={() => handleTabClick('Privacy')}>
            Privacy
          </div>
          <div className={`${styles.settingsButton} ${activeTab === 'Hotkeys' ? styles.active : ''}`} onClick={() => handleTabClick('Hotkeys')}>
            Hotkeys
          </div>
          <div className={`${styles.settingsButton} ${activeTab === 'Misc' ? styles.active : ''}`} onClick={() => handleTabClick('Misc')}>
            Misc
          </div>
        </div>

        <Box
          sx={(theme) => {
            return {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflowY: 'hidden',
              maxHeight: '100%',
              backgroundColor: theme.palette.lightBg
            };
          }}
          className={styles.box}
        >
          {renderContent()}
        </Box>
      </div>
    </Page>
  );
};

export default React.memo(SettingsView);
