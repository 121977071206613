import { ThemeNames } from '@/contexts/ThemeContext';
import { PaletteOptions } from '@mui/material/styles/createPalette';

interface IConfigData {
  chartApi: string;
  userApi: string;
  env: string;
  version: string;
  versionFromFile: string;
  company: string;
  platform: TradingPlatforms;
  coachName: string;
  platformName: string;
  platformSideBarLogo: string;
  platformLoginLogo: string;
  platformLoginBG: string;
  platformFavIcon: string;
  simAccountName: string;
  intercomId?: string;
  goldButton: string;
  themes: Partial<Record<ThemeNames, PaletteOptions>>;
  features: {
    oco?: boolean;
    mobile?: boolean;
  };
  accountTypes: {
    funded: string;
    live: string;
    sim: string;
    practice: string;
  };
  supportLinks: {
    supportResources?: string;
    helpCenter?: string;
    discord?: string;
  };
  enableNotificationFooter: boolean;
  alternativeMaxPDLL: number;
  zendeskKey?: string;
  audioPacks: {
    name: string;
    title: string;
    alerts: {
      name: string;
      url: string;
    }[];
  }[];
  crispKey?: string;
  tawkPropertyId?: string;
  tawkWidgetId?: string;
}

export enum TradingPlatforms {
  None = '',
  TopstepX = 'topstepx',
  E8 = 'e8',
  TheFundedTrader = 'thefundedtrader',
  AlphaFutures = 'alphafutures',
  Demo = 'demo',
  OneOfOne = 'oneofone',
  FundingFutures = 'fundingfutures',
  TickTickTrader = 'tickticktrader',
  FuturesElite = 'futureselite',
  GoatFunded = 'goatfunded',
  FuturesDesk = 'futuresdesk',
  BlueGuardianFutures = 'blueguardian',
  AquaFutures = 'aquafunded'
}

const data: IConfigData = {
  chartApi: '',
  userApi: '',
  env: '',
  version: '',
  versionFromFile: '',
  platform: TradingPlatforms.None,
  platformName: 'X',
  platformSideBarLogo: '',
  platformLoginLogo: '',
  platformLoginBG: '',
  simAccountName: '',
  goldButton: '',
  platformFavIcon: '',
  intercomId: undefined,
  company: '',
  coachName: '',
  themes: {},
  features: {},
  supportLinks: {
    supportResources: undefined,
    helpCenter: undefined,
    discord: undefined
  },
  accountTypes: {
    funded: 'Funded',
    live: 'Live',
    sim: 'Sim',
    practice: 'Practice'
  },
  enableNotificationFooter: false,
  alternativeMaxPDLL: 0,
  zendeskKey: undefined,
  audioPacks: [
    {
      name: '',
      title: '',
      alerts: [
        {
          name: '',
          url: ''
        }
      ]
    }
  ],
  crispKey: undefined,
  tawkPropertyId: undefined,
  tawkWidgetId: undefined
};

export const loadConfig = async () => {
  let result = null;
  console.log('process.env.NODE_ENV', process.env.NODE_ENV);
  if (process.env.NODE_ENV === 'development') {
    result = await fetch('/config.local.json');
  } else {
    result = await fetch('/config.json');
  }

  const versionJson = await fetch('/version.json');
  const versionFromFile = await versionJson.json();
  const dataJson = await result.json();

  data.versionFromFile = versionFromFile.version;

  data.chartApi = dataJson.chartApi.endsWith('/') ? dataJson.chartApi.substring(0, dataJson.chartApi.length - 1) : dataJson.chartApi;
  data.userApi = dataJson.userApi.endsWith('/') ? dataJson.userApi.substring(0, dataJson.userApi.length - 1) : dataJson.userApi;
  data.platformSideBarLogo = dataJson.platformSideBarLogo;
  data.platformLoginLogo = dataJson.platformLoginLogo;
  data.platformLoginBG = dataJson.platformLoginBG;
  data.simAccountName = dataJson.simAccountName;
  data.goldButton = dataJson.goldButton;
  data.coachName = dataJson.coachName || 'Trading Coach';
  data.company = dataJson.company;
  data.env = dataJson.env;
  data.themes = dataJson.themes;
  data.version = process.env.REACT_APP_VERSION || 'Unknown';
  data.platformFavIcon = dataJson.platformFavIcon || '';
  data.features = dataJson.features || {};
  data.intercomId = dataJson.intercomId;
  if (dataJson.platform) {
    data.platform = dataJson.platform;
  }

  if (dataJson.platformName) {
    data.platformName = dataJson.platformName;
  }
  if (dataJson.audioPacks && dataJson.audioPacks.length > 0) {
    const audioPack = dataJson.audioPacks.find(pack => pack.name === dataJson.platform) || dataJson.audioPacks.find(pack => pack.name === 'default');
    data.audioPacks = [audioPack];
  } else {
    data.audioPacks = [];
  }

  if (dataJson.accountTypes) {
    data.accountTypes = {
      funded: dataJson.accountTypes.funded || 'Funded',
      live: dataJson.accountTypes.live || 'Live',
      sim: dataJson.accountTypes.sim || 'Sim',
      practice: dataJson.accountTypes.practice || 'Practice'
    };
  }

  data.supportLinks = dataJson.supportLinks || {};

  if (dataJson.enableNotificationFooter) {
    data.enableNotificationFooter = dataJson.enableNotificationFooter;
  }

  if (dataJson.alternativeMaxPDLL) {
    data.alternativeMaxPDLL = dataJson.alternativeMaxPDLL;
  }

  if (dataJson.zendeskKey) {
    data.zendeskKey = dataJson.zendeskKey;
  }
  
  if (dataJson.crispKey) {
    data.crispKey = dataJson.crispKey;
  }

  if (dataJson.tawkPropertyId && dataJson.tawkWidgetId) {
    data.tawkPropertyId = dataJson.tawkPropertyId;
    data.tawkWidgetId = dataJson.tawkWidgetId;  
  }

  if (dataJson.audioPacks && Array.isArray(dataJson.audioPacks)) {
    data.audioPacks = dataJson.audioPacks; 
  } else {
    data.audioPacks = [];
  }

  return data;
};

export default data;
export { data as config };
