import { PaletteColor, PaletteOptions, Theme, ThemeOptions, alpha, createStyles, darken, lighten } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider as MaterialUIThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { config } from '@/config';
import Loading from '@/components/Loading';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

export enum ThemeNames {
  dark = 'dark',
  light = 'light'
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/styles' {
  export interface Palette {
    mediumGrey: string;
    lightBg: string;
    darkBg: string;
    red: string;
    white: string;
    green: string;
    gold: string;
    blue: string;
    neutral: PaletteColor;
  }
  export interface PaletteOptions {
    mediumGrey: string;
    lightBg: string;
    darkBg: string;
    red: string;
    white: string;
    green: string;
    gold: string;
    blue: string;
    neutral: PaletteColor;
  }
}

// declare module "@mui/material/Button" {
//   interface ButtonPropsColorOverrides {
//     mediumGrey: true;
//   }
// }

const createThemeOptions = (palette: PaletteOptions): ThemeOptions => {
  return {
    palette: {
      mode: 'dark',
      ...palette,
      neutral: {
        main: palette.mediumGrey,
        light: lighten(palette.mediumGrey, 0.4),
        dark: darken(palette.mediumGrey, 0.4),
        contrastText: palette.white
      }
    },
    typography: {
      fontFamily: "'Roboto Condensed', sans-serif"
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          footerContainer: {
            minHeight: '8px'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: palette.white
            }
          }
        }
      },

      MuiTablePagination: {
        styleOverrides: {
          root: {
            p: {
              margin: 0
            }
          },
          toolbar: {
            minHeight: '8px !important'
          }
        }
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '1rem'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #1c1e23 inset',
              WebkitTextFillColor: '#000'
            }
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
      fontFamily: "'Roboto Condensed', sans-serif",
      @font-face {
        font-family: 'TopstepFont';
        src: url('../assets/fonts/SharpGroteskBold16.ttf') format('truetype');
      },
      `
      }
    }
  };
};
export interface IThemeContext {
  selectedTheme?: ThemeNames.dark | ThemeNames.light;
  toggleSelectedTheme?: () => void;
}

export const ThemeContext = React.createContext<IThemeContext>({
  selectedTheme: ThemeNames.dark
});
export const useThemeSelector = () => React.useContext<IThemeContext>(ThemeContext);

export function ThemeProvider({ children }: any) {
  const [selectedTheme, setSelectedTheme] = useState<ThemeNames>(ThemeNames.dark);
  const [themes, setThemes] = useState<Partial<Record<ThemeNames, Theme>>>({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const newThemes: Partial<Record<ThemeNames, Theme>> = {};
    for (const [themeName, palleteOptions] of Object.entries(config.themes)) {
      newThemes[themeName as ThemeNames] = createTheme(createThemeOptions(palleteOptions));
    }
    setThemes(newThemes);
    setLoaded(true);
  }, [config]);

  const values = useMemo(() => {
    return {
      selectedTheme,
      toggleSelectedTheme: () => {
        setSelectedTheme(selectedTheme === ThemeNames.dark ? ThemeNames.light : ThemeNames.dark);
      }
    };
  }, [selectedTheme, setSelectedTheme]);

  const theme = useMemo(() => {
    return themes[selectedTheme];
  }, [themes, selectedTheme, loaded]);

  if (!loaded) return <Loading />;

  return (
    <ThemeContext.Provider value={values}>
      <MaterialUIThemeProvider theme={loaded && theme}>
        {loaded && <CssBaseline enableColorScheme />}
        {loaded && children}
      </MaterialUIThemeProvider>
    </ThemeContext.Provider>
  );
}
