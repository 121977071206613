import { NavRoutes } from "@/data/NavRoutes";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MobileHome = () => {

    const nav = useNavigate();

    useEffect(() => {
        nav('/trading/dom')
    }, []);

    return <></>;
};

export default MobileHome;