import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styles from './dom.module.scss';
import { useDom } from '@contexts/DomContext';
import { formatNumber } from 'src/helpers/formatter';
import { OrderPromptType, useOrders } from '@contexts/OrdersContext';
import { OrderType } from '@api/userApi';
import { DomLayoutType } from '@/contexts/SettingsContext';
import { useDeviceContext } from '@/contexts/DeviceContext';

interface BidCellProps {
  price: number;
  orderType: OrderPromptType;
  placeOrder: (price: number, orderType: OrderPromptType, order: OrderType) => void;
  domLayoutType: DomLayoutType;
  bgColor?: string;
}

const percentToHexAlpha = (percent) => {
  const alpha = Math.round(percent * 255);
  const hexAlpha = alpha.toString(16).padStart(2, '0').toUpperCase();
  return hexAlpha;
}

const BidCell: React.FC<BidCellProps> = ({ price, orderType, placeOrder: placeOrder, domLayoutType, bgColor }): JSX.Element => {
  const [color, setColor] = useState<string>(bgColor ?? '#007a1c');

  const { bids, lastPrice, maxBid, bestBid } = useDom();
  const { isMobile } = useDeviceContext();
  useEffect(() => {
    if (bgColor) {
      setColor(bgColor);
    }
  }, [bgColor]);

  const onMouseClick = useCallback(
    (e: React.MouseEvent) => {
      if (isMobile) {
        if (price > bestBid) {
          placeOrder(price, OrderPromptType.Buy, OrderType.Stop);
          e.preventDefault();
        } else {
          placeOrder(price, OrderPromptType.Buy, OrderType.Limit);
        }
      } else if (e.button == 2) {
        placeOrder(price, OrderPromptType.Buy, OrderType.Stop);
        e.preventDefault();
      } else {
        placeOrder(price, OrderPromptType.Buy, OrderType.Limit);
      }
    },
    [price, lastPrice, placeOrder, orderType, bestBid, isMobile]
  );

  const volume = useMemo(() => {
    // if (lastPrice < price) {
    //   return 0;
    // }
    return bids.get(price) || 0;
  }, [bids.get(price), price, lastPrice]);

  return useMemo(() => {
    if (domLayoutType === DomLayoutType.Modern) {
      return (
        <div className={styles.bidCell} onClick={onMouseClick} onContextMenu={onMouseClick}>
          {volume > 0 && (
            <div className={styles.bid} style={{ width: ((volume / maxBid) * 100).toFixed(2) + '%', backgroundColor: `${color}` }}>
              &nbsp;
            </div>
          )}
          {volume > 0 && <span className={styles.bidAmount}> {formatNumber(volume)}</span>}
        </div>
      );
    } else {
      const opac = Math.log10(volume + 1) / Math.log10(maxBid + 1);
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={onMouseClick} onContextMenu={onMouseClick}>
          <div style={{ width: 'fit-content', minWidth: '3em', textAlign: 'center', backgroundColor: volume > 0 ? `${color}${percentToHexAlpha(opac)}` : 'transparent' }}>
            {volume > 0 && <span style={{ color: 'white', width: '100%', textShadow: '0 0 2px black' }}>{volume}</span>}
          </div>
        </div>
      );
    }
  }, [volume, maxBid, bestBid, onMouseClick, domLayoutType, price, bgColor, color]);
};

export default React.memo(BidCell);
