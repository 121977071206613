import { QuotesClient } from "@/api/dataApi";

export class QuotesProvider {
    private _quotesClient: QuotesClient;

    constructor(client: QuotesClient) {
        this._quotesClient = client
    }
    getQuotes(symbols: string[]) {
        return new Promise((resolve, reject) => {
            this._quotesClient.get(symbols).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    }
}
