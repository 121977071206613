import React, { useMemo, useState } from 'react';
export type CqgCallbackId = number;

export enum DragType {
  None = 'None',
  DomBuyOrder = 'DomOrderBid',
  DomSellOrder = 'DomAskOrder',
  StopLoss = 'StopLoss',
  TakeProfit = 'TakeProfit',
}

export interface IDragDropContext {
  dragData: any;
  dragType: DragType;
  setDragData: (data?: any, type?: DragType) => void;
}

export const DragDropContext = React.createContext<IDragDropContext>({} as any);
export const useDragDrop = () => React.useContext<IDragDropContext>(DragDropContext);

function DragDropProvider({ children }: any) {
  const [dragData, setDragData] = useState<any>(null);
  const [dragType, setDragType] = useState<DragType>(DragType.None);

  const values = useMemo(() => {
    return {
      dragData,
      dragType,
      setDragData: (data?: any, type?: DragType) => {
        setDragData(data);
        setDragType(type ?? DragType.None);
      }
    };
  }, [dragData, dragType]);
  return <DragDropContext.Provider value={values}>{children}</DragDropContext.Provider>;
}

export default DragDropProvider;
