import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTilt } from '../../../../contexts/TiltContext';
import styles from './tilt.module.scss';
import { useSymbol } from '@contexts/SymbolContext';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import Button, { ButtonType } from '@components/topstep/button';
import Heading, { HeadingSize } from '@components/topstep/heading';
import { useApi } from '@contexts/ApiContext';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { useTradingAccount } from '@/contexts/TradingAccountContext';

const Tilt: React.FC = (): JSX.Element => {
  const { tiltData } = useTilt();
  const { getContractByProductName } = useSymbol();
  const { tiltApi } = useApi();
  const [showTilt, setShowTilt] = useState(true);
  const [count, setCount] = useState(0);
  const { isMobile } = useDeviceContext();
  const { activeTradingAccount } = useTradingAccount();

  useEffect(() => {
    if (showTilt) {
      const timeout = setTimeout(
        () => {
          setShowTilt(false);
        },
        120 * 60 * 1000
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showTilt]);

  useEffect(() => {
    tiltApi.activateWithAccountId(activeTradingAccount.accountId, count);
  }, [count]);

  const overlay = useMemo(() => {
    return (
      <div className={styles.overlay}>
        <div>
          <Heading size={HeadingSize.H2}>Are you still watching The Tilt™?</Heading>
        </div>
        <div>
          <StyledButton
            color='success'
            onClick={() => {
              setShowTilt(true);
              setCount((prev) => prev + 1);
            }}
          >
            Yes
          </StyledButton>
        </div>
      </div>
    );
  }, []);

  const modTiltData = useMemo(() => {
    if (!tiltData) return [];

    return tiltData.map((y) => {
      if (y.contractName.startsWith('ES')) {
        const fullName = getContractByProductName('/ES')?.contractName;
        return { ...y, name: '/ES', fullName: fullName };
      } else if (y.contractName.startsWith('CL')) {
        const fullName = getContractByProductName('/CL')?.contractName;
        return { ...y, name: '/CL', fullName: fullName };
      } else if (y.contractName.startsWith('NQ')) {
        const fullName = getContractByProductName('/NQ')?.contractName;
        return { ...y, name: '/NQ', fullName: fullName };
      } else if (y.contractName.startsWith('GC')) {
        const fullName = getContractByProductName('/GC')?.contractName;
        return { ...y, name: '/GC', fullName: fullName };
      }
    });
  }, [tiltData]);

  if (modTiltData.length > 0) {
    return (
      <div>
        <div className={classNames(styles.container, !showTilt && styles.blurred)}>
          {modTiltData.map((x, i) => (
            <div className={isMobile ? styles.mobileColumn : styles.column} key={x.contractName}>
              <div className={styles.contractName}>
                {x.fullName} ({x.name})
              </div>
              <div className={styles.desc}>
                <Tooltip title='Percentage of Topstep traders long'>
                  <span className={styles.full}>% Long</span>
                </Tooltip>
                <Tooltip title='Percentage of Topstep traders short'>
                  <span className={styles.full}>% Short</span>
                </Tooltip>
              </div>
              <div className={styles.bias}>
                <span className={`${x.shortBias <= x.longBias && styles.longBias} ${styles.full}`}>{x.longBias.toFixed(0)}%</span>
                <span className={`${x.shortBias > x.longBias && styles.shortBias} ${styles.full}`}>{x.shortBias.toFixed(0)}%</span>
              </div>
              <div className={styles.desc}>
                <Tooltip title='Percentage of Topstep traders who are up $'>
                  <span className={styles.full}>% Positive ($)</span>
                </Tooltip>
                <Tooltip title='Percentage of Topstep traders who are down $'>
                  <span className={styles.full}>% Negative ($)</span>
                </Tooltip>
              </div>
              <div className={styles.bias}>
                <span className={styles.full}>{(x.percentPositive * 100).toFixed(0)}%</span>
                <span className={styles.full}>{(x.percentNegative * 100).toFixed(0)}%</span>
              </div>
              <div className={styles.desc}>
                <Tooltip title='Topstep traders who are long, their avg entry price'>
                  <span className={styles.full}>Longs avg price</span>
                </Tooltip>
                <Tooltip title='Topstep traders who are short, their avg entry price'>
                  <span className={styles.full}>Shorts avg price</span>
                </Tooltip>
              </div>
              <div className={styles.bias}>
                <span className={styles.full}>${x.avgPriceLong.toFixed(2)}</span>
                <span className={styles.full}>${x.avgPriceShort.toFixed(2)}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={classNames(styles.disclaimerText, !showTilt && styles.blurred)}>
          The Tilt™ displays the aggregated trader sentiment analysis for all Topstep sim traders. Disclaimer: This data is for entertainment purposes only and should not be used as a buy or sell indicator.
        </div>
        {!showTilt && overlay}
      </div>
    );
  } else {
    return (
      <>
        <div className={classNames(styles.noTiltContainer, !showTilt && styles.blurred)}>
          <span>The Tilt™ will display during trading hours.</span>
        </div>
        {!showTilt && overlay}
      </>
    );
  }
};

export default Tilt;
