import { ActiveViolationModel, TradingAccountStatus, TradingRuleType, ViolationType } from '@/api/userApi';
import Loading from '@/components/Loading';
import { useApi } from '@/contexts/ApiContext';
import { IExtendedTradingAccountModel, useTradingAccount } from '@/contexts/TradingAccountContext';
import DailyLossLimitModal from '@/views/trader/components/notifications/DailyLossLimitModal';
import MaximumLossLimitModal from '@/views/trader/components/notifications/MaximumLossLimitModal';
import PersonalDailyLossLimitModal from '@/views/trader/components/notifications/PersonalDailyLossLimitModal';
import PersonalDailyProfitTargetModal from '@/views/trader/components/notifications/PersonalDailyProfitTargetModal';
import StopTradingModal from '@/views/trader/components/notifications/StopTradingModal';
import React, { useEffect, useMemo, useState } from 'react';
import CustomViolationModal from './CustomViolationModal';

const ViolationNotifications: React.FC = (): JSX.Element => {
  const { activeTradingAccount, tradingRules } = useTradingAccount();
  const [dismiss, setDismiss] = useState(false);
  const { violationsApi } = useApi();
  const [activeViolation, setActiveViolation] = useState<ActiveViolationModel | null>(null);
  useEffect(() => {
    setDismiss(false);
    if (!activeTradingAccount) return;
    switch (activeTradingAccount.status) {
      case TradingAccountStatus.TemporaryViolation:
        setDismiss(false);
        break;
      case TradingAccountStatus.Violation:
        setDismiss(false);
        break;
        case TradingAccountStatus.GoalAchieved :
          setDismiss(!activeViolation);
          break;
      default:
        setDismiss(true);
    }
  }, [activeTradingAccount?.accountId, activeTradingAccount?.status, activeViolation]);

  //update violation when status or account id changes via useEffect
  useEffect(() => {
    let changed = false;
    if (activeTradingAccount?.accountId) {
      violationsApi.getActiveViolation(activeTradingAccount.accountId).then((violation) => {
        if (!changed) {
          setActiveViolation(violation);
        }
      });
    }

    return () => {
      changed = true;
    };
  }, [activeTradingAccount?.accountId, activeTradingAccount?.status]);

  const getAccountName = (account: IExtendedTradingAccountModel): string => {
    if (account) {
      if (account.nickname) {
        return account.nickname + ' (' + account.accountName + ')';
      } else {
        return account.accountName;
      }
    } else {
      return 'Unknown Account';
    }
  };

  return useMemo(() => {
    if (dismiss) {
      return <></>;
    }

    if (activeTradingAccount?.status === TradingAccountStatus.TemporaryViolation || 
        activeTradingAccount?.status === TradingAccountStatus.Violation || 
        activeTradingAccount?.status === TradingAccountStatus.GoalAchieved ) {
      if (!activeViolation) {
        return <Loading />;
      }

      switch (activeViolation.type) {
        case ViolationType.DailyLossLimit:
          return <DailyLossLimitModal accountName={getAccountName(activeTradingAccount)} violation={activeViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.MaximumLossLimit:
          return <MaximumLossLimitModal account={activeTradingAccount} violation={activeViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.PersonalDailyLossLimit:
          return <PersonalDailyLossLimitModal accountName={getAccountName(activeTradingAccount)} violation={activeViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.PersonalDailyProfitTarget:
          return <PersonalDailyProfitTargetModal accountName={getAccountName(activeTradingAccount)} violation={activeViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.PersonalStop:
          return <StopTradingModal accountName={getAccountName(activeTradingAccount)} violation={activeViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.Custom:
          return <CustomViolationModal customViolation={activeViolation.customViolation} onHide={() => setDismiss(true)} />;
        case ViolationType.Rule: {
          const rule = tradingRules.find((rule) => rule.id === activeViolation.ruleId);
          if (!rule) {
            return <></>;
          }
          switch (rule.id) {
            case TradingRuleType.DailyLossLimit:
              return <DailyLossLimitModal accountName={getAccountName(activeTradingAccount)} violation={activeViolation} onHide={() => setDismiss(true)} />;
            case TradingRuleType.MaximumLossLimit:
              return <MaximumLossLimitModal account={activeTradingAccount} violation={activeViolation} onHide={() => setDismiss(true)} />;
            default:
              return <></>;
          }
        }

        default:
          return <></>;
      }
    }

    return <></>;
  }, [activeTradingAccount.accountId, activeTradingAccount?.status, activeViolation, dismiss, getAccountName]);
};

export default React.memo(ViolationNotifications);
